@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use '../helpers/functions' as functions;

.promotion-bar {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: brand.$brand-primary;
  color: brand.$brand-white;
  padding: 0 functions.flow(0.5);
  width: 100%;
  height: 80px;

  @include mq(mobile) {
    height: 100px;
    padding: 0 functions.flow(1);
  }

  &__half {
    overflow: hidden;
    $padding-left: functions.flow(1.75);
    $padding-right: functions.flow(1.25);

    &--left {
      text-align: right;
      flex-basis: 50%;
      padding-right: $padding-left;

      strong {
        display: inline-block;
        transform: translateX(calc(100% + (#{$padding-left} + 4px)));

        .animated & {
          transform: translateX(0);
        }
      }
    }

    &--right {
      text-align: left;
      flex-basis: 50%;
      padding-left: $padding-right;

      strong {
        display: inline-block;
        transform: translateX(calc(-100% - (#{$padding-right} + 4px)));

        .animated & {
          transform: translateX(0);
        }
      }
    }

    strong {
      font-size: 12px;
      line-height: 12px;
      @include fonts.helvetica-93-black-extended-oblique();
      transition: transform 500ms ease 350ms;

      @include mq(375px) {
        font-size: 15px;
        line-height: 15px;
      }

      @include mq(550px) {
        font-size: 24px;
        line-height: 24px;
      }

      @include mq(tablet) {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }
}
