/**
 * Add global settings in this file.
 */

$padding: 24px;
$viewport-wide: 100%;
$site-wide: 1160px;
$content-wide: 600px;
$content-wide--small: 500px;
$content-wide--wide: 820px;
$content-breakout: -50px;
$easing: cubic-bezier(0.645, 0.045, 0.355, 1);
$navheight: 76px;
$navheight-mobile: 68px;
