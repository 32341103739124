@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.review__sub-title {
  text-align: center;

  h2 {
    padding-top: 44px;
    font-size: 30px;
    @include fonts.helvetica-93-black-extended-oblique;
    text-transform: uppercase;

    @include mq($until: tablet) {
      font-size: 21px;
    }
  }
}

.reviews-and-ratings-container {
  background: brand.$brand-white;
}

.reviews-and-ratings {
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 25px;
  padding-right: 25px;

  .col-xs-8 {
    padding-right: 15px;

    @include mq($until: desktop) {
      width: 100%;
    }

    @include mq($from: desktop) {
      padding-right: 100px;
    }

    @include mq($from: 1421px) {
      padding-right: 155px;
    }
  }

  .col-xs-4 {
    display: none;

    @include mq($from: desktop) {
      display: block;
    }
  }

  .no-reviews {
    width: 100%;
    text-align: center;

    p {
      @include fonts.helvetica-73-bold-extended;
      font-size: 14px;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
  }

  .flag {
    display: block;
    width: 36px;
    height: 25px;
    background: url('~@img-old/country-flags.png') 0 0 no-repeat;
    -moz-background-size: 36px;
    -o-background-size: 36px;
    background-size: 36px;

    &.cs-CZ {
      background-position: 0 -30px;
    }

    &.da-DK {
      background-position: 0 -60px;
    }

    &.de-DE {
      background-position: 0 -90px;
    }

    &.en-GB {
      background-position: 0 -120px;
    }

    &.es-ES {
      background-position: 0 -150px;
    }

    &.fi-FI {
      background-position: 0 -180px;
    }

    &.fr-FR {
      background-position: 0 -210px;
    }

    &.hu-HU {
      background-position: 0 -240px;
    }

    &.it-IT {
      background-position: 0 -270px;
    }

    &.nl-NL {
      background-position: 0 -300px;
    }

    &.nn-NO {
      background-position: 0 -330px;
    }

    &.pl-PL {
      background-position: 0 -360px;
    }

    &.ru-RU {
      background-position: 0 -390px;
    }

    &.sv-SE {
      background-position: 0 -420px;
    }

    &.be-BE,
    &.nl-BE,
    &.fr-BE {
      background-position: 0 -450px;
    }

    &.lt-LT {
      background-position: 0 -480px;
    }

    &.lv-LV {
      background-position: 0 -510px;
    }

    &.et-EE {
      background-position: 0 -540px;
    }

    &.de-CH {
      background-position: 0 -569px;
    }

    &.ar-AE {
      background-position: 0 -599px;
    }

    &.en-ZA {
      background-position: 0 -629px;
    }

    &.pt-PT {
      background-position: 0 -659px;
    }

    &.sk-SK {
      background-position: 0px -688px;
    }

    &.tr-TR {
      background-position: 0 -721px;
    }

    &.ro-RO {
      background-position: 0 -751px;
    }

    &.de-AT {
      background-position: 0 -781px;
    }
  }
}

.button {
  &-red {
    background-color: brand.$brand-primary;
    text-transform: uppercase;
    color: brand.$brand-white;
    text-align: center;
    padding: 10px 56px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
  }

  &-black {
    background-color: brand.$brand-black;
    text-transform: uppercase;
    color: brand.$brand-white;
    text-align: center;
    padding: 10px 56px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
  }
}

.cancel {
  color: brand.$brand-black;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
}

.load-more {
  border: 1px solid brand.$brand-black;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 32px;
  display: inline-block;
  color: brand.$brand-black;
  margin-right: 10px;
  cursor: pointer;
  @include fonts.helvetica-83-heavy-extended;

  @include mq($until: 500px) {
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.rating-breakdowns {
  max-width: 350px;
  margin-bottom: 50px;

  &__title {
    @include fonts.helvetica-73-bold-extended;
    font-size: 14px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  &__breakdown {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &.active {
      color: brand.$brand-primary;

      .rating-breakdowns__breakdown-graph > div {
        background-color: brand.$brand-primary;
      }

      .star {
        background-image: url('~@img-old/svg/stars/star-red.svg');
      }
    }

    &-stars {
      width: 30px;
      margin-right: 12px;
      @include fonts.helvetica-83-heavy-extended;
      font-size: 12px;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      .star {
        width: 12px;
        height: 12px;
        background-image: url('~@img-old/svg/stars/star-black.svg');
        background-repeat: no-repeat;
        margin-left: auto;
      }
    }

    &-graph {
      width: 100%;
      max-width: 80%;
      height: 6px;
      background-color: #efefef;

      @include mq($from: 500px) {
        max-width: 85%;
      }

      div {
        height: 6px;
        background-color: brand.$brand-primary;
        display: block;
      }
    }

    &-total {
      width: 21px;
      @include fonts.helvetica-55-roman;
      font-size: 12px;
      text-align: right;
    }
  }
}

.ratings-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 44px;

  @include mq(tablet) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 244px;
  }

  > div {
    display: flex;
    justify-content: center;
    flex-basis: 33.333%;
    flex-grow: 0;
    height: 100%;
    margin: 0;
    padding: 20px;

    @include mq(desktop) {
      padding: 50px;
    }
  }

  .star-average {
    order: 1;
    background: brand.$brand-grey-light;
  }

  .rating-breakdowns {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    order: 2;
    max-width: none;

    @include mq($until: tablet) {
      align-self: center;
      width: 100%;
    }

    > div {
      flex: 1;
      width: 100%;
    }
  }

  .recommend-percentage {
    order: 3;
    background: brand.$brand-grey-light;
  }
}

.reviews {
  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 35px;
    flex-direction: column;

    @include mq($from: 500px) {
      flex-direction: row;
    }

    .review-pagination {
      font-size: 12px;
      @include fonts.helvetica-55-roman;

      @include mq($until: tablet) {
        display: none;
      }

      span {
        @include fonts.helvetica-73-bold-extended;
      }
    }

    .sort {
      width: 100%;
      max-width: 230px;
      padding: 10px;
      padding-right: 22px;
      border: 1px solid #ebebeb;
      position: relative;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 10px;
      @include fonts.helvetica-73-bold-extended;
      z-index: 10;

      @include mq($until: tablet) {
        display: none;
      }

      &.active {
        .sort-option {
          display: block;
        }

        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &:after {
        content: '\f078';
        font-family: 'FontAwesome';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        line-height: 0px;
        right: 10px;
        color: brand.$brand-primary;
        font-size: 16px;
      }
    }

    .sort-options {
      position: absolute;
      left: -1px;
      width: 101%;
      z-index: 1;
      background: white;
      border: 1px solid #ebebeb;
      border-top: none;
      top: 100%;
    }

    .sort-option {
      display: none;
      padding: 8px 10px;
      user-select: none;
      cursor: pointer;

      &:hover {
        background-color: #fbfbfb;
      }
    }

    .button-red {
      position: absolute;
      max-width: 210px;
      top: -1px;
      left: 0;
      right: 0;
      margin: 0 auto;
      @include fonts.helvetica-83-heavy-extended;
      padding-left: 32px;
      padding-right: 32px;

      @include mq($until: tablet) {
        position: relative;
      }
    }
  }

  .review {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 0 16px;

    @include mq($until: tablet) {
      padding: 44px 0 16px;
    }

    &:nth-child(odd) {
      background: brand.$brand-grey-light;
    }

    &-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      @include fonts.helvetica-55-roman;
      font-size: 14px;
      margin-bottom: 30px;
    }

    &__stars {
      width: 100px;

      .stars {
        margin: 2px;

        .star {
          width: 17px;
          height: 17px;
          margin: 0 1px;
        }
      }
    }

    &__verified {
      margin-right: 10px;
      display: none;

      &.is-verified {
        display: block;
        margin-right: 3px;
      }

      img {
        width: 20px;
      }
    }

    &__name {
      @include fonts.helvetica-55-roman;
      font-size: 14px;
      display: flex;
      align-items: center;

      .review-first-name {
        margin-right: 3px;
      }
    }

    .verified-title {
      display: block;
      font-size: 12px;
      @include fonts.helvetica-55-roman;
      color: #b1b1b1;
    }

    &__row {
      display: flex;
    }

    &__time {
      @include fonts.helvetica-55-roman;
      font-size: 12px;
      color: #b1b1b1;
      display: flex;
      align-items: center;

      .review-flag {
        margin-right: 2px;
        /* Kinda forced to do this so I can reuse the same image for the flags */
        transform: scale(0.65);
      }

      @include mq($until: 500px) {
        margin-left: inherit;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding-top: 5px;
      }
    }

    &-title {
      @include fonts.helvetica-73-bold-extended;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 18px;
      text-transform: uppercase;
    }

    &-content {
      display: flex;
      @include fonts.helvetica-55-roman;
      font-size: 16px;
      margin-bottom: 20px;
      flex-direction: column;
      flex-wrap: wrap;
      max-width: 640px;
      text-align: center;

      @include mq($from: mobile) {
        flex-direction: row;
      }

      img {
        align-self: flex-start;
        margin: 10px auto;
        display: block;
      }

      &__copy {
        word-break: break-word;
        flex-grow: 1;

        blockquote {
          font-style: italic;
        }

        p {
          font-size: 14px;
          line-height: 20px;
          @include fonts.helvetica-53-extended;
        }

        &.with-media {
          width: 50%;

          @include mq($until: mobile) {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }

      &__reply {
        font-size: 13px;
        margin-top: 10px;

        .reply-link {
          color: brand.$brand-primary;
          cursor: pointer;
          user-select: none;
          margin-bottom: 5px;
        }

        .reply-title {
          display: flex;
          align-items: center;
          margin-bottom: 11px;

          .review__verified img {
            margin: 0;
          }
        }

        .reply-block {
          display: none;
          background: #efefef;
          padding: 15px 10px;

          &.active {
            display: block;
          }
        }
      }

      &__media-items {
        width: 50%;
        position: relative;

        @include mq($until: mobile) {
          width: 100%;
        }
      }

      .media-video {
        height: 0px;
        padding-bottom: 56.25%;
        position: relative;

        > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }

      &__media {
        video,
        iframe {
          width: 100%;
          border: none;
          outline: none;
        }

        @include mq($until: tablet) {
          img {
            display: block;
            margin: 0 auto;
            margin-top: 10px;
          }
        }
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      span {
        display: inline-block;
        margin: 0 6px;
        text-decoration: underline;
        cursor: pointer;
      }

      small {
        font-size: inherit;
        color: brand.$brand-grey-medium;
      }

      &__recommended {
        display: flex;
        font-size: 14px;
        color: brand.$brand-primary;
        @include fonts.helvetica-73-bold-extended;
        margin-bottom: 64px;

        @include mq($until: 500px) {
          padding-bottom: 5px;
        }

        img {
          width: 20px;
          display: inline-block;
          margin-right: 10px;
        }
      }

      &__translate {
        text-align: right;
        padding-bottom: 5px;
        margin-bottom: 24px;
        @include fonts.helvetica-55-roman;

        .translate-link {
          position: relative;
          padding-right: 3px;
          cursor: pointer;
          user-select: none;
          color: brand.$brand-grey-medium;
        }
      }

      &__helpful {
        user-select: none;
        font-size: 14px;
        @include fonts.helvetica-55-roman;

        .grey-link {
          color: brand.$brand-grey-medium;
        }
      }
    }
  }

  &__footer {
    margin-top: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;

    > div {
      margin-bottom: 40px;

      &:first-child {
        margin-bottom: 28px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .button-red {
      max-width: 210px;
      padding-left: 32px;
      padding-right: 32px;
      @include fonts.helvetica-83-heavy-extended;
    }
  }
}

.star {
  width: 12px;
  display: inline-block;
  margin-right: -2px;
  cursor: pointer;

  &.disabled {
    opacity: 0.3;
  }
}

.sidebar {
  padding-right: 70px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;

  &-stat {
    @include fonts.helvetica-73-bold-extended;
    font-size: 50px;

    @include mq(desktop) {
      font-size: 62px;
    }
  }

  &.star-average {
    .star {
      width: 20px;
      height: 20px;
    }

    .star-average__figure {
      margin-right: 20px;
    }

    .star-average__stars {
      span {
        display: block;
        @include fonts.helvetica-45-light;
        font-size: 16px;
      }
    }
  }

  &.recommend-percentage {
    .recommend-percentage__percent {
      margin-right: 18px;
      padding-left: 10px;
    }

    .recommend-percentage__text {
      @include fonts.helvetica-55-roman;
      font-size: 14px;
      margin-left: 15px;

      @include mq(desktop) {
        font-size: 16px;
      }
    }
  }
}

.leave-a-review {
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;

  &__footer {
    text-align: center;

    &-buttons {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      margin-bottom: 15px;

      a {
        margin-left: 20px;
      }
    }

    span a {
      color: brand.$brand-black;
      text-decoration: underline;
    }
  }

  &__overlay {
    width: 100%;
    height: 100vh;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    &.is-active {
      display: block;
    }
  }

  &__inner {
    position: relative;
    display: block;
    width: 100%;
    max-width: 775px;
    height: auto;
    max-height: 90%;
    margin: 3% auto;
    background-color: brand.$brand-white;
    font-size: 13px;
    @include fonts.helvetica-55-roman;
    font-weight: bold;
    overflow: auto;
    z-index: 12;

    &.has-errors {
      animation: animate-shake 500ms linear;
    }

    .iPhone & {
      // allow for iOS nav bar thing...
      padding-bottom: 50px;
    }

    .form-wrapper {
      height: 100%;
      padding: 20px;

      @include mq($until: tablet) {
        padding: 20px;
        margin-top: 20px;
      }

      @include mq($until: mobile) {
        padding: 10px;
      }

      h3 {
        margin-bottom: 0px;
        @include fonts.helvetica-93-black-extended;
        font-size: 16px;
      }
    }

    .close-form {
      position: absolute;
      top: 0;
      right: 0px;
      width: 35px;
      height: 35px;
      cursor: pointer;
      background: brand.$brand-primary;
      text-align: center;

      @include mq($until: tablet) {
        width: 25px;
        height: 25px;

        &:after {
          font-size: 15px;
        }
      }

      &:after {
        content: '\F00D';
        font-family: 'FontAwesome';
        font-size: 18px;
        color: brand.$brand-white;
        line-height: 0;
        top: 50%;
        left: 52%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
    }

    h2 {
      font-size: 18px !important;
      text-align: center;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    h3 {
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
    }

    .grey-bg {
      margin-top: 11px;
      margin-bottom: 11px;
      padding: 20px 20px 0px 20px;
      text-align: left;

      span {
        font-size: 12px;
        display: block;
      }

      .asterix {
        display: inline-block;
        margin-bottom: 0px;
        color: brand.$brand-primary;
        padding-left: 3px;
      }

      .field-title {
        text-transform: uppercase;
        display: inline-block;
        margin: 0;
        user-select: none;
      }

      .field-summary {
        margin-bottom: 14px;
      }
    }

    .confirmation {
      height: 90%;
      margin: auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      .button-black {
        margin-top: 25px;
      }
    }

    .ratings,
    .attachments {
      display: flex;
      justify-content: space-evenly;
    }

    .ratings {
      margin-bottom: 22px;

      @include mq($until: mobile) {
        flex-direction: column;

        .overall {
          margin-bottom: 10px;
        }
      }

      .recommend {
        text-transform: uppercase;
      }
    }

    .split-fields {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 20px;

      @include mq($until: mobile) {
        flex-wrap: wrap;
        margin-bottom: 0px;
      }
    }

    .split-field {
      width: 49%;

      @include mq($until: mobile) {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .margin-bottom {
      margin-bottom: 20px;
    }

    .form-error {
      border-color: #ee181b;
      background-image: url('~@img-old/svg/validation.svg');
      background-repeat: no-repeat;
      background-position: 98% 8px;
      background-size: 14px 14px;
    }

    .form-error-message {
      color: #ee181b;
    }

    input,
    textarea {
      margin-top: 6px;
      resize: none;
      border: 1px solid #ccc;
      background-color: brand.$brand-white;
      padding: 10px;

      &:focus {
        outline: none;
      }
    }

    textarea {
      height: 100px;
    }

    .answers {
      display: inline-flex;
      margin-top: 6px;

      .answer {
        width: 78px;
        padding: 10px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        border: 1px solid #ccc;

        &.is-selected {
          background-color: brand.$brand-black;
          border: 1px solid brand.$brand-black;
          color: brand.$brand-white;
        }
      }
    }

    .attachments {
      .photo {
        width: 150px;
      }

      .file-container {
        width: 50px;
        height: 50px;
        display: block;
        background-image: url('~@img-old/svg/photo.svg');
        background-repeat: no-repeat;
        background-position: left center;

        input {
          width: 100%;
          height: 100%;
          appearance: none;
          opacity: 0;
          cursor: pointer;
        }
      }

      .file-list {
        padding-left: 0px;
        margin-top: 10px;
      }

      .youtube {
        margin-left: 70px;
      }
    }
  }

  &.is-active {
    display: block;
  }

  &__all-errors {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .tooltip-container {
    display: inline-block;
    cursor: default;

    @include mq($until: tablet) {
      cursor: pointer;
    }

    .review-tip {
      position: relative;
      user-select: none;
      display: inline;
      margin-left: 8px;

      i {
        color: brand.$brand-black;
        font-size: 16px;
      }

      .review-tooltip {
        display: none;
        position: absolute;
        background: brand.$brand-black;
        color: brand.$brand-white;
        padding: 15px;
        border: 1px solid;
        z-index: 1;
        width: 450px;
        box-shadow: 8px 8px 34px rgba(0, 0, 0, 0.2);

        @include mq($until: tablet) {
          position: relative;
          width: 100%;
        }

        @include mq($until: mobile) {
          padding: 10px;
        }

        &.is-open {
          display: block;
        }
      }
    }
  }
}

.leave-a-review {
  .form-control__checkbox-text {
    a {
      color: brand.$brand-black;
      text-decoration: underline;
    }
  }
}

.stars {
  margin-top: 12px;

  .rating {
    display: inline-block;
    font-size: 20px;
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    position: relative;
  }

  &.selected-10 {
    .star {
      &:nth-of-type(1) {
        background-image: url('~@img-old/svg/stars/star-red.svg');
      }
    }
  }

  &.selected-15 {
    .star {
      &:nth-of-type(1) {
        background-image: url('~@img-old/svg/stars/star-red.svg');
      }

      &:nth-of-type(2) {
        background-image: url('~@img-old/svg/stars/star-red-half.svg');
      }
    }
  }

  &.selected-20 {
    .star {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        background-image: url('~@img-old/svg/stars/star-red.svg');
      }
    }
  }

  &.selected-25 {
    .star {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        background-image: url('~@img-old/svg/stars/star-red.svg');
      }

      &:nth-of-type(3) {
        background-image: url('~@img-old/svg/stars/star-red-half.svg');
      }
    }
  }

  &.selected-30 {
    .star {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        background-image: url('~@img-old/svg/stars/star-red.svg');
      }
    }
  }

  &.selected-35 {
    .star {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        background-image: url('~@img-old/svg/stars/star-red.svg');
      }

      &:nth-of-type(4) {
        background-image: url('~@img-old/svg/stars/star-red-half.svg');
      }
    }
  }

  &.selected-40 {
    .star {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        background-image: url('~@img-old/svg/stars/star-red.svg');
      }
    }
  }

  &.selected-45 {
    .star {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        background-image: url('~@img-old/svg/stars/star-red.svg');
      }

      &:nth-of-type(5) {
        background-image: url('~@img-old/svg/stars/star-red-half.svg');
      }
    }
  }

  &.selected-50 {
    .star {
      background-image: url('~@img-old/svg/stars/star-red.svg');
    }
  }

  .star {
    width: 30px;
    height: 30px;
    background-image: url('~@img-old/svg/stars/star-grey.svg');
    background-repeat: no-repeat;
  }

  &--form {
    display: flex;

    .star {
      background-image: url('~@img-old/svg/stars/star-red-outline.svg');
    }
  }
}

// .stars--red {
//   &.selected-5 {
//     .star {
//       &:nth-of-type(1) {
//         background-image: url('~@img-old/svg/stars/star-red-half.svg');
//       }
//     }
//   }

//   &.selected-10 {
//     .star {
//       &:nth-of-type(1) {
//         background-image: url('~@img-old/svg/stars/star-red.svg');
//       }
//     }
//   }

//   &.selected-15 {
//     .star {
//       &:nth-of-type(1) {
//         background-image: url('~@img-old/svg/stars/star-red.svg');
//       }

//       &:nth-of-type(2) {
//         background-image: url('~@img-old/svg/stars/star-red-half.svg');
//       }
//     }
//   }

//   &.selected-20 {
//     .star {

//       &:nth-of-type(1),
//       &:nth-of-type(2) {
//         background-image: url('~@img-old/svg/stars/star-red.svg');
//       }
//     }
//   }

//   &.selected-25 {
//     .star {

//       &:nth-of-type(1),
//       &:nth-of-type(2) {
//         background-image: url('~@img-old/svg/stars/star-red.svg');
//       }

//       &:nth-of-type(3) {
//         background-image: url('~@img-old/svg/stars/star-red-half.svg');
//       }
//     }
//   }

//   &.selected-30 {
//     .star {

//       &:nth-of-type(1),
//       &:nth-of-type(2),
//       &:nth-of-type(3) {
//         background-image: url('~@img-old/svg/stars/star-red.svg');
//       }
//     }
//   }

//   &.selected-35 {
//     .star {

//       &:nth-of-type(1),
//       &:nth-of-type(2),
//       &:nth-of-type(3) {
//         background-image: url('~@img-old/svg/stars/star-red.svg');
//       }

//       &:nth-of-type(4) {
//         background-image: url('~@img-old/svg/stars/star-red-half.svg');
//       }
//     }
//   }

//   &.selected-40 {
//     .star {

//       &:nth-of-type(1),
//       &:nth-of-type(2),
//       &:nth-of-type(3),
//       &:nth-of-type(4) {
//         background-image: url('~@img-old/svg/stars/star-red.svg');
//       }
//     }
//   }

//   &.selected-45 {
//     .star {

//       &:nth-of-type(1),
//       &:nth-of-type(2),
//       &:nth-of-type(3),
//       &:nth-of-type(4) {
//         background-image: url('~@img-old/svg/stars/star-red.svg');
//       }

//       &:nth-of-type(5) {
//         background-image: url('~@img-old/svg/stars/star-red-half.svg');
//       }
//     }
//   }

//   &.selected-50 {
//     .star {
//       background-image: url('~@img-old/svg/stars/star-red.svg');
//     }
//   }

//   .star {
//     background-image: url('~@img-old/svg/stars/star-red-outline.svg');
//   }
// }

.product__review-star-container .stars {
  margin-top: 0px;

  .star {
    width: 20px;
    height: 22px;
  }
}

@keyframes animate-shake {
  8%,
  41% {
    transform: translateX(-10px);
  }

  25%,
  58% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-5px);
  }

  92% {
    transform: translateX(5px);
  }

  0%,
  100% {
    transform: translateX(0);
  }
}
