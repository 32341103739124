@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-content {
  display: block;
  margin: 0 auto 60px;
  opacity: 0;
  transition: opacity 500ms ease, transform 500ms ease;
  transform: translateY(5%);

  &.animated {
    opacity: 1;
    transform: translateY(0%);
  }

  &--lead {
    @include fonts.helvetica-53-extended;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 30px;
  }
}
