@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use './helpers/functions' as *;
@use './helpers/mixins' as mixins;

.ds-download {
  display: flex;
  justify-content: space-between;
  background: brand.$brand-grey-light;
  padding: flow(0.6) flow(1);
  margin-top: -1px;
  max-width: calc(100% - #{flow(2)});

  @include mixins.fade-in-up(300ms, 600ms, 20px, global.$easing);

  .ds-theme--red & {
    background-color: brand.$brand-primary-dark;
  }

  .ds-theme--nearly-black & {
    background-color: brand.$brand-grey-dark;
  }

  &--mb-15 {
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    padding-right: flow(0.3);

    svg {
      width: 20px;
      height: 30px;
      color: brand.$brand-primary;

      .ds-theme--nearly-black &,
      .ds-theme--red & {
        color: brand.$brand-white;
      }
    }
  }

  &__title {
    @include fonts.helvetica-55-roman();
    font-size: 18px;
    color: brand.$brand-grey-dark;
    padding-right: flow(0.3);

    .ds-theme--red &,
    .ds-theme--nearly-black & {
      color: brand.$brand-white;
    }
  }

  &__size {
    @include fonts.helvetica-55-roman();
    font-size: 14px;
    color: brand.$brand-grey-dark;
    opacity: 0.5;

    .ds-theme--red & {
      color: brand.$brand-white;
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__link {
    @include fonts.helvetica-55-roman();
    font-size: 16px;
    color: brand.$brand-grey-dark;
    text-decoration: underline;
    padding-right: flow(0.6);
    cursor: pointer;

    .ds-theme--red &,
    .ds-theme--nearly-black & {
      color: brand.$brand-white;
    }
  }

  &__arrow {
    display: flex;
    svg {
      width: 15px;
      height: 20px;
      color: brand.$brand-primary;

      .ds-theme--nearly-black &,
      .ds-theme--red & {
        color: brand.$brand-white;
      }
    }
  }
}
