@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

.laser-callouts {
  background: brand.$brand-primary;
  padding: 60px 0;

  &__inner {
    max-width: 1344px;
    width: 100%;
  }

  &__text {
    @include anim-fade-up(0s, 20px, self);

    max-width: 640px;
    margin: 0 auto 50px;
    text-align: center;

    h2,
    .ds & h2 {
      color: brand.$brand-white;
      margin: 0 0 18px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      @include fonts.helvetica-53-extended;
      color: brand.$brand-white;
    }
  }

  &__row {
    width: 100%;

    @include mq(tablet) {
      display: grid;
      grid-gap: 24px;
      grid-auto-flow: column;
      justify-content: center;
    }
  }

  &__card {
    @include anim-fade-up(0s, 20px, self);
    @include anim-loop-delay(3, 100ms);

    display: flex;
    flex-direction: column;
    margin: 0 auto 30px;
    max-width: 418px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq(tablet) {
      margin-bottom: 0;
    }

    &-image {
      overflow: hidden;

      img {
        display: block;
        transform: scale(1);
        transition: transform 300ms $anim-easing-cubic;

        .laser-callouts__card:hover & {
          transform: scale(1.16);
        }
      }
    }

    &-block {
      position: relative;
      flex: 1;
      background: brand.$brand-black;
      padding: 26px 20px 20px;
      text-align: center;

      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 24px;
        background: brand.$brand-black;
        clip-path: polygon(50% 0%, 0% calc(100% + 1px), 100% 100%);
        transition: clip-path 300ms $anim-easing-cubic;

        .laser-callouts__card:hover & {
          clip-path: polygon(50% 100%, 0% 100%, 100% 100%);
        }
      }

      h3 {
        color: brand.$brand-white;
        text-transform: uppercase;
        margin: 0 0 20px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        @include fonts.helvetica-53-extended;
        color: brand.$brand-white;
      }
    }
  }
}
