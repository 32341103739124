@use '../settings/settings-brand' as brand;

.ds-theme {
  &--red {
    background-color: brand.$brand-primary;
    color: brand.$brand-white;
  }

  &--white {
    background-color: brand.$brand-white;
    color: brand.$brand-nearly-black;
  }

  &--nearly-black {
    background: brand.$brand-nearly-black;
    color: brand.$brand-white;
  }
}
