@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use '../base/base-fonts' as typography;
@use '../helpers/functions' as *;
@use '../helpers/mixins' as mixins;

.ds-form-container {
  padding: flow(2) 0;

  @include mq(tablet) {
    padding: flow(3) 0;
  }

  .ds-form--basic-signup & {
    @include mq(tablet) {
      padding: flow(2) 0;
    }
  }

  &__title {
    .ds-content-wide-wide & {
      font-size: 30px;
      line-height: 32px;
      text-align: center;
    }

    .ds & {
      @include typography.h1--feature;
      margin-top: 0;
      display: block;
      color: brand.$brand-white;
      text-align: center;
      text-transform: uppercase;
      opacity: 0;
    }

    .animated & {
      @include mixins.fade-in-up(500ms, 250ms, 20px, global.$easing);
    }

    .EditMode &,
    .DesignMode & {
      opacity: 1;
    }
  }

  &__subtitle {
    .ds-content-wide-wide & {
      font-family: 'Helvetica Neue LT W05_53 Ext' !important;
      font-size: 18px;
      font-weight: 400 !important;
      font-style: normal !important;
      line-height: 28px;
      text-align: center;
    }
  }

  .ds-form {
    margin-bottom: 0;

    @include mq(tablet) {
      padding: flow(1) flow(1.5);
    }

    &__fields {
      grid-gap: 12px;
      grid-template-columns: 1fr 1fr;
    }

    &__field {
      a {
        color: inherit;
        @include fonts.helvetica-93-black-extended;
      }

      &--right-aligned {
        text-align: end;
      }

      /* IE - */
      @media all and (-ms-high-contrast: none) {
        margin-bottom: 20px;
      }
    }

    .po-submission-form__title {
      margin-top: 0;
      line-height: 26px;

      @include mq($until: tablet) {
        margin-bottom: 0;
      }
      @include mq(xlg) {
        line-height: 54px;
      }
    }

    .ds-button {
      display: inline-block;
      font-size: 12px;
      transition: background-color 150ms global.$easing;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 24px;
      @include fonts.helvetica-93-black-extended;
      color: inherit;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }

    .ds-form__smallprint {
      margin-bottom: 20px;
      font-size: 80%;
    }

    input[type='radio'] {
      display: none;

      + label {
        margin-bottom: flow(0.5);

        &:before {
          z-index: 10;
        }

        &:after {
          background: brand.$brand-white;
          border: 4px solid brand.$brand-primary-dark;
        }
      }

      &:checked + label:after {
        opacity: 1;
      }
    }

    input[type='checkbox'] {
      + .ds-form__label:after {
        background-color: transparent;
      }
    }

    // needed without classes for .net radio lists
    input[type='radio'] {
      + label {
        @include fonts.helvetica-53-extended;
        position: relative;
        left: auto;
        top: auto;
        display: block;
        padding-left: 46px;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 12px;
        opacity: 1;
        transform: none;
        transition: none;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 20px;
          display: block;
          width: 18px;
          height: 18px;
          border: 1px solid brand.$brand-grey-dark;
          border-radius: 50%;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0px;
          left: 20px;
          display: block;
          width: 18px;
          height: 18px;
          opacity: 0;
          transition: opacity 0.3s global.$easing;
          background-color: brand.$brand-primary;
          background-image: url('~@img/svg/icon-tick--white.svg');
          background-repeat: no-repeat;
          background-size: 14px 10px;
          background-position: center center;
          border-radius: 50%;

          .invalid & {
            background-color: brand.$brand-invalid;
          }
        }
      }
    }
  }

  &__single-column {
    display: flex;
    justify-content: center;
  }

  &__split {
    display: flex;
    opacity: 0;

    .animated & {
      @include mixins.fade-in-up(500ms, 450ms, 20px, global.$easing);
    }

    .EditMode &,
    .DesignMode & {
      opacity: 1;
    }

    &--horizontal {
      flex-direction: column;
      align-items: stretch;
      flex-grow: 1;

      @include mq(tablet) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &--vertical {
      flex-direction: column;
      align-items: stretch;
      flex-grow: 1;
    }
  }

  &__half {
    width: 100%;

    .ds-form-container__single-column & {
      @include mq(tablet) {
        width: 75%;
        flex-basis: 75%;
      }
    }

    .ds-form-container__split--horizontal & {
      @include mq(tablet) {
        width: 50%;
        flex-basis: 50%;
      }

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;

        @include mq(tablet) {
          position: sticky;
          top: 25px;
        }

        @include mq(desktop) {
          top: 50px;
        }
      }
    }

    &:nth-child(1) {
      .ds-form-container__split--horizontal & {
        order: 2;
      }

      .ds-form-container__split--left & {
        @include mq(tablet) {
          order: 1;
          padding-right: flow(0.5);
        }

        @include mq(desktop) {
          padding-right: flow(1.5);
        }
      }

      .ds-form-container__split--right & {
        @include mq(tablet) {
          order: 2;
          padding-left: flow(0.5);
        }

        @include mq(desktop) {
          padding-left: flow(1.5);
        }
      }

      .ds-form-container__split--top & {
        margin-bottom: flow(2);

        @include mq(tablet) {
          text-align: unset;
          margin-bottom: unset;
          order: 1;
        }
      }

      .ds-form-container__split--bottom & {
        order: 2;
      }
    }

    &:nth-child(2) {
      order: 1;

      .ds-form-container__split--horizontal & {
        text-align: center;
        margin-bottom: flow(2);

        @include mq(tablet) {
          text-align: unset;
          margin-bottom: unset;
        }
      }

      .ds-form-container__split--left & {
        @include mq(tablet) {
          order: 2;
          padding-left: flow(0.5);
        }

        @include mq(desktop) {
          padding-left: flow(1.5);
        }
      }

      .ds-form-container__split--right & {
        @include mq(tablet) {
          order: 1;
          padding-right: flow(0.5);
        }

        @include mq(desktop) {
          padding-right: flow(1.5);
        }
      }

      .ds-form-container__split--top & {
        text-align: center;

        @include mq(tablet) {
          order: 2;
          margin-top: flow(2);
        }
      }

      .ds-form-container__split--bottom & {
        order: 1;
        text-align: center;
        margin-bottom: flow(2);
      }
    }
  }

  &.ds-theme--red {
    background: brand.$brand-primary;

    .ds-button {
      background: brand.$brand-white;
      color: brand.$brand-black;

      &:hover {
        background: brand.$brand-grey;
      }
    }

    .ds-form__message--invalid,
    .ds-form__field--message,
    .invalid .ds-form__label,
    .ds-form__dropdown-selected {
      color: inherit;
    }

    .ds-form__control {
      border-bottom-color: brand.$brand-white;
      background-color: transparent;
      color: brand.$brand-white;

      &::placeholder {
        color: inherit;
      }
    }

    input[type='radio'] {
      display: none;

      + label {
        &:before {
          border-color: brand.$brand-white;
        }

        &:after {
          background: brand.$brand-white;
          border: 4px solid brand.$brand-primary-dark;
        }
      }
    }

    input[type='checkbox'] {
      + .ds-form__label:before {
        border-color: brand.$brand-white;
      }
    }
  }

  &.ds-theme--white {
    background: brand.$brand-grey;

    .ds-button {
      background: brand.$brand-primary;
      color: brand.$brand-white;

      &:hover {
        background: brand.$brand-primary-dark;
      }
    }

    .po-submission-form__title {
      color: brand.$brand-primary;
    }

    .ds-form-container__title {
      color: brand.$brand-black;
    }

    .ds-form__control,
    .ds-form__control::placeholder,
    .ds-form__label,
    .ds-form__disclaimer {
      color: brand.$brand-black;
    }

    .ds-form__control--stealth:-webkit-autofill,
    .ds-form__control--stealth:-webkit-autofill:hover,
    .ds-form__control--stealth:-webkit-autofill:focus,
    .ds-form__control--stealth:-webkit-autofill:active {
      -webkit-text-fill-color: brand.$brand-black;
    }

    .ds-form__control--stealth,
    input[type='checkbox'] + .ds-form__label--white:before {
      border-color: brand.$brand-black;
    }

    .ds-form__control--stealth .ds-form__dropdown-selected:after {
      color: black;
      background-image: url('~@img/svg/icon-angle-down.svg');
    }

    input[type='radio'] {
      display: none;

      + label {
        &:before {
          border-color: brand.$brand-black;
        }

        &:after {
          background: brand.$brand-primary;
          border: 4px solid brand.$brand-white;
        }
      }
    }

    input[type='checkbox'] {
      + .ds-form__label:after {
        background-color: transparent;
      }

      &:checked + .ds-form__label:after {
        background-color: brand.$brand-primary;
      }
    }

    .ds-form__fake-upload {
      background-color: brand.$brand-grey-light;
      border-color: brand.$brand-black;
      color: brand.$brand-black;
    }
  }

  &.ds-theme--nearly-black {
    background: brand.$brand-black;

    .ds-button {
      background: brand.$brand-primary;
      color: brand.$brand-white;

      &:hover {
        background: brand.$brand-primary-dark;
      }
    }

    input[type='radio'] {
      display: none;

      + label {
        &:before {
          border-color: brand.$brand-white;
        }

        &:after {
          background: brand.$brand-primary;
          border: 3px solid brand.$brand-white;
        }
      }
    }

    input[type='checkbox'] {
      + .ds-form__label:before {
        border-color: brand.$brand-white;
      }

      + .ds-form__label:after {
        background-color: transparent;
      }

      &:checked + .ds-form__label:after {
        background-color: brand.$brand-primary;
      }
    }
  }
}
