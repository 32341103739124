@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

.laser-text-banner {
  padding: 24px 0;

  &--primary {
    background: brand.$brand-primary;

    h2,
    .ds & h2 {
      color: brand.$brand-white;
    }
  }

  &--light {
    background: brand.$brand-white;

    h2,
    .ds & h2 {
      color: brand.$brand-primary;
    }
  }

  &--dark {
    background: brand.$brand-black;

    h2,
    .ds & h2 {
      color: brand.$brand-white;
    }
  }

  &__inner {
    h2,
    .ds & h2 {
      @include anim-fade-up(100ms, 20px, parent);

      font-size: 24px;
      margin: 0;
      @include fonts.helvetica-45-light;
      text-transform: uppercase;
      text-align: center;
      white-space: pre;

      @include mq(tablet) {
        font-size: 40px;
      }

      sup {
        top: -12px;
        left: 5px;
        font-size: 62%;
      }

      strong {
        @include fonts.helvetica-93-black-extended;
      }
    }
  }
}
