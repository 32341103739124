@use 'sass:math';
@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use '../helpers/functions' as functions;

.ds-content-video {
  padding: functions.flow(2) 0px;
  background-color: brand.$brand-grey-light;
  position: relative;
  overflow: hidden;

  @include mq($from: mobile) {
    padding: functions.flow(4) 0px;
  }

  &:before {
    content: '';
    position: absolute;
    right: -45%;
    top: 50%;
    width: 100vw;
    height: 200vh;
    background-color: brand.$brand-white;
    transform: translate(100%, -50%) skewX(10deg);
    transition: transform 375ms cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.25);
  }

  &.animated:before {
    transform: translate(0%, -50%) skewX(10deg);
  }

  &__grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;

    @include mq($from: mobile) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__video {
    flex: 0 0 100%;
    height: 0px;
    position: relative;
    padding-bottom: 56.25%;
    background-color: brand.$brand-black;

    opacity: 0;
    transition: opacity 500ms 400ms ease, transform 500ms 400ms ease;
    transform: translateX(-5%);

    &.animated {
      opacity: 1;
      transform: none;
    }

    @include mq($from: mobile) {
      margin-right: global.$padding;
      flex: 0 0 50%;

      // 56.25% being 16:9 aspect ratio as a percentage
      // Half of that as this is a 50/50 widget
      padding-bottom: calc(56.25% / 2);
    }

    &.video-push-start-container {
      height: auto;
      margin-bottom: 30px;
      padding: 0;
      order: 1;

      @include mq($from: mobile) {
        margin: 0 24px 0 0;
      }

      + .ds-content-video__content {
        @include mq($from: mobile) {
          order: 2;
        }
      }

      .js-lazy + .push-button-start {
        opacity: 0;
        transition: opacity 700ms 500ms global.$easing;
      }

      .js-lazy.loaded + .push-button-start {
        opacity: 1;
      }

      .push-button-start {
        position: absolute;
        top: 50%;
        margin: 0;
        transform: translate(-50%, -50%) scale(0.45);

        @include mq($from: 550px) {
          transform: translate(-50%, -50%) scale(0.6);
        }

        @include mq($from: tablet) {
          transform: translate(-50%, -50%) scale(0.8);
        }
      }
    }

    > video,
    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
    }
  }

  &__content {
    flex: 0 0 100%;

    opacity: 0;
    transition: opacity 500ms 400ms ease, transform 500ms 400ms ease;
    transform: translateX(5%);
    margin-bottom: 20px;

    &.animated {
      opacity: 1;
      transform: translateX(0%);
    }

    @include mq($from: mobile) {
      flex: 0 0 calc(50% - 50px);
      margin-left: global.$padding;
    }

    @include mq($from: tablet) {
      margin-bottom: 0;
    }

    h1 {
      margin-top: 0px;
    }

    h2 {
      margin: 0 0 14px;

      .ds & {
        font-size: 30px;
        line-height: 34px;
      }

      @include mq($until: tablet) {
        font-size: 24px;

        .ds & {
          font-size: 24px;
          line-height: 26px;
        }
      }
    }

    p {
      @include fonts.helvetica-53-extended;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  &__caption {
    @include fonts.helvetica-73-bold-extended;
    color: brand.$brand-primary;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 14px;
  }
}
