@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-video {
  height: 0px;
  position: relative;
  background-color: brand.$brand-black;

  &__trigger {
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 1px;
  }

  &__desktop {
    display: none;

    @include mq($from: tablet) {
      display: block;
    }
  }

  &__mobile {
    display: block;

    @include mq($from: tablet) {
      display: none;
    }
  }

  &__play {
    cursor: pointer;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;

    &:after {
      content: '\f04b';
      font-family: 'FontAwesome';
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 32px;
      transform: translate(-50%, -50%);
      color: brand.$brand-white;
      opacity: 0;
      transition: opacity 375ms ease;
    }

    &.active::after {
      opacity: 1;
    }
  }

  &__volume {
    cursor: pointer;
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    &:after {
      content: '\f026';
      font-family: 'FontAwesome';
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 42px;
      transform: translate(-50%, -50%);
      color: brand.$brand-white;
      animation: pulse 2s infinite linear;
    }

    @keyframes pulse {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.25;
      }
      100% {
        opacity: 1;
      }
    }

    &.playing-audio:after {
      content: '\f028';
    }
  }

  video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
