@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-layers' as layers;
@use '../settings/settings-fonts' as fonts;
@use './helpers/functions' as *;

.ds-article-banner {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: brand.$brand-grey;

  &__image {
    position: relative;
    z-index: layers.$layer-default;
    margin-bottom: -#{flow(1.25)};
    padding: 0 flow(1);

    @include mq(tablet) {
      margin-bottom: -#{flow(3)};
    }

    img {
      display: block;
      box-shadow: 0 3px 6px rgba(brand.$brand-black, 0.16);
    }
  }

  &__back {
    position: absolute;
    margin-right: auto;
    margin-bottom: 10px;
    padding-left: 10px;
    text-transform: uppercase;
    font-size: 12px;
    color: #1f1f1f;
    left: 30px;
    top: 24px;

    &:before {
      content: '';
      width: 16px;
      height: 8px;
      background-image: url('~@img-old/svg/arrow-back.svg');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
    }
  }

  &__inner {
    width: 100%;
    background: brand.$brand-grey-light;
    padding: flow(2.25) 0 flow(0.5);

    @include mq(tablet) {
      padding: flow(4.25) flow(1) flow(0.5);
    }

    strong {
      display: block;
      margin-bottom: flow(0.5);
      font-size: 12px;
      @include fonts.helvetica-93-black-extended;
      text-transform: uppercase;
    }

    h1 {
      margin-bottom: flow(1.2);
      font-size: 20px;
      @include fonts.helvetica-93-black-extended;
      text-transform: uppercase;

      @include mq(tablet) {
        font-size: 24px;
      }
    }

    small {
      display: block;
      font-size: 12px;
      @include fonts.helvetica-53-extended;
      color: rgba(51, 51, 51, 0.75);

      @include mq(tablet) {
        font-size: 16px;
      }
    }
  }
}
