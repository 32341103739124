@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use '../helpers/mixins' as mixins;
@use '../helpers/animations' as *;

$redemption-mobile-breakpoint: 1024px;
$skewed-padding: 60px;

$redemption-grad-dark: #b80117;
$redemption-grad-light: #ca011a;

$modal-header-height: 177px;

.red-promo {
  &__title {
    display: block;
    padding: 50px 24px 0px;
    background: brand.$brand-primary-dark;
    text-align: center;

    @include mq($redemption-mobile-breakpoint) {
      display: none;
    }

    .red-promo__card & {
      display: none;
      margin: -40px 0 60px;
      padding: 0;
      background: none;

      @include mq($redemption-mobile-breakpoint) {
        display: block;

        @supports (display: grid) {
          transform: skewX(12deg);
        }

        // Legacy Edge (12-18)
        @supports (-ms-ime-align: auto) {
          transform: none;
        }
      }
    }

    &.animated img {
      animation-play-state: running;
    }

    img {
      @include anim-fade-up(0ms, 20px, parent);
    }
  }

  &__row {
    position: relative;
    overflow: hidden;

    @include mq($redemption-mobile-breakpoint) {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      background: linear-gradient($redemption-grad-light, darken($redemption-grad-dark, 2%));
    }

    &:after {
      content: '';
      display: none;
      position: absolute;
      z-index: 10;
      width: 100px;
      height: 100%;
      top: 0;
      right: -100px;
      background: linear-gradient($redemption-grad-dark, lighten($redemption-grad-light, 2%));

      @include mq($redemption-mobile-breakpoint) {
        display: block;

        @supports (display: grid) {
          transform: skewX(-12deg);
        }

        // Legacy Edge (12-18)
        @supports (-ms-ime-align: auto) {
          transform: none;
        }
      }
    }
  }

  &__card {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 50px 24px;
    overflow: hidden;

    @include mq(mobile) {
      padding: 50px;
    }

    @include mq($until: 1023px) {
      background: linear-gradient(brand.$brand-primary-dark, brand.$brand-primary);
    }

    @include mq($redemption-mobile-breakpoint) {
      flex: 1;
      max-width: 50vw;
      padding: 100px 0;
    }

    @supports (display: grid) {
      @include mq($redemption-mobile-breakpoint) {
        transform: skewX(-12deg);
      }
    }

    // Legacy Edge (12-18)
    @supports (-ms-ime-align: auto) {
      transform: none;
    }

    &:nth-child(1) {
      overflow: visible;

      @supports (display: grid) {
        @include mq($redemption-mobile-breakpoint) {
          padding-left: calc(#{$skewed-padding} / 2);
        }
      }

      // Legacy Edge (12-18)
      @supports (-ms-ime-align: auto) {
        @include mq($redemption-mobile-breakpoint) {
          padding-left: 0;
        }
      }

      .red-promo__card-skewed-line {
        @include mq($redemption-mobile-breakpoint) {
          border: none;
        }
      }
    }

    &:nth-child(2) {
      @include mq($redemption-mobile-breakpoint) {
        background: linear-gradient($redemption-grad-dark, lighten($redemption-grad-light, 2%));
      }
    }

    &:nth-child(1),
    &:nth-child(3) {
      overflow: visible;

      &:after {
        content: '';
        display: none;
        position: absolute;
        width: 50vw;
        height: 100%;
        top: 0;
        right: -50vw;
        background: linear-gradient($redemption-grad-light, darken($redemption-grad-dark, 2%));

        @include mq($redemption-mobile-breakpoint) {
          display: block;
        }
      }
    }

    &:last-child {
      z-index: 11;
    }

    &-skewed-line {
      border-left: 1px solid transparent;
      transition: border-color 300ms $anim-easing-cubic;

      &.animated {
        border-color: brand.$brand-black;
      }

      .red-promo__card:nth-child(2) &,
      .red-promo__card:nth-child(3) & {
        overflow: hidden;
      }
    }

    &-inner {
      @supports (display: grid) {
        @include mq($redemption-mobile-breakpoint) {
          transform: skewX(12deg);
        }
      }

      // Legacy Edge (12-18)
      @supports (-ms-ime-align: auto) {
        transform: none;
      }
    }
  }

  &__kit {
    margin: 34px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-image {
      @include anim-fade-up(100ms, 20px, parent);

      @include mq($redemption-mobile-breakpoint) {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 272px;
      }

      img {
        display: block;
        max-width: calc(100% - #{$skewed-padding});

        @include mq($redemption-mobile-breakpoint) {
          max-height: 272px;
        }
      }
    }

    &-included-link {
      @include anim-fade-up(200ms, 20px, parent);

      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
      @include fonts.helvetica-83-heavy-extended;
      text-transform: uppercase;
      color: brand.$brand-white;
      background: url('~@img/svg/icon-question-circle.svg') no-repeat right center;
      padding-right: 31px;

      @include mq(desktop) {
        font-size: 19px;
        line-height: 21px;
      }

      span {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          border-bottom: 1px solid brand.$brand-white;
          transform-origin: center;
          transition: transform 250ms $anim-easing-cubic;
        }
      }

      &:hover {
        span:before {
          transform: translateY(4px) scaleX(0.8);
        }
      }
    }
  }

  &__cta {
    @include anim-fade-up(300ms, 20px, parent);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, brand.$brand-primary, rgba(brand.$brand-primary, 0));
    width: 100%;
    padding: 10px 12px;
    margin-bottom: 36px;
    box-shadow: rgba(brand.$brand-black, 0.16) -6px 3px 6px -2px;

    @include mq(mobile) {
      padding: 10px 24px;
    }

    @include mq($redemption-mobile-breakpoint) {
      width: calc(100% - #{$skewed-padding});
    }

    .red-promo__card:nth-child(2) &,
    .red-promo__card:nth-child(3) & {
      @supports (display: grid) {
        @include mq($redemption-mobile-breakpoint) {
          margin-left: -#{$skewed-padding};
          padding-left: $skewed-padding;
          width: calc(100% - (#{$skewed-padding} / 2));
        }
      }

      // Legacy Edge (12-18)
      @supports (-ms-ime-align: auto) {
        @include mq($redemption-mobile-breakpoint) {
          margin-left: 0;
          padding-left: 24px;
          width: calc(100% - #{$skewed-padding});
        }
      }
    }

    &-split {
      strong {
        display: block;
        font-size: 14px;
        @include fonts.helvetica-63-medium-extended;
        color: brand.$brand-white;

        @include mq(desktop) {
          font-size: 18px;
        }
      }

      small {
        font-size: 12px;
        @include fonts.helvetica-53-extended;
        color: brand.$brand-white;

        @include mq(desktop) {
          font-size: 16px;
        }
      }

      .ds-button {
        @include mq($until: desktop) {
          font-size: 14px;
        }

        @include mq($redemption-mobile-breakpoint, $until: desktop) {
          font-size: 11px;
          padding: 2px 10px;
        }

        &.ds-button--black {
          background-color: brand.$brand-black;

          &:hover {
            background-color: brand.$brand-nearly-black;
          }
        }
      }
    }

    + .red-promo__gradient-strip {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  &__gradient-strip {
    @include anim-fade-up(0s, 20px, parent);

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    background: linear-gradient(to right, #121212, rgba(18, 18, 18, 0));
    width: 100%;
    font-size: 12px;
    @include fonts.helvetica-93-black-extended-oblique;
    color: brand.$brand-primary;
    text-transform: uppercase;
    padding: 4px 12px;

    @include mq(mobile) {
      font-size: 14px;
      padding: 4px 24px;
    }

    @include mq($redemption-mobile-breakpoint) {
      width: calc(100% - #{$skewed-padding});
    }

    @include mq(desktop) {
      font-size: 14px;
    }

    &:nth-last-of-type(1) {
      @include anim-fade-up(400ms, 20px, parent);
    }

    .red-promo__card:nth-child(2) &,
    .red-promo__card:nth-child(3) & {
      width: calc(100% - (#{$skewed-padding} / 2));

      &:nth-child(1) {
        @supports (display: grid) {
          @include mq($redemption-mobile-breakpoint) {
            padding-left: calc(#{$skewed-padding} + (#{$skewed-padding} / 4));
          }
        }

        // Legacy Edge (12-18)
        @supports (-ms-ime-align: auto) {
          @include mq($redemption-mobile-breakpoint) {
            padding-left: 24px;
          }
        }
      }

      &:nth-last-of-type(1) {
        @supports (display: grid) {
          @include mq($redemption-mobile-breakpoint) {
            margin-left: -#{$skewed-padding};
            padding-left: $skewed-padding;
          }
        }

        // Legacy Edge (12-18)
        @supports (-ms-ime-align: auto) {
          @include mq($redemption-mobile-breakpoint) {
            margin-left: 0;
            padding-left: 24px;
          }
        }
      }
    }

    .red-promo__modal-header & {
      animation: none;
      transform: none;
      opacity: 1;
      width: 100%;
      padding-left: 14px;
    }

    img {
      display: inline-block;
      max-width: 150px;
      max-height: 15px;
      margin-right: 20px;
      margin-top: 6px;
      margin-bottom: 6px;

      @include mq(mobile) {
        max-width: 196px;
        max-height: 20px;
      }
    }

    .red-promo__outline-text {
      display: inline-flex;
      align-self: center;
      font-size: 16px;
      line-height: 16px;
      @include fonts.helvetica-93-black-extended-oblique;
      text-transform: uppercase;
      color: brand.$brand-white;
      margin: 6px 0;

      @supports (display: grid) {
        color: transparent;
        text-shadow: none;
        -webkit-text-stroke: 1px brand.$brand-white;
      }

      @include mq(desktop) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    strong {
      color: brand.$brand-white;
    }
  }

  &__products {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    padding-left: calc(#{$skewed-padding} / 2);

    .red-promo__card:nth-child(1) & {
      padding-left: $skewed-padding;
    }

    &-image {
      margin-right: 14px;

      @include mq(desktop) {
        margin-right: 30px;
      }

      img {
        display: block;
        max-height: 166px;
      }
    }

    &-free {
      flex-basis: 120px;

      @include mq(desktop) {
        flex-basis: 318px;
        padding-right: 20px;
      }

      strong {
        display: block;
        font-size: 30px;
        line-height: 30px;
        @include fonts.helvetica-93-black-extended-oblique;
        color: brand.$brand-white;
        text-transform: uppercase;
        margin-bottom: 10px;

        @include mq(desktop) {
          font-size: 46px;
          line-height: 46px;
        }
      }

      small {
        display: block;
        font-size: 14px;
        @include fonts.helvetica-53-extended;
        color: brand.$brand-white;
        text-transform: uppercase;
        margin-bottom: 4px;

        @include mq(desktop) {
          font-size: 16px;
        }
      }
    }
  }

  &__modal {
    pointer-events: none;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(brand.$brand-black, 0.53);
    opacity: 0;
    transition: opacity 500ms global.$easing;

    @include mq(tablet) {
      padding: 50px;
    }

    &--open {
      pointer-events: all;
      opacity: 1;
    }

    &-inner {
      width: 100%;
      max-width: 1216px;
      max-height: 100%;
      background: brand.$brand-white;
      box-shadow: rgba(brand.$brand-black, 0.16) 0px 3px 6px;
      transform: scale(0.8);
      opacity: 0;
      transform-origin: center;
      transition: opacity 500ms global.$easing 150ms, transform 500ms global.$easing 150ms;

      .red-promo__modal--open & {
        transform: scale(1);
        transform-origin: center;
        opacity: 1;
      }
    }

    &-header {
      position: sticky;
      z-index: 10;
      top: 0;
      height: $modal-header-height;
      padding: 58px 24px 20px;
      background: brand.$brand-primary;

      @include mq(tablet) {
        position: relative;
        display: flex;
        align-items: center;
        padding: 26px 38px;
        height: auto;
      }

      h2,
      .ds & h2 {
        color: brand.$brand-white;
        padding-right: 30px;
        margin: 6px 0;
        font-size: 21px;
        text-transform: uppercase;

        @include mq(tablet) {
          font-size: 29px;
        }
      }
    }

    &-close {
      cursor: pointer;
      position: absolute;
      top: 14px;
      right: 38px;
      width: 30px;
      height: 30px;
      background: url('~@img/svg/icon-cross--white.svg') no-repeat center;
      background-size: contain;
      text-indent: -9999px;

      @include mq(tablet) {
        top: 50%;
        margin-top: -15px;
      }
    }

    &-content {
      padding: 40px 24px 80px;
      height: calc(100vh - #{$modal-header-height});
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      @include mixins.hideScrollbar;

      @include mq(mobile) {
        padding: 40px 50px 80px;
      }

      @include mq(tablet) {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: auto;
        overflow-y: auto;
      }
    }

    &-split {
      .red-promo__modal-content & {
        padding-left: 20px;
      }

      @include mq(tablet) {
        flex-basis: 50%;

        .red-promo__modal-content & {
          max-height: 500px;
          overflow-y: auto;
          @include mixins.hideScrollbar;
        }

        &:nth-child(1) {
          .red-promo__modal-header & {
            padding-right: 20px;
          }
        }
      }
    }

    &-image {
      padding-right: 50px;

      img {
        display: block;
        margin: 0 auto;
      }
    }

    &-overflow {
      padding: 50px 0;

      h3 {
        position: relative;
        font-size: 18px;
        line-height: 23px;
        @include fonts.helvetica-93-black-extended;
        margin: 46px 0 10px;
        color: brand.$brand-black;
        text-transform: uppercase;

        &:nth-of-type(1) {
          margin-top: 0;
        }

        &:before {
          content: '';
          position: absolute;
          top: 6px;
          left: -16px;
          display: block;
          width: 8px;
          height: 8px;
          background: brand.$brand-black;
          border-radius: 50%;
        }
      }

      strong {
        display: block;
        font-size: 18px;
        @include fonts.helvetica-63-medium-extended;
        text-transform: uppercase;
        color: brand.$brand-primary;
        margin-bottom: 14px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
        margin: 0 0 18px;
        color: brand.$brand-black;
      }
    }
  }
}
