@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/mixins' as mixins;
@use '~@sass/helpers/animations' as *;
@use '~@sass/helpers/functions' as *;

.laser-dual-image {
  background-color: brand.$brand-primary;
  padding: flow(2) 0;

  &__container {
    display: flex;
    align-items: center;

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &__media-container {
    flex: 0 1 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;

    @include mq($until: tablet) {
      flex: 0 1 100%;
      width: 100%;
      transform: translateX(10%);
    }

    @include mq($until: sm) {
      transform: translateX(0%);
    }
  }

  &__media-1 {
    @include anim-fade-up(0ms, 60px, parent);

    @include mq($until: mobile) {
      max-width: 200px;
    }
  }

  &__media-2 {
    position: relative;
    transform: translate(25%, -25%);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 500ms;

    @include mq($until: mobile) {
      max-width: 270px;
    }

    @include mq($until: xs) {
      max-width: 210px;
    }

    .animated & {
      opacity: 1;
    }
  }

  &__content-container {
    max-width: 420px;
    flex: 1 0 35%;
  }

  &__icon {
    @include anim-fade-up(0ms, 60px, parent);
    img {
      max-width: 50px;
    }
  }

  &__title {
    @include anim-fade-up(0ms, 60px, parent);
    @include fonts.helvetica-93-black-extended;
    color: brand.$brand-white;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: flow(1);

    @include mq($until: tablet) {
      font-size: 21px;
      line-height: 22px;
    }
  }

  &__content {
    @include anim-fade-up(300ms, 60px, parent);
    @include fonts.helvetica-53-extended;
    color: brand.$brand-white;
    font-size: 16px;
    line-height: 24px;
  }
}
