@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use './helpers/mixins' as mixins;

.product-pin-banner {
  position: relative;

  &--overlay:after {
    opacity: 0.7;
    content: '';
    background-color: #000;
    z-index: 2;
    position: ABSOLUTE;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--half-overlay:after {
    opacity: 0.3;
    content: '';
    background-color: #000;
    z-index: 2;
    position: ABSOLUTE;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__media {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  &__app {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__pins {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__pin {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    will-change: opacity, transform;
    width: 46px;
    height: 46px;
    border: 2px solid brand.$brand-white;
    border-radius: 50%;
    z-index: 3;
    backdrop-filter: brightness(4);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.6);
    animation: pin-animation 2.2s infinite cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 275ms ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.4);
    }

    @keyframes pin-animation {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
      }
      70% {
        box-shadow: 0 0 0 14px rgba(255, 255, 255, 0);
      }
      100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    }

    @include mq($from: desktop) {
      width: 56px;
      height: 56px;
    }
  }

  &__modal-track {
    height: 100%;
  }

  &__modal {
    width: 100%;
    padding: 15px;
    height: calc(var(--vh) * 100);
    z-index: 1010;
    top: 0px;
    opacity: 0;
    pointer-events: none;
    position: fixed;

    @include mq($from: tablet) {
      height: calc(80vh - #{global.$navheight});
      min-height: 450px;
      top: 100px;
      padding: 35px;

      @supports (position: sticky) {
        position: sticky;
      }
    }

    @include mq($from: desktop-lg) {
      height: calc(100vh - 200px);
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    &-inner {
      height: 100%;
      display: flex;
      position: relative;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: center;

      @include mq($from: tablet) {
        flex-direction: row;
        justify-content: flex-start;
      }
    }

    &-media {
      background-color: brand.$brand-white;
      width: 100%;
      height: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 2;
      border-radius: 4px 4px 0px 0px;

      // Flip styles
      opacity: 0;

      .active & {
        opacity: 1;
      }

      @include mq($from: tablet) {
        padding: 30px;
        border-radius: 4px;
        margin-right: -2px;
        height: 100%;
        width: 50%;
      }

      @supports (position: sticky) {
        width: 300px;
        height: 300px;
        position: absolute;

        .active & {
          position: relative;
          // need to be important to override inline styles
          left: auto !important;
          top: auto !important;
          width: 100%;
          height: 25%;

          @include mq($from: tablet) {
            height: 100%;
            width: 50%;
          }
        }
      }

      picture {
        display: block;
        max-width: 100%;
        height: 100%;
        text-align: center;
        overflow: hidden;
      }

      img {
        max-height: 100%;
      }
    }

    &-copy {
      background-color: brand.$brand-primary;
      width: 100%;
      max-height: 75%;
      align-self: center;
      padding: 15px;
      position: relative;
      z-index: 1;
      border-radius: 0px 0px 4px 4px;
      opacity: 0;
      transition: opacity 375ms 200ms ease;
      overflow-y: auto;

      @include mq($from: mobile) {
        padding: 25px;
      }

      @include mq($from: tablet) {
        width: 50%;
        height: calc(100% - 80px);
        max-height: calc(100% - 80px);
        border-radius: 4px;
        margin-left: -2px;
      }

      @include mq($from: desktop) {
        padding: 50px;
      }

      @include mq($from: desktop-lg) {
        padding: 50px 100px 150px 100px;
      }

      .active & {
        opacity: 1;
      }

      &-holder {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mq($from: tablet) {
          height: 100%;
        }
      }

      &-inner {
        margin-top: 30px;
        @include mixins.custom-scrollbar(brand.$brand-primary, brand.$brand-primary, brand.$brand-white);
      }
    }

    &-close {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 20px;
      right: 20px;

      border: none;
      outline: none;
      background-color: transparent;
      background-image: url('~@img/svg/icon-cross--white.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      @include mq($from: mobile) {
        width: 25px;
        height: 25px;
      }
    }

    &-logo {
      margin-bottom: 20px;
      max-width: 100px;

      @include mq($from: desktop) {
        max-width: 250px;
      }
    }

    & &-code {
      @include fonts.helvetica-73-bold-extended;
      color: brand.$brand-white;
      text-transform: uppercase;
      font-size: 14px;
      margin-top: 0px;
      margin-bottom: 5px;
      line-height: 1;

      @include mq(desktop) {
        font-size: 18px;
      }
    }

    & &-title {
      @include fonts.helvetica-93-black-extended;
      color: brand.$brand-white;
      text-transform: uppercase;
      font-size: 20px;
      margin-top: 0px;
      margin-bottom: 20px;
      line-height: 1;

      @include mq(desktop) {
        font-size: 24px;
      }
    }

    ul {
      color: brand.$brand-white;
      list-style: square;
      padding-left: 15px;

      li {
        @include fonts.helvetica-53-extended;
        font-size: 12px;
        color: brand.$brand-white;
        margin-bottom: 10px;

        @include mq(desktop) {
          font-size: 14px;
        }
      }
    }

    &-footer {
      margin-top: 30px;
    }
  }
}
