// Project specific mixins can be added into here.
@mixin fade-in-up($duration, $delay, $amount, $timingFunction) {
  will-change: transform;
  transform: translate(0%, $amount);
  opacity: 0;
  animation: fade-in $duration $delay $timingFunction forwards;
}

@mixin fade-in($duration, $delay, $timingFunction) {
  will-change: transform;
  opacity: 0;
  animation: fade-in $duration $delay $timingFunction forwards;
}

@keyframes fade-in {
  to {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}

@mixin custom-scrollbar($bg, $match-bg, $thumb) {
  scrollbar-width: thin;
  scrollbar-color: $thumb $bg;

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: $bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb;
    border-radius: 6px;
    border: 3px solid $match-bg;
  }
}

@mixin hideScrollbar {
  // Chrome and Safari
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  // IE 10+
  -ms-overflow-style: none;

  // Firefox
  scrollbar-width: none;
}
