@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use '../helpers/functions' as *;
@use '../helpers/mixins' as *;

$bp-500: 500px;

.promotion-card-listing {
  padding: flow(1.5) flow(0.3);

  @include mq(tablet) {
    padding: flow(2) 0;
  }

  @include mq(desktop) {
    padding: flow(3) 0;
  }

  &__split {
    @include mq(tablet) {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &-half {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: flow(1);
      opacity: 0;

      &.animated {
        @include fade-in-up(400ms, 0s, 24px, linear);
      }

      @include mq(tablet) {
        display: inherit;
        flex-basis: 50%;
        padding: 0 flow(0.6);
        margin-bottom: flow(1.25);
      }
    }
  }
}

.promotion-card {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  width: 100%;
  max-width: 704px;
  box-shadow: 0 3px 6px rgba(brand.$brand-black, 0.22);
  transition: box-shadow 300ms ease, transform 300ms ease, opacity 300ms ease;

  /* IE - */
  @media all and (-ms-high-contrast: none) {
    align-self: flex-start;
  }

  @include mq(tablet) {
    &:hover {
      box-shadow: 0 0 20px 8px rgba(brand.$brand-black, 0.25);

      @supports (display: grid) {
        transform: scale(1.01);
      }
    }

    .promotion-card-listing.is-active-container &:not(.is-hovered) {
      opacity: 0.5;
    }
  }

  &__top {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: brand.$brand-primary;
    padding: flow(0.8) flow(3) flow(0.8) flow(0.75);

    @include mq($bp-500) {
      padding: flow(0.8) flow(4.25) flow(0.8) flow(1);
    }
  }

  &__logo {
    margin-bottom: flow(0.5);

    img {
      max-height: 25px;
    }
  }

  &__title {
    font-size: 18px;
    line-height: 19px;
    margin-bottom: flow(0.25);
    color: brand.$brand-white;
    @include fonts.helvetica-93-black-extended();

    @include mq(mobile) {
      font-size: 21px;
      line-height: 22px;
    }
  }

  &__code {
    color: brand.$brand-white;
    font-size: 10px;
    @include fonts.helvetica-63-medium-extended();

    @include mq(mobile) {
      font-size: 12px;
    }
  }

  &__offer {
    overflow: hidden;
    background: brand.$brand-white;
    flex: 1;

    /* IE - */
    @media all and (-ms-high-contrast: none) {
      flex: 0 1 auto !important;
    }

    @include mq($bp-500) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &--vertical {
      display: flex;
      flex-direction: column;
      padding-top: 20px;

      /* IE - */
      @media all and (-ms-high-contrast: none) {
        min-height: 330px;
      }
    }

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 212px;

      @include mq($bp-500) {
        height: 250px;
      }

      .promotion-card__offer--with-free-product & {
        @include mq($bp-500) {
          flex-basis: 40%;
          height: 230px;
        }
      }

      .promotion-card__offer--with-product-carousel & {
        flex-wrap: wrap;
      }

      &--split {
        *:first-child {
          border-right: 1px solid darken(brand.$brand-grey, 12%);
        }
      }

      strong {
        font-size: 12px;
        @include fonts.helvetica-93-black-extended-oblique;

        @include mq($bp-500) {
          font-size: 14px;
        }
      }

      picture {
        padding: 0 flow(1);
        text-align: center;
      }

      img {
        max-width: calc(100% - 30px);
        max-height: 160px;

        @include mq($bp-500) {
          max-height: 216px;
        }

        .promotion-card__offer--with-free-product & {
          @include mq($bp-500) {
            max-height: 200px;
          }
        }
      }

      &--with-white-space {
        picture {
          display: contents;
        }

        img {
          max-height: 212px;

          @include mq($bp-500) {
            max-height: 250px;
          }
        }
      }
    }

    &-details {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      max-width: 262px;
      margin-top: auto;
      margin-left: auto;

      .promotion-card__offer--with-free-product & {
        max-width: none;

        @include mq($bp-500) {
          flex-basis: 60%;
          max-width: 60%;
          height: 100%;
        }
      }

      .promotion-card__offer--vertical & {
        max-width: none;
        align-items: center;
        padding-top: 20px;
      }
    }
  }

  &__carousel {
    margin-top: 20px;

    .promotion-card__offer-img--with-white-space & {
      margin-top: 0;
    }

    &-item {
      text-align: center;

      img {
        .promotion-card__offer-img:not(.promotion-card__offer-img--with-white-space) & {
          max-height: 132px;

          @include mq($bp-500) {
            max-height: 180px;
          }
        }
      }
    }

    &-nav {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;

      button {
        appearance: none;
        border: 0;
        outline: 0;
        padding: 0;
        margin: 0 4px;
        background: brand.$brand-grey-medium;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        text-indent: -9999px;

        &.tns-nav-active {
          background: brand.$brand-primary;
        }
      }
    }
  }

  &__skew-outer {
    display: flex;
    flex-direction: row;
    background-color: brand.$brand-grey-light;
    transform: skewX(-14deg);
    padding-right: 28px;
    margin-right: -28px;

    .promotion-card__offer--with-free-product & {
      @include mq($bp-500) {
        align-items: flex-end;
        width: 100%;
        flex: 1;

        @media all and (-ms-high-contrast: none) {
          flex: 1 0 auto;
        }
      }
    }

    &--narrow {
      display: inline-flex;
      min-width: 160px;
      width: auto;
      margin-left: auto;
      padding-right: 14px;
      margin-right: -14px;
    }
  }

  &__skew-inner {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    transform: skewX(14deg);
    padding: flow(0.9) flow(0.75) flow(0.75);

    @include mq($bp-500) {
      padding: flow(0.9) flow(1) flow(0.75);
    }

    .promotion-card__offer--with-free-product & {
      @include mq($bp-500) {
        padding: flow(0.4) flow(1) flow(0.75);
      }
    }

    .promotion-card__skew-outer--narrow & {
      padding: flow(0.5) flow(1) 0;
    }
  }

  &__free {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;

    &-img {
      width: 100%;
      max-width: 100px;
      padding: flow(0.25) flow(0.4) 0 flow(0.75);
      text-align: right;

      @include mq(tablet) {
        max-width: 74px;
      }

      @include mq(1250px) {
        max-width: 136px;
      }

      @include mq(desktop) {
        max-width: 160px;
        padding-right: flow(01);
      }

      img {
        max-width: 70px;
        max-height: 70px;

        @include mq(tablet) {
          max-width: 50px;
          max-height: 50px;
        }

        @include mq(1250px) {
          max-width: 80px;
          max-height: 60px;
        }

        @include mq(desktop) {
          max-width: 106px;
          max-height: 90px;
        }
      }
    }

    &-details {
      flex: 1;
      text-align: right;

      @include mq(1180px) {
        min-width: 130px;
      }

      strong {
        display: block;
        font-size: 22px;
        line-height: 22px;
        color: brand.$brand-primary;
        margin-bottom: flow(0.25);
        @include fonts.helvetica-93-black-extended-oblique();

        @include mq(desktop) {
          font-size: 35px;
          line-height: 35px;
        }
      }

      p {
        font-size: 12px;
        line-height: 15px;
        color: brand.$brand-black;
        margin-bottom: flow(0.1);
        @include fonts.helvetica-93-black-extended();

        @include mq(tablet) {
          max-width: 150px;
          margin-left: auto;
        }

        @include mq(desktop) {
          font-size: 14px;
          line-height: 17px;
        }
      }

      small {
        font-size: 10px;
        color: brand.$brand-grey-medium;
        margin-bottom: flow(0.2);
        @include fonts.helvetica-63-medium-extended();

        @include mq(desktop) {
          font-size: 12px;
        }
      }
    }
  }

  &__price {
    display: inline-block;

    .promotion-card__offer--with-free-product & {
      text-align: right;

      @include mq($until: tablet) {
        display: block;
      }
    }

    label {
      display: block;
      font-size: 15px;
      line-height: 15px;
      padding-left: flow(0.5);
      margin-bottom: 2px;
      color: brand.$brand-primary;
      @include fonts.helvetica-93-black-extended-oblique();

      .promotion-card__offer--with-free-product & {
        display: inline-block;

        @include mq($bp-500) {
          padding-left: 0;
          font-size: 12px;

          @include mq(desktop) {
            font-size: 14px;
          }
        }
      }

      .promotion-card__skew-outer--narrow & {
        font-size: 14px;
        line-height: 14px;
        padding-left: flow(0.25);
        color: brand.$brand-black;
      }
    }

    strong {
      display: block;
      font-size: 30px;
      line-height: 30px;
      color: brand.$brand-primary;
      @include fonts.helvetica-93-black-extended-oblique();

      @include mq(mobile) {
        font-size: 32px;
        line-height: 32px;
      }

      .promotion-card__offer--with-free-product & {
        display: inline-block;
      }

      .promotion-card__skew-outer--narrow & {
        font-size: 18px;
        line-height: 18px;
        color: brand.$brand-black;

        @include mq(mobile) {
          font-size: 20px;
          line-height: 20px;
        }
      }

      &.xlg-strong {
        font-size: 56px;
        line-height: 56px;
      }
    }
  }

  &__cta {
    background: brand.$brand-black;
    max-width: 212px;
    width: 100%;
    text-align: center;
    padding: flow(0.5) flow(1);
    font-size: 10px;
    margin-top: flow(0.75);
    color: brand.$brand-white;
    @include fonts.helvetica-83-heavy-extended();
    transition: background-color 150ms global.$easing;

    @include mq(mobile) {
      font-size: 12px;
    }

    &:hover {
      color: brand.$brand-white;
      background-color: brand.$brand-grey-dark;
    }

    &--secondary {
      margin-top: flow(0.6);
      background: brand.$brand-primary;

      &:hover {
        background-color: brand.$brand-primary-dark;
      }
    }

    &--with-icon {
      background-image: url('~@img/svg/icon-location.svg');
      background-repeat: no-repeat;
      background-position: left 12px center;
      padding-left: 34px;
      padding-right: 12px;
    }
  }

  & &__summary {
    @include fonts.helvetica-55-roman;
    font-size: 10px;
    text-transform: uppercase;
    text-align: left;
    margin-top: 10px;
    max-width: 202px;
  }

  &__offer--vertical .promotion-card__summary {
    max-width: none;
  }

  &__footer {
    background: brand.$brand-black;
    padding: flow(0.5) flow(1);
    font-size: 9px;
    color: brand.$brand-white;
    @include fonts.helvetica-55-roman();

    /* IE - */
    // @media all and (-ms-high-contrast: none) {
    //   margin-top: 0px;
    //   margin-bottom: auto;
    // }

    @include mq(mobile) {
      font-size: 12px;
    }

    & &-title {
      @include fonts.helvetica-83-heavy-extended;
      margin: 0;
      text-transform: uppercase;
      position: relative;
      padding-right: 25px;
      cursor: pointer;

      &:after {
        content: '';
        width: 12px;
        height: 20px;
        position: absolute;
        right: 0px;
        top: 10px;
        transform: translate(-50%, -50%) rotate(-90deg);
        background-image: url('~@img/svg/icon-chevron-left--white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    ul.promotion-card__footer-content {
      column-count: 2;
      margin: 0px;
      padding: 20px 20px 10px 20px;

      li {
        @include fonts.helvetica-63-medium-extended;
        font-style: italic;
        font-size: 14px;
      }
    }

    p.promotion-card__footer-content {
      padding: 20px 0px 10px 0px;
      @include fonts.helvetica-63-medium-extended;
      font-style: italic;
      margin: 0px;
      font-size: 14px;
    }

    &-content {
      display: none;
    }

    &.active {
      .promotion-card__footer-title:after {
        transform: translate(-50%, -50%) rotate(90deg);
      }

      .promotion-card__footer-content {
        display: block;
      }
    }
  }
}
