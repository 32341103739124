@use '~sass-mq/mq' as *;
@use './settings/settings-brand' as brand;
@use './settings/settings-global' as global;
@use './settings/settings-fonts' as fonts;
@use './helpers/functions' as *;

.ds-service {
  background: brand.$brand-primary;
  color: brand.$brand-white;
  padding: 44px 0 50px;

  @include mq(mobile) {
    padding: 44px 0 80px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: 0 auto;

    img {
      display: inline-flex;
      margin: 0 auto 30px;
    }

    h2 {
      margin: 0 0 40px;
      text-align: center;

      @include mq($until: mobile) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 20px;
      margin: 0 0 36px;
      color: brand.$brand-white;
      @include fonts.helvetica-93-black-extended;
      text-transform: uppercase;
    }

    h4 {
      font-size: 16px;
      line-height: 18px;
      margin: 22px 0 18px;
      @include fonts.helvetica-93-black-extended;
      text-transform: uppercase;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      @include fonts.helvetica-53-extended;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        &:nth-child(even) {
          background: #b80013;
        }

        td,
        th {
          padding: 5px 16px;
          height: auto;
        }

        th {
          @include fonts.helvetica-73-bold-extended;
        }

        td {
          @include fonts.helvetica-53-extended;
          &:first-child {
            width: 60%;
          }
        }
      }
    }
  }
}
