@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/mixins' as mixins;
@use '~@sass/helpers/functions' as *;
@use '~@sass/helpers/animations' as *;

.ds-register-tool {
  background-color: brand.$brand-black;
  padding: flow(2) 0;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq($until: mobile) {
      flex-direction: column;
    }
  }

  &__image {
    max-width: 68px;
    opacity: 0;
    transform: translateY(20px);
    margin-right: 30px;
    flex-shrink: 0;
    transition: transform 500ms $anim-easing-cubic 50ms, opacity 500ms $anim-easing-cubic 50ms;

    .animated & {
      opacity: 1;
      transform: translateY(0px);
    }

    @include mq($until: mobile) {
      margin-right: 0;
      margin-bottom: 30px;
    }

    img {
      display: block;
    }
  }

  &__content {
    @include fonts.helvetica-93-black-extended;
    font-size: 24px;
    line-height: 24px;
    color: brand.$brand-white;
    max-width: 770px;
    margin-right: 30px;
    opacity: 0;
    transform: translateY(20px);
    transition: transform 500ms $anim-easing-cubic 100ms, opacity 500ms $anim-easing-cubic 100ms;

    .animated & {
      opacity: 1;
      transform: translateY(0px);
    }

    @include mq($until: mobile) {
      margin-right: 0;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__button {
    opacity: 0;
    transform: translateY(20px);
    transition: transform 500ms $anim-easing-cubic 150ms, opacity 500ms $anim-easing-cubic 150ms;

    .animated & {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
