/* ==========================================================================
Helper classes
========================================================================== */

/*
* Hide from both screenreaders and browsers: h5bp.com/u
*/

.hidden {
  display: none !important;
  visibility: hidden;
}

/*
* Hide only visually, but have it available for screenreaders: h5bp.com/v
*/

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*
* Extends the .sr-only class to allow the element to be focusable
* when navigated to via the keyboard: h5bp.com/p
*/

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*
* Hide visually and from screenreaders, but maintain layout
*/

.invisible {
  visibility: hidden;
}

/*
* Clearfix: contain floats
*
* For modern browsers
* 1. The space content is one way to avoid an Opera bug when the
* `contenteditable` attribute is included anywhere else in the document.
* Otherwise it causes space to appear at the top and bottom of elements
* that receive the `clearfix` class.
* 2. The use of `table` rather than `block` is only necessary if using
* `:before` to contain the top-margins of child elements.
*/

.clearfix:before,
.clearfix:after {
  content: ' '; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

///
/// Center Block
///

%center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

///
/// Helpers to manage images
///

.img--full {
  width: 100%;
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

///
/// Helpers to manage padding
///

.ds-padding--vert-0-30 {
  padding-bottom: 30px;
}

.ds-padding--vert-60 {
  padding: 60px 0;
}
