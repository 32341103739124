@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-terms {
  &__title {
    background: brand.$brand-grey-dark;

    h3 {
      cursor: pointer;
      position: relative;
      color: brand.$brand-white;
      margin: 0;
      padding: 36px 36px 40px 0;
      font-size: 20px;
      @include fonts.helvetica-93-black-extended;

      @include mq(mobile) {
        font-size: 24px;
      }

      &:after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        content: '';
        display: block;
        width: 20px;
        height: 10px;
        background: url('~@icons/icon-caret--white.svg') no-repeat center;
        transition: transform 500ms global.$easing 100ms;
      }
    }

    &.active {
      h3:after {
        transform: translateY(-50%) rotateZ(-180deg);
      }

      + .ds-terms__drop {
        max-height: 1000px;
      }
    }
  }

  &__drop {
    background: brand.$brand-grey-dark;
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s global.$easing;

    p {
      color: brand.$brand-white;
      font-size: 13px;
      line-height: 23px;
      margin: 0 0 14px;
      @include fonts.helvetica-63-medium-extended;

      &:last-child {
        margin-bottom: 80px;
      }
    }
  }
}
