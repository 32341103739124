@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-feature-product-carousel {
  background-color: brand.$brand-white;
  position: relative;
  padding: 70px 0px;

  @include mq($until: tablet) {
    padding: 50px 20px;
  }

  @include mq($until: mobile) {
    padding: 25px 0px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }

  &__inner {
    position: relative;
    z-index: 2;
    padding: 0px 60px;

    @include mq($until: tablet) {
      padding: 0;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 25px;
    text-transform: uppercase;
    @include fonts.helvetica-93-black-extended-oblique;
    font-size: 30px;
    text-transform: uppercase;
    color: brand.$brand-white;

    @include mq($until: mobile) {
      font-size: 14px;
      padding: 0px 15px;
    }
  }

  &__products {
    max-width: 1400px;
    margin: 0 auto;
    padding: 35px 0px;
    display: none;

    @include mq($until: mobile) {
      padding: 0px;
    }

    &.slick-initialized {
      display: block;
    }
  }

  &__footer {
    margin-top: 40px;
    text-align: center;

    @include mq($until: mobile) {
      margin-top: 25px;
    }
  }

  .product-card {
    &__inner-container {
      padding: 0px 20px 0px 20px;

      @include mq($until: desktop) {
        padding: 0px 1px 0px 1px;
      }
    }

    &__model-number {
      width: 60%;

      @include mq($until: tablet) {
        width: 100%;
      }
    }

    &__new {
      top: 6px;
      right: 6px;

      @include mq($until: tablet) {
        top: auto;
        right: auto;
      }
    }
  }

  .slick-prev,
  .slick-next {
    background-color: brand.$brand-primary;
    width: 59px;
    height: 59px;
    opacity: 0.4;
    transition: opacity 275ms ease;
    z-index: 10;

    @include mq($until: tablet) {
      display: none !important;
    }

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 1;
    }

    &.slick-disabled {
      display: none !important;
    }

    &:before {
      content: '';
      color: #fff;
      opacity: 1;
      width: 14px;
      height: 24px;
      background-image: url('~@img/svg/icon-chevron-left--white.svg');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 1;
      display: block;
      margin: 0 auto;
    }
  }

  .slick-prev {
    transform: translate(-50%, -50%);
    left: -20px;

    @include mq($until: tablet) {
      display: none;
    }
  }

  .slick-next {
    transform: translate(50%, -50%);
    right: -20px;

    @include mq($until: tablet) {
      display: none;
    }

    &:before {
      transform: rotate(180deg);
    }
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .slick-slide {
    display: block;
    float: none;
    height: auto;

    > div,
    .product-card {
      height: 100%;
    }
  }
}
