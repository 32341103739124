@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use './helpers/functions' as *;

.ds-video-library {
  background: brand.$brand-white;

  &__filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: brand.$brand-grey-light;
    padding: 14px 0 20px;

    @include mq(tablet) {
      flex-direction: row;
      height: 70px;
      padding-bottom: 4px;
    }

    strong {
      display: inline-flex;
      padding: 4px 20px 4px 0;
      border-right: none;
      color: rgba(brand.$brand-black, 0.3);
      font-size: 14px;
      @include fonts.helvetica-83-heavy-extended;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 6px;

      @include mq(tablet) {
        margin-bottom: 0px;
        border-right: 1px solid #d6d6d6;
        text-align: left;
      }
    }

    ul {
      display: inline-flex;
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        padding: 4px flow(1);
        font-size: 14px;
        border-right: 1px solid #d6d6d6;
        @include fonts.helvetica-83-heavy-extended;
        text-transform: uppercase;

        @include mq(tablet) {
          padding: 4px 42px;
        }

        &:last-child {
          border-right: none;
        }

        span {
          cursor: pointer;
          user-select: none;
          border-bottom: 2px solid transparent;
          padding-bottom: 2px;
        }

        &.is-active {
          color: brand.$brand-primary;

          span {
            border-color: brand.$brand-primary;
          }
        }
      }
    }
  }

  &__inner {
    padding: flow(2) flow(1.5);
    max-width: 1660px;
    margin: 0 auto;
  }

  &__listing {
    @include mq(mobile) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 28px;

      @include mq(mobile) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mq(desktop) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__card {
    position: relative;
    margin-bottom: flow(1);

    @include mq(mobile) {
      flex-basis: calc(50% - 28px);
    }

    @include mq(desktop) {
      flex-basis: calc(33.333% - 28px);
    }

    // IE only
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      padding: 14px;
    }

    &:after {
      content: '';
      opacity: 0;
      position: absolute;
      z-index: 10;
      top: calc(100% + 36px);
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 17px 16px 17px;
      border-color: transparent transparent brand.$brand-primary transparent;
    }

    &--active:after {
      opacity: 1;
    }

    &-thumbnail {
      cursor: pointer;
      position: relative;
      margin-bottom: flow(0.75);
      overflow: hidden;
      opacity: 0;
      transition: opacity 300ms 100ms global.$easing;

      &.active {
        opacity: 1;
      }

      &:before {
        content: '';
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: 56.25%;
      }

      img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform 300ms global.$easing, filter 300ms global.$easing, opacity 300ms global.$easing;
        filter: brightness(0.75);
      }

      &:hover,
      .ds-video-library__card--active & {
        img {
          filter: blur(1px) brightness(0.75);
          transform: scale(1.05);
        }

        &:before {
          transform: translate(-50%, -50%) scale(1.2);
        }
      }
    }

    &-duration {
      position: absolute;
      bottom: 0;
      left: 0;
      background: brand.$brand-primary;
      color: brand.$brand-white;
      padding: flow(0.25) flow(0.75);
      font-size: 16px;
      @include fonts.helvetica-53-extended;
    }

    &-tag {
      display: block;
      color: brand.$brand-grey-medium;
      font-size: 14px;
      @include fonts.helvetica-53-extended;
      margin-bottom: flow(0.25);
    }

    &-title {
      color: brand.$brand-nearly-black;
      font-size: 18px;
      @include fonts.helvetica-93-black-extended;
      text-transform: uppercase;
    }

    &-play {
      width: 92px;
      height: 92px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      @include mq(tablet) {
        width: 152px;
        height: 152px;
      }

      .play-circle {
        display: block;
        width: 100%;
        height: 100%;

        &-01 {
          transform: rotateZ(0deg);
          transform-origin: center;
          transition: transform 500ms global.$easing;
          animation: spin-01 3s linear infinite 500ms;
          animation-play-state: paused;

          .ds-video-library__card:hover & {
            transform: rotateZ(180deg);
            animation-play-state: running;
          }

          @keyframes spin-01 {
            0% {
              transform: rotateZ(180deg);
            }
            100% {
              transform: rotateZ(-180deg);
            }
          }
        }

        &-02 {
          transform: rotateZ(180deg);
          transform-origin: center;
          transition: transform 500ms global.$easing;
          animation: spin-02 3s linear infinite 500ms;
          animation-play-state: paused;

          .ds-video-library__card:hover & {
            transform: rotateZ(0deg);
            animation-play-state: running;
          }

          @keyframes spin-02 {
            0% {
              transform: rotateZ(0deg);
            }
            100% {
              transform: rotateZ(360deg);
            }
          }
        }
      }

      .play-triangle {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 0 20px 30px;
        border-color: transparent transparent transparent brand.$brand-white;
        transition: transform 500ms global.$easing;
        transform-origin: center;

        .ds-video-library__card:hover & {
          transform: scale(1.3) perspective(2px) rotateY(0deg);
        }
      }
    }
  }

  &__expanded {
    display: flex;
    flex-direction: column;
    background: brand.$brand-primary;
    width: 100%;
    position: relative;

    @supports (display: grid) {
      grid-column: 1/-1;
    }

    @include mq(tablet) {
      flex-direction: row;
    }

    &--vertical {
      flex-direction: column;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 100vw;
      background-color: brand.$brand-primary;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }

    &-split {
      position: relative;
      width: 50%;
      padding-top: flow(3.25);
      padding-bottom: flow(3.25);
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mq($until: tablet) {
        padding-bottom: flow(2);
        width: 100%;
      }

      .ds-video-library__expanded--vertical & {
        display: block;
        width: 100%;
      }

      &:nth-child(2) {
        display: flex;
        align-items: center;

        @include mq($until: tablet) {
          padding-top: 0;
          padding-bottom: 38px;

          .ds-video-library__expanded-text {
            padding: 0;
          }
        }

        .ds-video-library__expanded--vertical & {
          padding-top: 0;
        }
      }

      strong {
        display: block;
        font-size: 21px;
        text-transform: uppercase;
        color: brand.$brand-white;
        @include fonts.helvetica-93-black-extended;
        margin-bottom: 12px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
        color: brand.$brand-white;
        @include fonts.helvetica-53-extended;
      }
    }

    &-text {
      padding: 0;
      width: 100%;

      @include mq(tablet) {
        padding: flow(1.5) flow(2.75);
      }

      @include mq(desktop) {
        padding: flow(4) flow(3);
      }

      .ds-video-library__expanded--vertical & {
        max-width: 1000px;
        padding: flow(1) flow(3) flow(1.5);
      }
    }

    &-video {
      position: relative;
      padding-bottom: 56.6%;

      .ds-video-library__expanded--vertical & {
        max-width: 1296px;
        margin: 0 auto;

        @include mq(tablet) {
          padding-bottom: 43.9%;
        }
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
      }
    }

    &-close {
      cursor: pointer;
      position: absolute;
      top: flow(1.4);
      right: 0;
      font-size: 12px;
      color: brand.$brand-white;
      text-transform: uppercase;
      @include fonts.helvetica-53-extended;

      &:after {
        content: '';
        display: inline-flex;
        width: 10px;
        height: 10px;
        margin-left: 5px;
        background-image: url('~@img/svg/icon-cross--white.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}
