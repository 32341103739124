@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/mixins' as mixins;
@use '~@sass/helpers/functions' as *;

.ds-social-links {
  background-color: brand.$brand-grey-dark;
  padding: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;

    &:last-child {
      margin-bottom: 0px;
    }

    @include mq(mobile) {
      margin-bottom: 0px;
      width: auto;
      margin-left: 50px;

      &:first-child {
        margin-left: 0px;
      }
    }

    &-text {
      margin: 0;
      text-transform: uppercase;
      @include fonts.helvetica-83-heavy-extended;
      font-size: 14px;
      color: brand.$brand-white !important;
      text-align: center;

      @include mq(tablet) {
        font-size: 18px;
      }
    }

    &-icon:after {
      font-family: 'FontAwesome';
      color: brand.$brand-white;
      margin-left: 10px;
      font-size: 28px;
      line-height: 1;

      @include mq(tablet) {
        font-size: 38px;
      }
    }

    &-icon--youtube:after {
      content: '\f16a';
    }

    &-icon--instagram:after {
      content: '\f16d';
    }

    &-icon--linkedin:after {
      content: '\f08c';
    }
  }
}
