@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-read-more {
  &__container {
    max-width: global.$site-wide;
    margin: 0 auto;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding: 3em 0px;
    justify-content: center;

    @supports (display: grid) {
      display: grid;
      grid-gap: 45px;
      justify-content: center;

      &--2 {
        grid-template-columns: 1fr 1fr;
      }

      &--3 {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @include mq($until: mobile) {
        grid-template-columns: 1fr !important;
      }
    }
  }

  &__heading {
    text-transform: uppercase;
    text-align: center;
  }

  &__media-container {
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 75%;
      left: -10%;
      width: 130%;
      height: 110%;
      background: brand.$brand-white;
      transform: rotate(-15deg);
      z-index: 0;
    }
  }

  &__media {
    width: 100%;
  }

  &__content {
    padding: 20px;
  }

  &__title {
    color: brand.$brand-black;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__description {
    color: brand.$brand-black;
    font-size: 14px;
  }

  &__item {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    max-width: 50%;
    flex: 0 1 25%;
    margin: 10px 45px;
    background-color: brand.$brand-white;
    transform: translateY(5%);
    opacity: 0;
    transition: opacity 500ms ease, transform 500ms ease;

    &.animated {
      opacity: 1;
      transform: translateY(0%);
    }

    @include mq($until: tablet) {
      flex: 0 1 45%;
      margin: 10px 25px;
      max-width: 326px;
    }

    @include mq($until: mobile) {
      flex: 0 1 98%;
      margin: 10px;
      max-width: 100%;
    }

    @supports (display: grid) {
      max-width: 100%;
      margin: 0;
    }
  }
}
