@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

.laser-video {
  position: relative;
  background: brand.$brand-black;

  &__thumbnail {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 500ms $anim-easing-cubic;
    overflow: hidden;

    .is-playing & {
      opacity: 0;
      pointer-events: none;
    }

    &-svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      transform-origin: center;
      transition: transform 300ms $anim-easing-cubic;

      .laser-video__thumbnail:hover & {
        transform: translate(-50%, -50%) scale(1.14);
      }

      .is-playing & {
        transform: translate(-50%, -50%) scale(50);
        transition: transform 1s $anim-easing-cubic;
      }
    }

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  video {
    display: block;
    width: 100%;
    height: auto;
  }

  &__youtube {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
