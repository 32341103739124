@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-nav-page {
  position: relative;
  display: block;
  height: 48px;
  margin-bottom: 40px;
  background: brand.$brand-white;
  text-align: center;
  user-select: none;

  @include mq($from: mobile) {
    height: 76px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: #d6d6d6;
  }

  &__caption {
    @include fonts.helvetica-93-black-extended;
    position: absolute;
    left: 40px;
    top: 50%;
    display: none;
    color: #d6d6d6;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 1em;
    text-align: left;
    text-transform: uppercase;
    transform: translateY(-50%);

    @include mq($from: tablet) {
      display: block;
    }
  }

  &__caption-icon {
    display: none;
    width: 6px;
    height: 10px;
    margin-left: 10px;
    fill: #d6d6d6;
    stroke: #d6d6d6;

    @include mq($from: tablet) {
      display: inline-block;
    }
  }

  &__links-wrapper {
    @include mq($from: tablet) {
      margin-left: 230px;
    }
  }

  &__links {
    display: flex;
    width: min-content;
    margin: 0;
    padding: 0 24px;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  &__link {
    @include fonts.helvetica-93-black-extended;
    position: relative;
    display: inline-block;
    margin: 0 24px;
    color: brand.$brand-grey-dark;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 47px;
    text-align: left;
    text-transform: uppercase;
    transition: color 0.3s global.$easing;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @include mq($from: mobile) {
      line-height: 75px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      display: block;
      width: 100%;
      height: 2px;
      background: brand.$brand-primary;
      transform: scaleX(0);
      transform-origin: center center;
      transition: background-color 0.3s global.$easing, transform 0.3s global.$easing;
    }

    &:focus,
    &:hover,
    &--active {
      color: brand.$brand-primary;

      &::after {
        background-color: brand.$brand-primary;
        transform: scaleX(1);
      }
    }
  }
}
