@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-pin-banner {
  position: relative;
  overflow: hidden;

  @mixin break {
    position: relative;
    width: 100%;
    transform: none;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    background-color: brand.$brand-black;
  }

  &--left {
  }

  &--right {
  }

  &__content {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    width: 665px;
    background-color: rgba(brand.$brand-black, 90%);
    padding: 40px;
    color: brand.$brand-white;

    &--left {
      left: 40px;
    }

    &--right {
      right: 40px;
    }

    @include mq($until: tablet) {
      padding: 20px;
      @include break;
    }

    &.break {
      @include break;
    }

    h2 {
      @include fonts.helvetica-93-black-extended;
      text-transform: uppercase;
      color: brand.$brand-white;
      margin-bottom: 50px;

      @include mq($until: tablet) {
        margin-bottom: 30px;
        font-size: 24px;
      }

      small {
        @include fonts.helvetica-53-extended;
        font-size: 12px;
        display: block;
        color: inherit;
      }
    }
  }

  p {
    max-width: 425px;
    @include fonts.helvetica-53-extended;
    font-size: 16px;
    margin: 0px;
  }

  &__numbers {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
      padding-left: 47px;
      position: relative;
      margin-bottom: 23px;
      max-width: 425px;
      @include fonts.helvetica-53-extended;
      font-size: 16px;

      span {
        position: absolute;
        left: 0px;
        top: 0px;

        @include fonts.helvetica-83-heavy-extended-oblique;
        font-size: 18px;
        color: brand.$brand-primary;
      }
    }
  }

  &__image-wrapper {
    position: relative;
  }

  &__image {
    display: block;
    // Just doing this for 4k monitors, all the images should fit regardless if content
    // is entered correctly
    width: 100%;

    @include mq($until: xs) {
      display: none;
    }

    &--mobile {
      display: none;

      @include mq($until: xs) {
        display: block;
      }
    }
  }

  &__pin {
    position: absolute;
    width: 100%;
    max-width: 260px;
    transform: translate(-50%, -50%);
    z-index: 3;

    @include mq($until: mobile) {
      left: 50% !important;
      top: 50% !important;
    }

    &-marker {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      background-color: brand.$brand-white;
      margin: 0 auto;

      @include mq($until: mobile) {
        width: 25px;
        height: 25px;
      }

      @keyframes pin-animation {
        0% {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
        }
        70% {
          box-shadow: 0 0 0 14px rgba(255, 255, 255, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:before {
        width: 10px;
        height: 10px;
        background-image: url('~@img/svg/icon-plus.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }

      &:after {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 40px;

        @include mq($until: mobile) {
          width: 25px;
          height: 25px;
          border-radius: 25px;
        }

        box-shadow: 0 0 0 rgba(255, 255, 255, 0.6);
        animation: pin-animation 2.2s infinite cubic-bezier(0.165, 0.84, 0.44, 1);
      }

      &:hover:after {
        animation: none;
      }
    }

    &-text {
      text-align: center;
      @include fonts.helvetica-93-black-extended;
      font-size: 16px;
      color: brand.$brand-white;
      margin-top: 15px;
      text-transform: uppercase;

      @include mq($until: mobile) {
        font-size: 12px;
      }
    }
  }

  &__icons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include mq($until: mobile) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__icon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 40px;

    @include mq($until: mobile) {
      margin-right: 0px;
      margin-bottom: 20px;
    }

    &:last-child {
      margin-right: 0px;
    }

    &-img {
      margin-right: 8px;
      width: 25px;
    }

    &-text {
      h2 {
        font-size: 11px;
        margin: 0;
      }
    }
  }
}
