@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/mixins' as mixins;
@use '~@sass/helpers/functions' as *;

.laser-intro-text {
  background-color: brand.$brand-primary;
  padding: 0 0 flow(2);

  &--padding {
    padding: flow(2) 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__tag {
    @include fonts.helvetica-93-black-extended;
    color: brand.$brand-white;
    font-size: 57px;
    line-height: 57px;
    background-color: brand.$brand-black;
    padding: flow(0.25) flow(0.4);
    margin-bottom: flow(2);
    opacity: 0;
    text-transform: uppercase;

    .animated & {
      @include mixins.fade-in-up(500ms, 0ms, 20px, global.$easing);
    }

    @include mq($until: tablet) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  &__logo-container {
    padding-bottom: flow(0.5);

    @include mq($until: sm) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    img {
      margin: 0 8px;
    }
  }

  &__logo {
    opacity: 0;

    .animated & {
      @include mixins.fade-in-up(500ms, 300ms, 20px, global.$easing);
    }

    @include mq($until: sm) {
      padding-bottom: flow(0.25);
    }
  }

  &__title {
    width: 100%;
    max-width: 640px;
    padding-bottom: flow(0.5);
    opacity: 0;

    .animated & {
      @include mixins.fade-in-up(500ms, 600ms, 20px, global.$easing);
    }

    h2,
    .ds & h2 {
      color: brand.$brand-white;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      @include fonts.helvetica-45-light;

      @include mq($until: tablet) {
        font-size: 24px;
        line-height: 24px;
      }

      sup {
        top: -12px;
        left: -12px;
        font-size: 62%;
      }

      strong {
        @include fonts.helvetica-93-black-extended;
      }
    }
  }

  &__content p {
    width: 100%;
    max-width: 640px;
    @include fonts.helvetica-53-extended;
    color: brand.$brand-white;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    padding-bottom: flow(1);
    margin: 0;
    opacity: 0;

    .animated & {
      @include mixins.fade-in-up(500ms, 600ms, 20px, global.$easing);
    }
  }
}
