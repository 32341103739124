@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(brand.$brand-black, 50%);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__inner {
    background-color: #fff;
    padding: 25px;
    border-radius: 3px;

    &--small {
      width: 325px;
    }
  }

  &__error {
    width: 21px;
    height: 20px;
    background-image: url('~@img/svg/icon-error.svg');
    background-size: contain;
    margin: 0 auto 1em auto;
  }

  &__title {
    text-transform: uppercase;
    text-align: center;
  }

  &__text {
    text-align: center;
    font-size: 14px;
    margin-bottom: 2em;
  }

  &__link {
    @include fonts.helvetica-63-medium-extended;
    font-size: 12px;
    color: brand.$brand-nearly-black;
    margin-top: 1.5em;
    text-decoration: underline;
    display: block;
    line-height: 1;
    cursor: pointer;
    user-select: none;

    &:hover,
    &:active {
      color: brand.$brand-nearly-black;
    }
  }

  &__footer {
    text-align: center;
    margin-top: 20px;
  }
}
