@use '../settings/settings-brand' as brand;

.fixed-viewport-background {
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;

  &.layout-marking-bg {
    background: brand.$brand-primary;
  }
}

.render-bg-transparent {
  background-color: transparent !important;
}
