@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use './helpers/functions' as *;
@use './helpers/mixins' as mixins;

.ds-wysiwyg {
  @include mixins.fade-in-up(300ms, 0s, 20px, global.$easing);

  .ds-theme--red & {
    color: brand.$brand-white;
  }

  .ds-theme--white & {
  }

  .ds-theme--nearly-black & {
    color: brand.$brand-white;
  }

  small {
    display: inline-block;
    margin-bottom: flow(0.75);
    font-size: 14px;
    @include fonts.helvetica-53-extended();
  }
}
