@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-pr {
  margin: 20px 0;

  &__grid {
    display: flex;
    flex-wrap: wrap;
    @include mq($until: tablet) {
      justify-content: center;
    }
    margin-bottom: 20px;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: repeat(auto-fill, 326px);
      grid-gap: 45px 90px;
      justify-content: center;
    }
  }

  &__footer {
    text-align: center;
  }

  &__results {
    padding-bottom: 30px;
  }

  &__load-more {
    border: 1px solid brand.$brand-black;
    text-transform: uppercase;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
    height: 100%;
    border-radius: 0;

    color: #fff;
    background-color: brand.$brand-black;
    padding: 10px 18px;
    display: inline-block;
    user-select: none;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      color: brand.$brand-black;
      background-color: #fff;
      border: 1px solid brand.$brand-black;
    }
  }

  &__load-more-container {
    display: inline-block;
    text-align: center;
    padding-bottom: 25px;
  }

  .ds-pr-item {
    cursor: pointer;
    max-width: 300px;
    flex: 0 1 25%;
    margin: 10px 45px;

    @include mq($until: tablet) {
      flex: 0 1 45%;
      margin: 10px 25px;
      max-width: 326px;
    }

    @include mq($until: mobile) {
      flex: 0 1 98%;
      margin: 10px;
      max-width: 100%;
    }

    @supports (display: grid) {
      max-width: 100%;
      margin: 0;
    }

    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

      .ds-pr-item__title {
        color: brand.$brand-primary;
        margin-bottom: 50px;
      }

      .ds-pr-item__read-more {
        display: block;
      }

      .ds-pr-item__content {
        background-color: brand.$brand-white;
      }
    }

    &__image-container {
      background-color: brand.$brand-white;
      height: 326px;
      position: relative;
    }

    &__image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__content {
      padding: 20px;
    }

    &__title {
      @include fonts.helvetica-53-extended;
      font-weight: 600;
      margin-bottom: 70px;
      color: brand.$brand-nearly-black;
      min-height: 55px;
    }

    &__summary {
      display: none;
    }

    &__date {
      font-size: 12px;
      color: brand.$brand-primary;
      margin-bottom: 20px;
    }

    &__read-more {
      font-size: 14px;
      color: brand.$brand-primary;
      display: none;
      height: 20px;
    }

    &__read-more-icon {
      display: inline-block;
      width: 16px;
      height: 9px;
      margin-right: 10px;
      transform: rotate(180deg);
      fill: brand.$brand-primary;
      margin-left: 10px;
    }
  }

  &__grid-show-summary .ds-pr-item {
    &__date {
      margin-bottom: 12px;
    }

    &__content {
      min-height: 248px;
      display: flex;
      flex-direction: column;
    }

    &__title {
      margin-bottom: 12px;
      min-height: unset;
    }

    &__summary {
      display: block;
      margin-bottom: 32px;
      color: brand.$brand-nearly-black;
      min-height: 72px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      /*! autoprefixer: off */
      -webkit-box-orient: vertical;
      /*! autoprefixer: on */
    }

    &__read-more {
      margin-top: auto;
    }

    &:hover {
      .ds-pr-item__summary,
      .ds-pr-item__title {
        margin-bottom: 12px;
      }
    }
  }
}

.ds-pr-detail {
  &__summary {
    color: brand.$brand-grey-dark;
    font-size: 18px;
    line-height: 30px;
    margin: 50px 0 100px;
  }

  &__text {
    p {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 30px;
    }

    ul,
    ol {
      margin: 24px 0;
      padding-left: 18px;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    li {
      margin-bottom: 10px;
    }
  }

  &__table,
  table {
    width: 100%;
    font-size: 14px;
    @include fonts.helvetica-73-bold-extended;

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #dfdfdf;
        }
        &:nth-child(even) {
          background-color: brand.$brand-white;
        }

        td {
          padding: 0 20px;
        }
      }
    }
  }
}

.ds-pr-carousel {
  position: relative;
  z-index: 3;
  max-width: 1800px;
  padding: 80px 59px; // 59px being the width of the slick buttons
  margin: 0 auto;

  @include mq($until: desktop) {
    padding: 70px 59px;
  }

  @include mq($until: mobile) {
    padding: 70px 45px; // 45px being the width of the slick buttons
  }

  &__item {
    margin: 0px 30px;
  }

  /* Slick Styles Start */
  .slick-list {
    overflow: visible;
  }

  .slick-slide:focus {
    outline: none;
  }

  .slick-slide {
    &:not(.slick-current) {
      opacity: 0.5;
    }
  }

  .slick-dots {
    left: 50%;
    transform: translateX(-50%);

    button:before {
      color: white;
      opacity: 0.5;
    }

    .slick-active button:before {
      color: white;
      opacity: 1;
    }
  }

  .slick-prev,
  .slick-next {
    background-color: brand.$brand-nearly-black;
    width: 59px;
    height: 59px;
    opacity: 0.5;
    transition: opacity 275ms ease;
    z-index: 10;

    @include mq($until: tablet) {
      display: none !important;
    }

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 1;
    }

    &.slick-disabled {
      display: none !important;
    }

    &:before {
      content: '';
      color: #fff;
      opacity: 1;
      width: 14px;
      height: 24px;
      background-image: url('~@img/svg/icon-chevron-left--white.svg');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 1;
      display: block;
      margin: 0 auto;
    }
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;

    &:before {
      transform: rotate(180deg);
    }
  }

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .slick-slide {
    display: block;
    float: none;
    height: auto;
  }

  /* Slick styles end */
}
