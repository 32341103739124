.pdf-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: none;

  &.active {
    display: block;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(48, 48, 48);
  }

  &__inner {
    height: 0;
    padding-bottom: 56.25%;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 1000000;
    background-image: url('~@img/svg/icon-cross--white.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  &__iframe {
    outline: none;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .frameContainer {
      background-color: transparent !important;
    }
  }
}
