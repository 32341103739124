@use '~sass-mq/mq' as *;
@use './settings/settings-brand' as brand;
@use './settings/settings-global' as global;
@use './settings/settings-fonts' as fonts;
@use './settings/settings-layers' as layers;
@use './helpers/functions' as *;

select.product-select {
  display: none;
  visibility: hidden;
}

.product-select {
  $border-width: 2px;
  border: $border-width solid brand.$brand-primary;
  position: relative;

  &.active {
    z-index: layers.$layer-default;
  }

  > .product-select__value {
    position: relative;
    padding: flow(0.25) flow(3) flow(0.25) flow(0.25);

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: flow(1.5);
      transform: translate(50%, -50%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='6' viewBox='0 0 12 6'%3E%3Cpath id='f35f' d='M0,0H12L6,6Z' fill='%23231f20'/%3E%3C/svg%3E%0A");
      width: 12px;
      height: 6px;
    }
  }

  &__value {
    cursor: pointer;
    padding: flow(0.25) flow(0.25) flow(0.25) flow(0.25);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-image {
      width: 64px;
      height: 64px;
      flex: 0 0 64px;
      line-height: 1;
      user-select: none;
    }

    &-text {
      @include fonts.helvetica-73-bold-extended;
      font-size: 16px;
      margin-left: flow(1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: none;
    }

    &:hover {
      background-color: brand.$brand-grey-light;
    }
  }

  &__values {
    display: none;
    position: absolute;
    background: white;
    width: calc(100% + (#{$border-width} * 2));
    left: -$border-width;
    border-left: $border-width solid brand.$brand-primary;
    border-bottom: $border-width solid brand.$brand-primary;
    border-right: $border-width solid brand.$brand-primary;
    max-height: 60vh;
    overflow-y: auto;

    .active & {
      display: block;
    }
  }
}
