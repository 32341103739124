@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-interactive-360 {
  &__media {
    position: relative;
    cursor: grab;
  }

  &__prompt {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.65);
    transform: translate(-50%, -50%);
    z-index: 3;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    min-width: 230px;
    transition: opacity 500ms ease;
    display: none;

    @include mq($from: mobile) {
      padding: 22px;
    }

    &--noclicks {
      pointer-events: none;
    }

    h2 {
      margin-bottom: 4px;
      @include fonts.helvetica-93-black-extended;
      font-size: 16px;
      color: brand.$brand-black;
    }

    svg {
      margin-bottom: 10px;
    }

    &.faded {
      opacity: 0;
      pointer-events: none;
    }

    &.active {
      display: flex;
    }
  }

  & &__drag {
    margin: 0;
    text-transform: uppercase;
    color: brand.$brand-primary;
    position: relative;
    padding: 0px 30px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 20px;
      height: 100%;
      background-image: url('~@img-old/svg/chevron-left--black.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      transition: transform 375ms ease;
    }

    @keyframes animate-before {
      from {
        transform: translate(0%, -50%);
      }
      to {
        transform: translate(-10%, -50%);
      }
    }

    @keyframes animate-after {
      from {
        transform: translate(0%, -50%) rotateZ(180deg);
      }
      to {
        transform: translate(10%, -50%) rotateZ(180deg);
      }
    }

    &:before {
      left: 0;
      animation: animate-before 900ms ease-out infinite;
    }

    &:after {
      right: 0;
      animation: animate-after 900ms ease-out infinite;
    }
  }

  &__loader {
    height: 30px;
    background-color: brand.$brand-primary;
    width: 300px;
    transform-origin: 0 0;
    transform: scaleX(0);
  }
}
