@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use '../helpers/functions' as *;

.ds-form {
  display: block;
  margin-bottom: 60px;
  position: relative;

  .ds-theme--red & {
    background: brand.$brand-primary-dark;
  }

  .ds-theme--white & {
    background: brand.$brand-white;
  }

  .ds-theme--nearly-black & {
    background: brand.$brand-nearly-black;
  }

  &-split {
    @include mq($from: mobile) {
      display: flex;
      flex-direction: row;

      &__50 {
        flex: 1 1 50%;

        &:nth-child(1) {
          padding-right: 8px;
        }

        &:nth-child(2) {
          padding-left: 8px;
        }
      }
    }
  }

  &--banner-cover {
    &:before {
      content: '';
      width: 100vw;
      height: 110px;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-#{global.$padding});
      background-color: #fff;
      border-bottom: 1px solid #d6d6d6;

      @include mq(mobile) {
        width: 200vw;
        transform: translateX(-50%);
      }
    }
  }

  &__main {
    position: relative;
    padding-bottom: 70px;
  }

  &__progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 10;

    // fix for jittery progress bar on scroll
    @supports (display: grid) {
      position: sticky;
      transform: none !important;
    }

    &-inner {
      display: flex;
      align-items: center;
      background: brand.$brand-primary;
      height: 70px;
      flex-direction: column;
      justify-content: center;
      padding: 8px 16px 16px;
      text-align: center;
      width: 100%;
      transition: background-color 300ms global.$easing 100ms;

      .ds-form__progress--complete & {
        background: brand.$brand-complete;
      }
    }

    &-label {
      @include fonts.helvetica-63-medium-extended();
      content: attr(data-label);
      display: block;
      color: brand.$brand-white;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 24px;
      text-transform: uppercase;
    }

    &-bar {
      position: relative;
      display: block;
      background: brand.$brand-white;
      height: 3px;
      max-width: 340px;
      width: 100%;

      &-icon {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        background: brand.$brand-nearly-black url('~@icons/icon-bolt.svg') no-repeat center center;
        height: 23px;
        transition: transform 0.6s global.$easing;
        width: 22px;
      }
    }
  }

  &__inner {
    display: block;
    background: brand.$brand-white;
    position: relative;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.13);
    padding-bottom: 24px;

    .ds-preferences & {
      margin-bottom: 40px;
    }

    .ds-form__description {
      margin-top: 30px;
    }

    &--fade {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &__submit {
    grid-column: span 2;
  }

  &__action-row {
    margin-top: 40px;

    &--center {
      text-align: center;
    }
  }

  &__title {
    @include fonts.helvetica-93-black-extended;
    display: block;
    margin: 0;
    border: 0;
    padding: 18px 0;
    background: brand.$brand-nearly-black;
    border-radius: 3px 3px 0 0;
    color: brand.$brand-white;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;

    &--white {
      background: brand.$brand-white;
      color: brand.$brand-black;
    }
  }

  &__sub-title {
    @include fonts.helvetica-63-medium-extended;
    color: brand.$brand-nearly-black;
    font-size: 14px;
    text-align: left;
    padding: 24px 42px 0;

    + .ds-form__fields {
      padding-top: 12px;
    }
  }

  &__progress-flag {
    position: relative;
    display: inline-flex;
    align-self: center;
    @include fonts.helvetica-83-heavy-extended;
    background: brand.$brand-primary;
    color: brand.$brand-white;
    text-transform: uppercase;
    font-size: 8px;
    letter-spacing: 0.5px;
    padding: 3px 10px;
    margin-left: 24px;
    border-radius: 4px;

    @include mq(mobile) {
      font-size: 10px;
      padding: 5px 14px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%) translateX(-8px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 10px 7px 0;
      border-color: transparent brand.$brand-primary transparent transparent;
    }

    &--complete {
      background: brand.$brand-complete;

      &:before {
        border-color: transparent brand.$brand-complete transparent transparent;
      }
    }
  }

  &__fields {
    display: block;
    padding: 24px;

    select {
      display: none;
    }

    &--center .ds-form {
      &__sub-title,
      &__checkbox-description {
        padding-right: 20px;
      }

      &__checkbox-inner {
        justify-content: start;
        padding-right: 20px;
      }
    }
  }

  &__field {
    position: relative;
    display: inline-block;
    margin-top: 20px;

    &--disabled {
      opacity: 0.5;
    }

    &--full {
      width: 100%;
    }

    &--half {
      width: 100%;

      @include mq($from: mobile) {
        width: 50%;
      }

      /* IE - */
      @media all and (-ms-high-contrast: none) {
        width: 100% !important;
      }
    }

    &--third {
      width: 100%;

      @include mq($from: mobile) {
        width: 33%;
      }
    }

    &--no-margin {
      margin-top: 0;
    }

    &--margin-break-top {
      margin-top: flow(1.75);
    }

    &--margin-break-bottom {
      margin-bottom: flow(1.75);
    }

    &.radio {
      list-style: none;
      padding-left: 0;
      min-height: 0;
      margin-bottom: 0;
    }

    .ds-form {
      &__sub-title,
      &__checkbox-description {
        padding-left: 20px;
      }
    }

    &--message {
      @include fonts.helvetica-53-extended;
      display: none;
      font-size: 10px;
      letter-spacing: 1px;
      margin-left: 20px;
      margin-top: 5px;

      &--invalid {
        color: brand.$brand-invalid;
      }
    }

    &--error {
      .ds-form__field--message {
        display: block;
      }
    }
  }

  &__intro {
    grid-column: span 2;
    text-align: center;

    p {
      @include fonts.helvetica-63-medium-extended;
      margin: 0;
    }
  }

  &__description {
    @include fonts.helvetica-53-extended;
    font-size: 14px;
    line-height: 22px;
    padding: 0px 24px;
    text-align: center;

    + .ds-form__fields {
      padding-top: 0;
    }
  }

  &__checkbox-description {
    // double specificity to counter base styles
    & {
      @include fonts.helvetica-53-extended;
      font-size: 14px;
      padding-left: 46px;
      margin: 5px 0px 0px 0px;
      user-select: none;
    }
  }

  &__checkbox-inner {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
  }

  &__label-container {
    position: absolute;
    left: 20px;
    top: -20px;
    display: flex;
    align-items: center;
  }

  &__tooltip {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: brand.$brand-white;
    margin-left: 10px;

    &:before {
      content: '?';
      font-size: 18px;
      line-height: 18px;
      @include fonts.helvetica-53-extended;
      color: brand.$brand-primary;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__label {
    @include fonts.helvetica-53-extended;
    position: absolute;
    left: 20px;
    top: 0;
    display: block;
    margin: 0;
    font-size: 11px;
    line-height: 20px;
    opacity: 1;
    transform: translateY(-100%);
    transition: opacity 0.3s global.$easing, transform 0.3s global.$easing;
    z-index: 1;
    user-select: none;

    .ds-form__control:not([type='checkbox']):not([type='radio']):placeholder-shown + & {
      opacity: 0;
      transform: translateY(0);
    }

    .ds-form__control:not([type='checkbox']):not([type='radio']):not(:placeholder-shown) + & {
      opacity: 1;
      transform: translateY(-100%);
    }

    .ds-form__control--stealth + & {
      /* IE - */
      @media all and (-ms-high-contrast: none) {
        display: none;
      }
    }

    &--relative {
      position: relative;
      left: 0;
      transform: translateY(0);
    }

    &--confirmation {
      left: 0;
      width: 100%;
      text-align: center;
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      font-size: 14px;

      @include mq($from: tablet) {
        text-align: left;
        width: auto;
      }
    }

    &--white {
      color: brand.$brand-white;
    }

    &--black-highlight a {
      color: brand.$brand-black;
    }

    &--checkboxlist-label {
      padding-bottom: 15px;

      & + input {
        margin-bottom: 15px;
      }
    }

    &--center {
      width: 100%;
      text-align: center;
    }

    input[type='checkbox'] + &,
    input[type='radio'] + & {
      @include fonts.helvetica-53-extended;
      position: relative;
      left: auto;
      top: auto;
      display: block;
      padding-left: 46px;
      font-size: 14px;
      line-height: 18px;
      opacity: 1;
      transform: none;
      transition: none;

      &.ds-form__label--disabled {
        cursor: default;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        display: block;
        width: 18px;
        height: 18px;
        border: 1px solid brand.$brand-grey-dark;

        .invalid & {
          border-color: brand.$brand-invalid;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 20px;
        display: block;
        width: 18px;
        height: 18px;
        opacity: 0;
        transition: opacity 0.3s global.$easing;
        background-color: brand.$brand-primary;
        background-image: url('~@img/svg/icon-tick--white.svg');
        background-repeat: no-repeat;
        background-size: 14px 10px;
        background-position: center center;

        .invalid & {
          background-color: brand.$brand-invalid;
        }
      }

      &--white::before {
        border: 1px solid brand.$brand-white;
      }

      &--inline {
        padding-left: 26px;

        &:before {
          left: 0px;
        }

        &:after {
          left: 0px;
        }
      }

      &--small {
        font-size: 12px;
      }
    }

    input[type='checkbox'] + & {
      &::before,
      &::after {
        border-radius: 2px;
      }
    }

    input[type='radio'] + & {
      &::before,
      &::after {
        border-radius: 50%;
      }
    }

    input[type='checkbox']:checked + &,
    input[type='radio']:checked + & {
      &::after {
        opacity: 1;
      }
    }

    .invalid & {
      color: brand.$brand-invalid;
    }
  }

  &__disclaimer {
    @include fonts.helvetica-53-extended;
    font-size: 11px;

    a {
      text-decoration: underline;
    }
  }

  &__control {
    @include fonts.helvetica-53-extended;
    position: relative;
    display: block;
    height: 40px;
    padding: 10px 20px 9px;
    border: 0;
    border-bottom: 2px solid #231f20;
    background: brand.$brand-white;
    box-shadow: none;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    outline: 0;
    transition: background 0.3s global.$easing;
    z-index: 2;

    &:focus {
      background: mix(#231f20, brand.$brand-white, 2%);
      box-shadow: none !important;
      outline: 0;
    }

    &--stealth {
      background-color: transparent;
      color: brand.$brand-white;
      border-bottom: 1px solid brand.$brand-white;

      &:focus {
        background: transparent;
      }

      &::placeholder {
        color: brand.$brand-white;
      }

      // Overwrites chromes autofill styles
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: brand.$brand-white;
      }
    }

    .invalid & {
      border-bottom-color: brand.$brand-invalid;

      &::placeholder {
        color: brand.$brand-invalid;
      }
    }

    input[type='checkbox'] &,
    input[type='radio'] & {
      display: none;
    }

    .ds-form__select-wrapper {
      position: relative;

      &:after {
        content: '';
        right: 10px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    select & {
      // reset select appearance to that of a text box (hides the default arrow)
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    textarea & {
      height: auto;
      padding: 10px 19px 9px;
      border: 1px solid fadeout(#323232, 50%);
      border-radius: 5px;
      min-height: 120px;

      .invalid & {
        border-color: brand.$brand-invalid;
      }
    }

    input[type='text']:read-only & {
      cursor: not-allowed;
      user-select: none;
      color: rgba(brand.$brand-nearly-black, 50%);
    }
  }

  &__file-upload {
    .ds-form__control {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;

      &:hover + .ds-form__fake-upload {
        background-color: rgba(brand.$brand-white, 0.1);
      }
    }

    + .ds-form__message {
      margin-top: -18px;
    }
  }

  &__file-inner {
    position: relative;
    margin-bottom: flow(1);
  }

  &__fake-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(brand.$brand-white, 0.2);
    border: 1px solid brand.$brand-white;
    color: brand.$brand-white;
    height: 68px;
    padding: flow(0.2);
    @include fonts.helvetica-53-extended;
    transition: background-color 150ms global.$easing;

    strong {
      @include fonts.helvetica-93-black-extended;
      margin-right: 5px;
    }
  }

  &__file-output {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: flow(0.6);

    &:last-of-type {
      margin-bottom: flow(1.3);
    }
  }

  &__file-name,
  &__file-size {
    @include fonts.helvetica-73-bold-extended;
    font-size: 11px;
    line-height: 20px;
    color: inherit;

    @include mq(tablet) {
      font-size: 14px;
    }
  }

  &__file-remove {
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    height: 0;
    @include fonts.helvetica-53-extended;

    &.is-visible {
      opacity: 1;
      height: auto;
      margin-bottom: flow(1.3);
      pointer-events: all;
    }
  }

  &__dropdown {
    cursor: pointer;
    z-index: 11;
    border: none;
    padding: 10px 0px 9px;

    &-values {
      display: none;
      background-color: mix(#231f20, brand.$brand-white, 2%);
      color: brand.$brand-grey-dark;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 2;
      max-height: 300px;
      overflow-y: auto;
    }

    &-selected {
      position: relative;
      z-index: 1;
      width: 100%;
      border-bottom: solid 1px white;
      left: 0;
      padding-left: 20px;
      padding-bottom: 8px;
      padding-right: 16px;

      &:after {
        content: '';
        z-index: 2;
        top: 50%;
        right: 20px;
        width: 12px;
        height: 6px;
        position: absolute;
        background-image: url('~@img/svg/icon-angle-down.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }

      .invalid & {
        color: brand.$brand-primary;
      }

      .ds-form__control--stealth & {
        &:after {
          background-image: url('~@img/svg/icon-angle-down--white.svg');
        }
      }

      .active.ds-form__control--stealth & {
        color: brand.$brand-grey-dark;

        &:after {
          background-image: url('~@img/svg/icon-angle-down.svg');
        }
      }
    }

    &-value {
      position: relative;
      padding: 10px 20px 9px;
      user-select: none;

      &:hover {
        background-color: mix(#231f20, brand.$brand-white, 5%);

        .ds-form__control--stealth & {
          background-color: transparent;
        }
      }
    }

    &.active {
      background-color: mix(#231f20, brand.$brand-white, 2%);
      z-index: 99;

      .ds-form__dropdown-values {
        display: block;
      }

      .ds-form__dropdown-selected:after {
        transform: rotateZ(180deg);
      }
    }

    &-flag {
      display: block;
      width: 36px;
      height: 25px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      background: url('~@img/country-flags.png') 0 0 no-repeat;
      cursor: pointer;

      &.cs-CZ {
        background-position: 0 -30px;
      }

      &.da-DK {
        background-position: 0 -60px;
      }

      &.de-DE {
        background-position: 0 -90px;
      }

      &.en-GB {
        background-position: 0 -120px;
      }

      &.es-ES {
        background-position: 0 -150px;
      }

      &.fi-FI {
        background-position: 0 -180px;
      }

      &.fr-FR {
        background-position: 0 -210px;
      }

      &.hu-HU {
        background-position: 0 -240px;
      }

      &.it-IT {
        background-position: 0 -270px;
      }

      &.nl-NL {
        background-position: 0 -300px;
      }

      &.nn-NO {
        background-position: 0 -330px;
      }

      &.pl-PL {
        background-position: 0 -360px;
      }

      &.ru-RU {
        background-position: 0 -390px;
      }

      &.sv-SE {
        background-position: 0 -420px;
      }

      &.be-BE,
      &.nl-BE,
      &.fr-BE {
        background-position: 0 -450px;
      }

      &.lt-LT {
        background-position: 0 -480px;
      }

      &.lv-LV {
        background-position: 0 -510px;
      }

      &.et-EE {
        background-position: 0 -540px;
      }

      &.de-CH {
        background-position: 0 -569px;
      }

      &.ar-AE {
        background-position: 0 -599px;
      }

      &.en-ZA {
        background-position: 0 -629px;
      }

      &.pt-PT {
        background-position: 0 -659px;
      }

      &.sk-SK {
        background-position: 0px -688px;
      }

      &.tr-TR {
        background-position: 0 -721px;
      }

      &.ro-RO {
        background-position: 0 -751px;
      }

      &.de-AT {
        background-position: 0 -781px;
      }
    }
  }

  &__message {
    @include fonts.helvetica-53-extended;
    display: block;
    font-size: 10px;
    letter-spacing: 1px;
    margin-left: 20px;
    margin-top: 5px;

    &--button {
      margin-left: 0;
      margin-bottom: 10px;
    }

    &--invalid {
      color: brand.$brand-invalid;
    }
  }

  &__icon {
    position: absolute;
    top: 20px; // half the input height
    right: 6px;
    display: block;
    width: 20px;
    height: 21px;
    fill: brand.$brand-grey-dark;
    transform: translateY(-50%);
    z-index: 3;

    &--invalid {
      fill: brand.$brand-invalid;
    }

    textarea ~ & {
      right: 7px;
    }
  }

  &__content {
    @include fonts.helvetica-53-extended;
    display: block;
    padding: 24px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
  }

  &__terms {
    @include fonts.helvetica-53-extended;
    font-size: 12px;
    color: rgba(brand.$brand-nearly-black, 50%);
    text-decoration: underline;
    text-align: center;
  }

  &__footer {
    padding-bottom: 30px;

    &-submit {
      margin-bottom: 3em;
    }

    &-unsubscribe {
      text-align: center;
      margin-bottom: 20px;

      p {
        @include fonts.helvetica-53-extended;
        color: rgba(brand.$brand-nearly-black, 50%);
        font-size: 12px;
        margin: 1.5em auto;
        max-width: 360px;

        a {
          color: rgba(brand.$brand-nearly-black, 50%);
          text-decoration: underline;

          &:focus,
          &:hover {
            color: brand.$brand-primary;
          }
        }
      }
    }

    &-terms {
      margin: 1.5em auto;
      text-align: center;
    }
  }

  &--basic-signup {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    @include mq($until: mobile) {
      background-image: none !important;
    }

    .po-submission-form__title {
      text-align: center;
    }

    .ds-form__field--full {
      text-align: center;
    }

    .ds-form__label {
      text-align: left;
      font-size: 12px;
      line-height: 18px;
    }

    .ds-form-container.ds-theme--red {
      @include mq(mobile) {
        background-color: rgba(brand.$brand-primary, 0.82);
      }

      .ds-form {
        background-color: transparent;
      }

      .ds-button {
        width: 100%;
        max-width: 418px;
        background-color: brand.$brand-nearly-black;
        color: brand.$brand-white;
        margin-top: 10px;

        &:hover {
          background-color: brand.$brand-black;
        }
      }
    }
  }
}

// For edge, handles "display: grid" but not "grid-column: span n".
@supports (display: grid) {
  .ds-form {
    &__fields {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr;
    }

    &__field {
      display: block;
      width: auto;
    }
  }
}

// For modern browsers, handles 2 column layout via "grid-column: span n".
@supports (grid-column: span 1) {
  .ds-form {
    &__fields {
      grid-template-columns: 1fr;

      &--half {
        grid-template-columns: 1fr 1fr;
      }

      &--third {
        grid-template-columns: 1fr 1fr 1fr;
      }

      &--gap-small {
        grid-gap: 12px;
      }
    }

    &__field {
      &--full {
        width: auto;
        grid-column: span 2;
      }

      &--half {
        width: auto;
        grid-column: span 2;

        @include mq($from: mobile) {
          grid-column: span 1;
        }
      }

      &--third {
        width: auto;
        grid-column: span 3;

        @include mq($from: mobile) {
          grid-column: span 1;
        }
      }
    }
  }

  .form-control__checkbox-text {
    text-align: left;
    padding-left: 34px;
  }

  .ds-form__message {
    text-align: left;
  }
}
