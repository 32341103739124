@use '~sass-mq/mq' as *;

@mixin cultureFallbacks() {
  .RURU &,
  .RORO &,
  .PLPL &,
  .HUHU &,
  .CSCZ &,
  .ETEE &,
  .LVLV &,
  .LTLT &,
  .SKSK &,
  .BGBG &,
  .HRHR & {
    font-weight: bold;
    font-style: normal;
    font-family: 'Arimo', sans-serif !important;

    strong {
      font-weight: bold;
      font-family: 'Arimo', sans-serif !important;
    }
    b {
      font-weight: bold;
      font-family: 'Arimo', sans-serif !important;
    }
  }
}

@mixin helvetica-45-light {
  font-family: 'Helvetica Neue LT W01_45 Light', sans-serif;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;

  @include cultureFallbacks();
}

@mixin helvetica-46-light-italic {
  font-family: 'Helvetica Neue LT W01_46 Lt It', sans-serif;
  font-stretch: normal;
  font-style: italic;
  font-weight: 300;

  @include cultureFallbacks();
}

@mixin helvetica-53-extended {
  font-family: 'Helvetica Neue LT W05_53 Ext', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;

  @include cultureFallbacks();
}

@mixin helvetica-53-extended-oblique {
  font-family: 'Helvetica Neue LT W05_53 Ex Ob', sans-serif;
  font-stretch: semi-expanded;
  font-style: oblique;
  font-weight: 400;

  @include cultureFallbacks();
}

@mixin helvetica-55-roman {
  font-family: 'Helvetica Neue LT W01_55 Roman', sans-serif;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;

  @include cultureFallbacks();
}

@mixin helvetica-63-medium-extended {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', sans-serif;
  font-style: normal;
  font-weight: normal;

  @include cultureFallbacks();
}

@mixin helvetica-73-bold-extended {
  font-family: 'Helvetica Neue LT W01_73 Bd Ex', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 700;

  @include cultureFallbacks();
}

@mixin helvetica-83-heavy-extended {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex';
}

@mixin helvetica-83-heavy-extended-oblique {
  font-family: 'Helvetica Neue LT W01_83HvExObl';
  font-style: oblique;

  @include cultureFallbacks();
}

@mixin helvetica-93-black-extended {
  font-family: 'Helvetica Neue LT W01_93 BlkEx', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 900;

  @include cultureFallbacks();
}

@mixin helvetica-93-black-extended-oblique {
  font-family: 'Helvetica Neue LT W0193BlkExObl', sans-serif;
  font-stretch: semi-expanded;
  font-style: oblique;
  font-weight: 900;

  @include cultureFallbacks();
}
