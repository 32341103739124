@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use '../helpers/functions' as *;

.ds-icon-set {
  padding: flow(2) 0;

  @include mq(desktop) {
    padding: flow(3) 0;
  }

  &--white {
    background: brand.$brand-white;
  }

  &--primary {
    background: brand.$brand-primary;
  }

  &--black {
    background: brand.$brand-nearly-black;
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 flow(1);

    @include mq(sm) {
      padding: 0 flow(2);
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: flow(1);
    opacity: 0;
    transform: translateY(40px);
    transition: opacity 500ms global.$easing, transform 500ms global.$easing;

    @include mq(mobile) {
      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 100ms;
        }
      }
    }

    &.animated {
      opacity: 1;
      transform: translateY(0px);
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include mq(mobile) {
      width: 50%;
      flex-basis: 50%;
      padding: 0 flow(1);
    }

    @include mq(desktop) {
      width: 25%;
      flex-basis: 25%;
      margin-bottom: 0;
    }
  }
}

.ds-icon {
  width: 40%;
  margin-right: 20px;
  max-width: 100px;
  flex-shrink: 0;

  @include mq(mobile) {
    max-width: 132px;
  }

  @include mq(desktop) {
    max-width: 166px;
  }

  &__svg {
    position: relative;
    z-index: 1;
    max-width: 90px;
    width: 100%;
    color: brand.$brand-black;

    @include mq(mobile) {
      max-width: 124px;
    }

    @include mq(desktop) {
      max-width: 154px;
    }
  }

  &-text {
    display: inline-flex;
    align-items: center;
    align-self: center;
    width: 100%;
    min-height: 44px;
    padding: flow(0.2) flow(1);
    @include fonts.helvetica-93-black-extended;
    font-size: 14px;
    line-height: 18px;
    padding-left: flow(1.2);
    margin-left: -44px;
    text-transform: uppercase;
    background: brand.$brand-black;
    color: brand.$brand-white;

    @include mq(sm) {
      font-size: 18px;
      line-height: 22px;
    }

    @include mq(mobile) {
      padding: flow(0.5) flow(1);
      padding-left: flow(1.4);
    }

    @include mq(desktop) {
      font-size: 1vw;
      line-height: 1.1vw;
    }
  }
}
