@use '~sass-mq/mq' as *;

$anim-easing-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

@mixin anim-loop-delay($items, $delay: 100ms) {
  @for $i from 1 to $items + 1 {
    &:nth-child(#{$i}) {
      animation-delay: $i * $delay;
    }
  }
}

@mixin anim-fade-up($delay: 0s, $amount: 20px, $mode: self) {
  opacity: 0;
  transform: translateY($amount);
  animation: anim-fade-up 600ms $anim-easing-cubic forwards $delay;
  animation-delay: 0s;
  animation-play-state: paused;

  @include mq(tablet) {
    animation-delay: $delay;
  }

  @if ($mode == parent) {
    .animated & {
      animation-play-state: running;
    }
  } @else if ($mode == self) {
    &.animated {
      animation-play-state: running;
    }
  }

  @keyframes anim-fade-up {
    0% {
      opacity: 0;
      transform: translateY($amount);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

@mixin anim-fade-down($delay: 0s, $amount: 20px, $mode: self) {
  opacity: 0;
  transform: translateY(-#{$amount});
  animation: anim-fade-down 600ms $anim-easing-cubic forwards $delay;
  animation-delay: 0s;
  animation-play-state: paused;

  @include mq(tablet) {
    animation-delay: $delay;
  }

  @if ($mode == parent) {
    .animated & {
      animation-play-state: running;
    }
  } @else if ($mode == self) {
    &.animated {
      animation-play-state: running;
    }
  }

  @keyframes anim-fade-down {
    0% {
      opacity: 0;
      transform: translateY(-#{$amount});
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

@mixin anim-image-fade-up($amount: 60px, $mode: self) {
  opacity: 0;
  filter: grayscale(1);
  transform: translateY($amount);
  transition: opacity 600ms $anim-easing-cubic, filter 600ms $anim-easing-cubic 300ms, transform 600ms $anim-easing-cubic;
  will-change: filter, opacity, transform;

  @if ($mode == parent) {
    .animated & {
      opacity: 1;
      filter: grayscale(0);
      transform: translateY(0px);
    }
  } @else if ($mode == self) {
    &.animated {
      opacity: 1;
      filter: grayscale(0);
      transform: translateY(0px);
    }
  }
}

@mixin anim-image-fade-left($amount: 60px, $mode: self) {
  opacity: 0;
  filter: grayscale(1);
  transform: translateX($amount);
  transition: opacity 600ms $anim-easing-cubic, filter 600ms $anim-easing-cubic 600ms, transform 600ms $anim-easing-cubic;
  will-change: filter, opacity, transform;

  @if ($mode == parent) {
    .animated & {
      opacity: 1;
      filter: grayscale(0);
      transform: translateX(0px);
    }
  } @else if ($mode == self) {
    &.animated {
      opacity: 1;
      filter: grayscale(0);
      transform: translateX(0px);
    }
  }
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}
