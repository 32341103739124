@use '~@sass/settings/settings-brand' as brand;

@use './component-product-focus';

.layout-marking-bg {
  &--dots {
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: -28.5px;
      left: -28.5px;
      background-size: 58px 58px;
      background-image: radial-gradient(circle, brand.$brand-white 1px, rgba(0, 0, 0, 0) 1px);

      animation: gridDotsAnimation;
      animation-duration: 8s;
      animation-fill-mode: forwards;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    background-size: 58px 58px;
    background-image: linear-gradient(to right, brand.$brand-white 0.5px, transparent 1px);
    opacity: 0.2;
    z-index: 0;

    animation: gridLinesVerticalAnimation;
    animation-duration: 2s;
    animation-delay: 4s;
    animation-fill-mode: forwards;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    background-size: 58px 58px;
    background-image: linear-gradient(to bottom, brand.$brand-white 0.5px, transparent 1px);
    opacity: 0.2;
    z-index: 0;

    animation: gridLinesHorizontalAnimation;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }
}

@keyframes gridLinesHorizontalAnimation {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes gridLinesVerticalAnimation {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@keyframes gridDotsAnimation {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}
