@use '~sass-mq/mq' as *;
@use './settings/settings-brand' as brand;
@use './settings/settings-global' as global;
@use './settings/settings-fonts' as fonts;
@use './helpers/functions' as *;
@use './helpers/mixins' as mixins;

.ds-tabs {
  .ds-theme--red & {
    background: brand.$brand-primary;
  }

  .ds-theme--white & {
    background: brand.$brand-white;
  }

  .ds-theme--nearly-black & {
    background: brand.$brand-nearly-black;
  }

  &__inner {
    position: relative;

    &:before {
      position: absolute;
      bottom: 3px;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: ' ';
      display: block;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid brand.$brand-primary;

      .ds-theme--red &,
      .ds-theme--nearly-black & {
        border-color: brand.$brand-white;
      }
    }
  }

  &__list {
    display: block;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    text-align: center;
    @include mixins.custom-scrollbar(brand.$brand-white, brand.$brand-white, brand.$brand-primary);

    .ds-theme--red & {
      @include mixins.custom-scrollbar(
        brand.$brand-primary,
        brand.$brand-primary,
        brand.$brand-white
      );
    }

    .ds-theme--nearly-black & {
      @include mixins.custom-scrollbar(
        brand.$brand-nearly-black,
        brand.$brand-nearly-black,
        brand.$brand-white
      );
    }

    &::-webkit-scrollbar-thumb {
      border: none;
    }

    &::-webkit-scrollbar {
      height: 6px;
    }
  }

  &__item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    @include fonts.helvetica-93-black-extended();
    color: rgba(brand.$brand-grey-dark, 0.5);
    font-size: 18px;
    text-transform: uppercase;
    padding: 0 flow(2);
    height: 60px;
    cursor: pointer;
    position: relative;
    transition: color 150ms global.$easing;

    .ds-theme--red & {
      color: rgba(brand.$brand-white, 0.6);
    }

    .ds-theme--nearly-black & {
      color: rgba(brand.$brand-white, 0.5);
    }

    @include mq($until: mobile) {
      padding: 0 flow(1) flow(0.3);
      margin-bottom: flow(0.3);
    }

    @for $i from 1 to 10 {
      &:nth-child(#{$i}) {
        @include mixins.fade-in-up(300ms, $i * 150ms, 20px, global.$easing);
      }
    }

    &:hover,
    &:focus {
      color: brand.$brand-grey-dark;
      text-decoration: none;

      .ds-theme--red &,
      .ds-theme--nearly-black & {
        color: brand.$brand-white;
      }
    }

    &:before {
      content: '';
      position: absolute;
      height: 3px;
      background-color: brand.$brand-primary;
      bottom: 2px;
      right: 0;
      width: 50%;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.3s global.$easing;

      .ds-theme--red &,
      .ds-theme--nearly-black & {
        background-color: brand.$brand-white;
      }
    }

    &::after {
      content: '';
      position: absolute;
      height: 3px;
      background-color: brand.$brand-primary;
      bottom: 2px;
      left: 0;
      width: 50%;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.3s global.$easing;

      .ds-theme--red &,
      .ds-theme--nearly-black & {
        background-color: brand.$brand-white;
      }
    }

    &.active {
      color: brand.$brand-grey-dark;

      .ds-theme--red &,
      .ds-theme--nearly-black & {
        color: brand.$brand-white;
      }

      @include mq(tablet) {
        &:before,
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }
}
