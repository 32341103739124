@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-address {
  display: block;
  max-width: 240px;
  margin-bottom: 60px;

  &__title {
    @include fonts.helvetica-93-black-extended;
    display: block;
    margin-bottom: 24px;
    color: brand.$brand-black;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
  }

  &__content {
    @include fonts.helvetica-53-extended;
    display: block;
    margin: 0;
    color: brand.$brand-grey-dark;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 22px;

    span {
      display: block;
    }

    a {
      display: inline-block;
      color: brand.$brand-primary;
    }
  }
}
