@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

$laser-colour: #169a3b;

.laser-banner {
  cursor: auto;
  position: relative;
  overflow: hidden;
  min-height: 550px;
  background: brand.$brand-black;

  @include mq(tablet) {
    cursor: none;
  }

  &.laser-banner--watch-triggered .ds-button {
    transform: scale(0.5);
    opacity: 0;
    transition: transform 500ms $anim-easing-cubic, opacity 500ms $anim-easing-cubic;
  }

  a {
    cursor: pointer;

    @include mq(tablet) {
      cursor: none;
    }
  }

  &__crosshair {
    display: none;
    pointer-events: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    margin-top: -1.5px;
    margin-left: -1.5px;
    border-top: 3px solid $laser-colour;
    border-left: 3px solid $laser-colour;
    box-shadow: $laser-colour 0 0 10px;
    opacity: 0;
    transform: scale(0);
    will-change: opacity transform;
    transition: opacity 150ms ease-out, transform 10ms ease-out;
    animation: border-intensity 2s forwards infinite;

    @include mq(tablet) {
      display: block;
    }

    @keyframes border-intensity {
      0% {
        box-shadow: $laser-colour 0 0 10px;
        border-color: $laser-colour;
      }
      50% {
        box-shadow: lighten($laser-colour, 10%) 0 0 14px;
        border-color: lighten($laser-colour, 10%);
      }
      100% {
        box-shadow: $laser-colour 0 0 10px;
        border-color: $laser-colour;
      }
    }

    .laser-banner--inbounds & {
      opacity: 1;
      transition: opacity 300ms $anim-easing-cubic;
    }

    .laser-banner--watch-triggered & {
      opacity: 0;
    }

    &--horizontal {
      width: 100%;
    }

    &--vertical {
      height: 100%;
    }
  }

  &__cursor {
    display: none;
    pointer-events: none;
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    margin-top: -18px;
    margin-left: -18px;
    border-radius: 50%;
    border: 2px solid lighten($laser-colour, 10%);
    opacity: 0;
    transition: 200ms opacity ease;

    @include mq(tablet) {
      display: block;
    }

    .laser-banner--inbounds & {
      &--pointer {
        opacity: 1;
      }
    }

    .laser-banner--watch-triggered.laser-banner--inbounds & {
      opacity: 1;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 54px;
      height: 54px;
      transform: translate(-50%, -50%);
      background: url('~@img/svg/lasers/icon-crosshair.svg') no-repeat center;
    }
  }

  &__image {
    position: relative;
    z-index: 1;
    filter: grayscale(1);
    transition: filter 500ms $anim-easing-cubic;

    .animated & {
      filter: grayscale(0);
    }

    .laser-banner--watch-triggered & {
      opacity: 0;
      transition: opacity 500ms $anim-easing-cubic 500ms;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(brand.$brand-black, 0.35);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 140px;
      background: linear-gradient(rgba(brand.$brand-black, 0), brand.$brand-black);
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__video {
    pointer-events: none;
    display: block;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: none;

    .animated & {
      transition: opacity 500ms $anim-easing-cubic;
      opacity: 0;
    }

    .laser-banner--watch-triggered & {
      pointer-events: all;
      opacity: 1;
      transition: opacity 500ms $anim-easing-cubic 700ms;
    }

    &-close {
      pointer-events: all;
      content: '';
      display: block;
      position: absolute;
      z-index: 12;
      top: 0;
      right: 0;
      width: 54px;
      height: 54px;
      background: brand.$brand-black url('~@img/svg/icon-cross--white.svg') no-repeat center;
      text-indent: -9999px;
      transition: background-color 200ms $anim-easing-cubic;

      &:hover {
        background: rgba(brand.$brand-black, 0.8) url('~@img/svg/icon-cross--white.svg') no-repeat center;
      }
    }

    video {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__text-overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px 0 60px;
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
    transition: opacity 500ms $anim-easing-cubic, transform 500ms $anim-easing-cubic;

    .animated & {
      opacity: 1;
      transform: scale(1);
    }

    @include mq(tablet) {
      justify-content: center;
      padding: 0;
    }

    h1,
    .ds & h1 {
      position: relative;
      z-index: 4;
      max-width: 500px;
      color: brand.$brand-white;
      text-align: center;
      margin: 0 auto 30px;
      transition: transform 500ms $anim-easing-cubic, opacity 500ms $anim-easing-cubic;

      .laser-banner--watch-triggered & {
        transform: scale(0.5);
        opacity: 0;
      }

      @include mq(desktop) {
        font-size: 40px;
        line-height: 40px;
      }
    }
  }

  &__logos {
    position: relative;
    z-index: 4;
    transition: transform 500ms $anim-easing-cubic, opacity 500ms $anim-easing-cubic;
    margin-bottom: auto;

    @include mq(tablet) {
      margin-bottom: 20px;
    }

    .laser-banner--watch-triggered & {
      transform: scale(0.5);
      opacity: 0;
    }

    img {
      max-height: 48px;
      margin: 0 8px 8px;
    }
  }

  .ds-button {
    margin-bottom: 24px;
  }

  &__watch {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: brand.$brand-white;
    @include fonts.helvetica-63-medium-extended;
    transform-origin: center;
    transition: transform 300ms $anim-easing-cubic;

    @include mq(tablet) {
      cursor: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2400px;
      height: 2400px;
      border-radius: 50%;
      background: radial-gradient(brand.$brand-white, $laser-colour);
      box-shadow: #59b974 0 0 90px 50px;
      transform: translate(-50%, -50%) scale(0);
      filter: brightness(1);
      opacity: 0;
      will-change: transform opacity;

      .laser-banner--watch-triggered & {
        transform: translate(-50%, -50%) scale(1);
        filter: brightness(1.5);
        opacity: 1;
        transition: transform 1s ease, filter 1s ease, opacity 1s ease;
      }
    }

    &:hover {
      transform: translateZ(0) scale(1.16);
    }

    svg {
      margin-right: 6px;
    }
  }
}
