@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/mixins' as mixins;
@use '~@sass/helpers/functions' as *;

.layout-marking-product {
  position: relative;
  padding: flow(2) 0;
  background-color: brand.$brand-primary;
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq($until: tablet) {
      flex-direction: column-reverse;
    }

    &--reversed {
      flex-direction: row-reverse;

      @include mq($until: tablet) {
        flex-direction: column-reverse;
      }

      .layout-marking-product__media-title {
        right: initial;
        left: -100px;
        transform: translate(0%, -50%) rotate(-90deg);

        @include mq(desktop) {
          transform: translate(-25%, -50%) rotate(-90deg);
        }
      }
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    max-width: 410px;
    width: 100%;

    &.animated {
      .ds-button {
        @include mixins.fade-in-up(700ms, 150ms, 20px, global.$easing);
      }
    }
  }

  &__tag {
    @include fonts.helvetica-73-bold-extended;
    font-size: 18px;
    line-height: 24px;
    background-color: brand.$brand-white;
    padding: 4px 6px;
    color: brand.$brand-primary;
    opacity: 0;

    .animated & {
      @include mixins.fade-in-up(700ms, 150ms, 20px, global.$easing);
    }
  }

  &__category {
    @include fonts.helvetica-93-black-extended;
    font-size: 16px;
    line-height: 32px;
    color: brand.$brand-white;
    margin-bottom: flow(1);
    opacity: 0;

    .animated & {
      @include mixins.fade-in-up(700ms, 150ms, 20px, global.$easing);
    }
  }

  &__title {
    @include fonts.helvetica-93-black-extended;
    font-size: 30px;
    line-height: 32px;
    color: brand.$brand-white;
    margin-bottom: flow(1);
    opacity: 0;

    .animated & {
      @include mixins.fade-in-up(700ms, 150ms, 20px, global.$easing);
    }

    @include mq($until: mobile) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__content {
    @include fonts.helvetica-53-extended;
    font-size: 14px;
    line-height: 22px;
    color: brand.$brand-white;
    margin-bottom: flow(2);
    opacity: 0;

    .animated & {
      @include mixins.fade-in-up(700ms, 150ms, 20px, global.$easing);
    }
  }

  .ds-button {
    opacity: 0;
  }

  &__media-container {
    position: relative;
  }

  &__media {
    img {
      max-height: 500px;
    }
    opacity: 0;

    .animated & {
      @include mixins.fade-in(700ms, 150ms, global.$easing);
    }
  }

  &__media-title {
    position: absolute;
    @include fonts.helvetica-93-black-extended;
    font-size: 59px;
    line-height: 50px;
    right: -100px;
    top: 50%;
    transform: translate(0%, -50%) rotate(90deg);
    color: #ed808d;

    @include mq($until: mobile) {
      transform: translate(-25%, -50%) rotate(90deg);
      font-size: 37px;
      line-height: 31px;
    }
  }
}
