.app-bar {
  display: flex;
  padding: 0 20px;
  justify-content: left;

  &--centre {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }

  &__logo {
    padding: 25px 15px;
  }
}
