/**
 * Use the following css class format: .icon-name
 */

.icon-svg {
  display: inline-block;
  vertical-align: top;
}

.icon-caret {
  width: 12px;
  height: 6px;
}
