@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use './helpers/functions' as *;

.ds-split-5050 {
  padding: flow(1.5) 0;

  .ds-theme--red & {
    background-color: brand.$brand-primary;
    color: brand.$brand-white;
  }

  .ds-theme--white & {
    background-color: brand.$brand-white;
    color: brand.$brand-nearly-black;
  }

  .ds-theme--nearly-black & {
    background-color: brand.$brand-nearly-black;
    color: brand.$brand-white;
  }

  @include mq(mobile) {
    padding: flow(3) 0;
  }

  &--tabbed {
    opacity: 0;
    height: 0;
    padding: 0;
    visibility: hidden;
    transition: opacity 500ms ease-out;

    &.active {
      opacity: 1;
      height: auto;
      padding: inherit;
      visibility: visible;
    }
  }

  &__inner {
    @include mq(mobile) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  &__image {
    position: relative;
    margin-bottom: flow(1.25);
    opacity: 0;
    transform: translateY(40px);
    transition: opacity 500ms ease, transform 500ms ease;
    text-align: center;

    .ds-split-5050:not(.ds-split-5050--tabbed).animated &,
    .ds-split-5050.animated.active & {
      opacity: 1;
      transform: translateY(0);

      &.has-transitioned {
        transform: none;
      }
    }

    @include mq(mobile) {
      flex-basis: 50%;
      width: 50%;
      margin-bottom: 0;

      .ds-split-5050--image-first & {
        order: 1;
        transform: translateX(-40px);

        &.has-transitioned {
          transform: none;
        }
      }

      .ds-split-5050--content-first & {
        order: 2;
        transform: translateX(40px);
        transition-delay: 200ms;
        &.has-transitioned {
          transform: none;
        }
      }

      .ds-split-5050:not(.ds-split-5050--tabbed).animated &,
      .ds-split-5050.animated.active & {
        opacity: 1;
        transform: translateX(0);

        &.has-transitioned {
          transform: none;
        }
      }
    }

    &--video-thumbnail {
      cursor: pointer;

      &:hover {
        &:before {
          opacity: 0.5;
        }

        &:after {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }

      &:before {
        content: ' ';
        pointer-events: none;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: brand.$brand-nearly-black;
        opacity: 0.25;
        transition: opacity 300ms global.$easing;
      }

      &:after {
        content: ' ';
        pointer-events: none;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 40px;
        height: 58px;
        transform: translate(-50%, -50%);
        background: url('~@icons/icon-play.svg') no-repeat center;
        background-size: contain;
        transition: transform 300ms global.$easing;

        @include mq(sm) {
          width: 66px;
          height: 78px;
        }
      }
    }

    &--image-only {
      cursor: default;
      &:after,
      &:before {
        display: none;
      }
    }
  }

  &__wysiwyg {
    opacity: 0;
    transform: translateY(40px);
    transition: opacity 500ms ease 200ms, transform 500ms ease 200ms;

    .form-control__button {
      display: block;
      max-width: 250px;

      .content-block {
        display: flex;
        justify-content: space-between;
      }

      &.has-arrow .content-block:after {
        line-height: 1;
      }
    }

    .ds-split-5050:not(.ds-split-5050--tabbed).animated &,
    .ds-split-5050.animated.active & {
      opacity: 1;
      transform: translateY(0);
    }

    @include mq(mobile) {
      flex-basis: 50%;
      width: 50%;

      .ds-split-5050--image-first & {
        padding-left: flow(2);
        order: 2;
        transform: translateX(40px);
      }

      .ds-split-5050--content-first & {
        padding-right: flow(2);
        order: 1;
        transform: translateX(-40px);
        transition-delay: 0s;
      }

      .ds-split-5050:not(.ds-split-5050--tabbed).animated &,
      .ds-split-5050.animated.active & {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @include mq(tablet) {
      .ds-split-5050--image-first & {
        padding-left: flow(3);
      }

      .ds-split-5050--content-first & {
        padding-right: flow(3);
      }
    }

    h2,
    p {
      width: 100%;
    }

    h2 {
      @include fonts.helvetica-93-black-extended;
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: flow(0.75);

      @include mq($from: mobile) {
        font-size: 28px;
        max-width: 400px;
      }

      @include mq($from: tablet) {
        font-size: 36px;
      }

      @include mq($from: desktop) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    p {
      @include fonts.helvetica-53-extended;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: flow(1);

      &:last-child {
        margin-bottom: 0;
      }

      @include mq($from: tablet) {
        font-size: 16px;
        line-height: 26px;

        .ds-split-5050--image-first & {
          max-width: 470px;
        }
      }
    }
  }

  &__iframe {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }
}
