@use '~sass-mq/mq' as *;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

@mixin h-base() {
  @include fonts.helvetica-93-black-extended;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

@mixin h-base-oblique() {
  @include fonts.helvetica-93-black-extended-oblique;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

@mixin p-base() {
  @include fonts.helvetica-55-roman();
  font-size: 14px;
  line-height: 18px;

  @include mq($from: tablet) {
    font-size: 14px;
    line-height: 18px;
  }

  @include mq($from: xlg) {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin h1--feature() {
  @include h-base();
  @include fonts.helvetica-93-black-extended;

  @include mq($from: tablet) {
    font-size: 56px;
    line-height: 60px;
  }

  @include mq($from: xlg) {
    font-size: 56px;
    line-height: 60px;
  }
}

@mixin h1--feature-oblique() {
  @include h-base();
  @include fonts.helvetica-93-black-extended-oblique;

  @include mq($from: tablet) {
    font-size: 56px;
    line-height: 60px;
  }

  @include mq($from: xlg) {
    font-size: 56px;
    line-height: 60px;
  }
}

@mixin h1() {
  @include h-base();
  font-size: 24px;
  line-height: 26px;

  @include mq($from: xlg) {
    font-size: 24px;
    line-height: 26px;
  }
}

@mixin h1--oblique() {
  @include h-base-oblique();
  font-size: 24px;
  line-height: 26px;

  @include mq($from: xlg) {
    font-size: 24px;
    line-height: 26px;
  }
}

@mixin h2() {
  @include h-base();
  font-size: 20px;
  line-height: 24px;

  @include mq($from: xlg) {
    font-size: 44px;
    line-height: 54px;
  }
}

@mixin h3() {
  @include h-base();
  font-size: 16px;
  line-height: 18px;

  @include mq($from: xlg) {
    font-size: 30px;
    line-height: 28px;
  }
}

@mixin h4() {
  @include h-base();
  font-size: 14px;
  line-height: 16px;

  @include mq($from: xlg) {
    font-size: 24px;
    line-height: 26px;
  }
}

@mixin h5() {
  @include h-base();
  font-size: 12px;
  line-height: 14px;

  @include mq($from: xlg) {
    font-size: 20px;
    line-height: 22px;
  }
}

@mixin h6() {
  @include h-base();
  font-size: 12px;
  line-height: 14px;

  @include mq($from: xlg) {
    font-size: 18px;
    line-height: 20px;
  }
}

@mixin p() {
  @include p-base();
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

// @todo: Find a way to have these styles scoped to all future design-system based templates, without a specific template class.
.ds {
  @include p-base();

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    @include h-base();
  }

  h1.feature-heading {
    @include h1--feature-oblique();
  }

  h1,
  .h1 {
    @include h1();
  }

  h2,
  .h2 {
    @include h2();
  }

  h3,
  .h3 {
    @include h3();
  }

  h4,
  .h4 {
    @include h4();
  }

  h5,
  .h5 {
    @include h5();
  }

  h6,
  .h6 {
    @include h6();
  }

  p {
    @include p();
  }
}

.copy-container {
  max-width: global.$content-wide--small;
  margin-left: auto;
  margin-right: auto;
}
