// Breakpoint settings
@use 'settings/settings-breakpoints';

// libs
@use './node_modules/tiny-slider/src/tiny-slider.scss';

// Vendor

// animations
@use 'helpers/animations';

// functions
@use 'helpers/functions';

// helpers
@use 'helpers/mixins';
@use 'helpers/list-inputs';
@use 'helpers/helpers';

// settings
@use 'settings/settings-brand';
@use 'settings/settings-layers';
@use 'settings/settings-type';
@use 'settings/settings-global';

// tools
@use '~@netc/sass/src/global/_global-mixins-functions';

// base
@use 'base/base-fonts';
@use 'base/base-grid';

// Components
// Import the grid first as it's got layouts that can be overwritten
@use 'components/component-grid';

// alphabetical order please 😀
@use 'components/component-address';
@use 'components/component-app-bar';
@use 'components/component-article-banner';
@use 'components/component-centre-text';
@use 'components/component-content';
@use 'components/component-content-video';
@use 'components/component-download';
@use 'components/component-feature-product-carousel';
@use 'components/component-flags';
@use 'components/component-form-container';
@use 'components/component-form';
@use 'components/component-icon-bar';
@use 'components/component-icon-set';
@use 'components/component-icons';
@use 'components/component-image-carousel';
@use 'components/component-interactive-360';
@use 'components/component-logo-banner';
@use 'components/component-modal';
@use 'components/component-multiple-pin-banner';
@use 'components/component-nav-inner';
@use 'components/component-nav-page';
@use 'components/component-pdf-modal';
@use 'components/component-pin-banner';
@use 'components/component-preferences';
@use 'components/component-press-release';
@use 'components/component-product-carousel';
@use 'components/component-product-pin-banner';
@use 'components/component-product-seo-carousel';
@use 'components/component-promo-card';
@use 'components/component-promotion-bar';
@use 'components/component-promotion-card';
@use 'components/component-read-more';
@use 'components/component-redemption-promo';
@use 'components/component-register-interest';
@use 'components/component-register-tool';
@use 'components/component-reviews';
@use 'components/component-toasts';
@use 'components/component-typography';
@use 'components/component-video-banner';
@use 'components/component-product-select';
@use 'components/component-load-all-images';
@use 'components/component-social-links';
@use 'components/component-service';
@use 'components/component-split-5050';
@use 'components/component-stacked-split-content';
@use 'components/component-tabs';
@use 'components/component-terms';
@use 'components/component-theme';
@use 'components/component-video-layouts';
@use 'components/component-video-library';
@use 'components/component-wysiwyg';

@use 'components/e-service/es-components';

@use 'components/lasers/lasers-components';

@use 'components/drain-cleaners/dc-components';

@use 'components/layout-marking/layout-marking-components';

@use 'components/online-blitz/component-blitz-steps';
@use 'components/online-blitz/component-countdown';
@use 'components/online-blitz/component-img-cta';
@use 'components/online-blitz/component-stockists';

// keep at the bottom
@use 'components/component-fixed-viewport-background';

// templates
@use 'template/template-home';

// overrides
@use 'fixes/overrides-common';

html {
  scroll-behavior: smooth;
  font-size: 100%;
}

body {
  font-family: 'Helvetica Neue LT W05_53 Ext', 'Sans Serif';
}

#js-pdf {
  position: fixed;
  z-index: 100000;
}
