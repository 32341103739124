@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/mixins' as mixins;
@use '~@sass/helpers/functions' as *;

.countdown {
  text-align: center;
  padding: 30px 15px;
  background-color: brand.$brand-primary;
}

.countdown__title {
  color: brand.$brand-white;
  text-transform: uppercase;
  font-style: italic;
  font-size: 30px;

  @include mq($until: tablet) {
    font-size: 24px;
  }

  .ds & {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 10px;

    @include mq($until: tablet) {
      font-size: 24px;
    }
  }
}

.countdown__inner {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.countdown__block {
  @include fonts.helvetica-93-black-extended;
  font-size: 55px;
  margin-right: 20px;
  color: brand.$brand-white;

  @include mq($until: tablet) {
    font-size: 21px;
    margin-right: 10px;
  }

  &:last-child {
    margin-right: 0;

    @include mq($until: tablet) {
      margin-right: 0;
    }
  }

  span {    
    background-color: brand.$brand-primary-dark;    
    border-radius: 8px;
    width: 85px;
    display: inline-block;
    margin-right: 10px;
    padding: 7px;

    @include mq($until: tablet) {
      width: 34px;
      padding: 3px;
      margin-right: 5px;
    }
    
    .ds & {
      line-height: 79px;

      @include mq($until: tablet) {
        line-height: 31px;
      }
    }
  }
}

.countdown__section-title {
  margin-top: 15px;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;

  @include mq($until: tablet) {
    margin-top: 5px;
    font-size: 8px;
    line-height: 14px;
  }
}

.countdown__days,
.countdown__hours,
.countdown__mins {
  position: relative;

  &:after {
    content: ":";
    position: absolute;
    right: -17px;
    top: 2px;
    line-height: 81px;

    @include mq($until: tablet) {
      right: -7px;
      line-height: 33px;
    }
  }
}

.countdown__timers {
  display: none;
}