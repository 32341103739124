@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/mixins' as mixins;

.img-cta {
  width: 100%;
  background: brand.$brand-white;
}

.img-cta__inner {
  position: relative;
  padding: 30px 24px;
  max-width: 1366px;
  margin: 0 auto;
  opacity: 0;

  &.animated {
    @include mixins.fade-in-up(500ms, 0s, 20px, global.$easing);
  }
}

.img-cta__background {
  position: relative;
  text-align: right;
  width: 65%;
  margin-right: 0;
  margin-left: auto;

  @include mq($until: mobile) {
    width: 100%;
    margin: auto;
  }
}

.img-cta__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 410px solid brand.$brand-white;
  border-right: 100px solid transparent;

  @include mq($until: mobile) {
    display: none;
  }
}

.img-cta__media {
  width: 100%;
  height: 410px;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit: cover; object-position: center center;';

  @include mq($until: mobile) {
    height: 220px;
  }
}

.img-cta__content-holder {
  width: calc(100% - 30px);
  margin: 0 auto;
  transform: translateY(-35px);
  background-color: brand.$brand-nearly-black;
  padding: 20px;

  @include mq(mobile) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 45%;
    width: 100%;
    min-width: 530px;
    padding: 40px;
  }

  .ds-button {
    font-size: 12px;
    line-height: 20px;
    border-radius: 3px;
    margin-top: 5px;
  }

  .ds-button--icon-location {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.img-cta__title {
  color: brand.$brand-white;
  text-transform: uppercase;
  margin-top: 0;

  @include mq($until: mobile) {
    font-size: 24px;
  }

  .ds & {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 36px;

    @include mq($until: mobile) {
      font-size: 24px;
      line-height: 30px;
    }
  }
}

.img-cta__content {
  @include fonts.helvetica-53-extended;
  color: brand.$brand-white;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  margin-bottom: 30px;

  .ds & {
    @include fonts.helvetica-53-extended;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 1px;
    margin-bottom: 30px;
  }
}
