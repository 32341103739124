@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

.laser-warranty {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 500px;

  &__image {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(brand.$brand-black, 0.6);
    }

    picture {
      display: contents;
    }

    img {
      display: block;
      flex: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 830px;

    @include mq(tablet) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__logos {
    @include anim-fade-up(0s, 20px, parent);

    display: flex;
    flex-direction: row;
    margin-right: 16px;
    margin-bottom: 30px;

    @include mq(tablet) {
      margin-bottom: 0;
    }

    img {
      display: block;
      margin-right: 8px;
      max-height: 102px;

      @include mq(tablet) {
        margin-right: 18px;
        max-height: 158px;
      }

      &:last-child {
        margin-right: none;
      }
    }
  }

  &__text {
    @include mq(tablet) {
      flex: 1;
    }

    h2,
    .ds & h2 {
      font-size: 24px;
      @include anim-fade-up(100ms, 20px, parent);
      color: brand.$brand-white;
      text-transform: uppercase;
      margin: 0 0 12px;

      @include mq(tablet) {
        font-size: 30px;
      }
    }

    p {
      @include anim-fade-up(200ms, 20px, parent);

      font-size: 16px;
      line-height: 26px;
      color: brand.$brand-white;
      margin: 0 0 34px;
      @include fonts.helvetica-53-extended;

      @include mq(tablet) {
        margin: 0 0 20px;
      }
    }

    .ds-button {
      @include anim-fade-up(300ms, 20px, parent);
    }
  }
}
