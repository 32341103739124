@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use '../helpers/mixins' as mixins;
@use '../helpers/functions' as functions;

.ds-seo-product-list {
  background-color: brand.$brand-white;
  padding: global.$padding 0px;

  @include mq($from: mobile) {
    padding: functions.flow(2) 0px;
  }

  @include mq($from: tablet) {
    padding: functions.flow(4) 0px;
  }

  .ds-seo-card {
    @include mq($from: mobile) {
      margin-top: functions.flow(2);
    }

    @include mq($from: tablet) {
      margin-top: functions.flow(4);
    }

    &:nth-child(1) {
      margin-top: 0px;
    }
  }
}

.ds-seo-carousel {
  background-color: brand.$brand-white;

  &__nav {
    padding: functions.flow(2) global.$padding global.$padding global.$padding;
  }

  &__main {
    position: relative;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);

    @include mq($from: mobile) {
      padding: 0px 80px;
    }
  }

  /* Simplebar override as the autohide setting doesn't work */
  .simplebar-scrollbar:before,
  .simplebar-track .simplebar-scrollbar.visible:before {
    opacity: 0.25;
  }

  &__left,
  &__right {
    position: absolute;
    top: 20%;
    width: 38px;
    height: 38px;
    background-color: #d9d9d9;
    background-image: url('~@img/svg/icon-chevron-left--black.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    cursor: pointer;

    @include mq($from: mobile) {
      width: 60px;
      height: 60px;
      background-size: 14px;
      top: 50%;
    }
  }

  &__left {
    left: 0px;

    @include mq($from: xs) {
      left: 10px;
    }

    @include mq($from: mobile) {
      left: 20px;
    }
  }

  &__right {
    right: 0px;
    transform: rotate(180deg);

    @include mq($from: xs) {
      right: 10px;
    }

    @include mq($from: mobile) {
      right: 20px;
    }
  }

  &__title {
    text-align: center;
    text-transform: uppercase;
    @include fonts.helvetica-93-black-extended-oblique;
    text-transform: uppercase;
    color: brand.$brand-nearly-black;
    font-size: 18px;

    @include mq($from: mobile) {
      font-size: 30px;
      margin-bottom: global.$padding;
    }
  }

  &__track-wrapper {
    overflow-x: auto;
  }

  &__track {
    padding: global.$padding 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include mq($from: mobile) {
      padding: global.$padding;
    }
  }

  &__item {
    padding: functions.flow(0.5);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex: 0 0 145px;
    margin-right: 15px;
    position: relative;

    @include mq($from: mobile) {
      margin-right: 95px;
    }

    &--unpublished:before {
      content: 'UNPUBLISHED';
      position: absolute;
      font-size: 18px;
      font-weight: bold;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      color: red;
      letter-spacing: 3px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 50%;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: brand.$brand-black;
      opacity: 0;
      transition: opacity 375ms ease;
    }

    &.active:after {
      opacity: 1;
    }

    &:last-child {
      margin-right: 0px;
    }

    img {
      margin-bottom: functions.flow(0.5);
      align-self: center;
    }

    h3 {
      @include fonts.helvetica-93-black-extended-oblique;
      font-size: 10px;
      text-transform: uppercase;
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.ds-seo-card {
  display: none;
  flex-direction: column;
  align-items: center;
  padding: global.$padding 0px;
  color: brand.$brand-nearly-black;

  &:hover {
    color: brand.$brand-nearly-black;
  }

  &.active {
    display: flex;
  }

  @include mq($from: mobile) {
    flex-direction: row;

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  &__image {
    max-width: 100%;
    opacity: 0;

    &--unpublished {
      &::after {
        content: 'UNPUBLISHED';
        position: absolute;
        font-size: 28px;
        font-weight: bold;
        top: 0px;
        left: 0;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: red;
        letter-spacing: 3px;
      }
    }

    .active & {
      @include mixins.fade-in(700ms, 0ms, global.$easing);
    }

    @include mq($from: mobile) {
      max-width: 275px;
      margin-right: global.$padding;

      .ds-seo-card--reverse & {
        margin-right: initial;
        margin-left: global.$padding;
      }
    }
  }

  &__content {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include mq($from: mobile) {
      max-width: 700px;
      text-align: left;
    }

    @include mq($from: desktop) {
      margin-right: initial;

      .ds-seo-card--reverse & {
        margin-right: auto;
        margin-left: initial;
      }
    }

    .ds-button {
      .active & {
        @include mixins.fade-in-up(700ms, 150ms, 20px, global.$easing);
      }
    }
  }

  &__title {
    @include fonts.helvetica-93-black-extended-oblique;
    text-transform: uppercase;
    margin-bottom: global.$padding;
    margin-top: functions.flow(0.5);
    font-size: 18px;

    @include mq($from: mobile) {
      font-size: 34px;
    }

    .active & {
      @include mixins.fade-in-up(700ms, 50ms, 20px, global.$easing);
    }

    .ds-seo-card--line & {
      &:before {
        content: '';
        height: 1px;
        width: 75px;
        background-color: brand.$brand-primary;
        position: absolute;
        top: 19px;
        right: #{'calc(100% + 24px)'};
        display: none;

        @include mq($from: desktop) {
          display: block;
        }
      }
    }
  }

  &__body {
    @include fonts.helvetica-53-extended;
    font-size: 16px;
    margin-bottom: global.$padding;

    .active & {
      @include mixins.fade-in-up(700ms, 100ms, 20px, global.$easing);
    }
  }
}
