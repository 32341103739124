@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-mips {
  background-color: brand.$brand-black;

  @include mq($until: mobile) {
    display: none;
  }

  &__mobile {
    display: none;

    @include mq($until: mobile) {
      display: block;
    }
  }

  &__container {
    position: relative;
  }

  &__img {
    transition: opacity 1000ms ease;
    opacity: 1;
    user-select: none;

    @include mq($until: mobile) {
      width: 100%;
    }

    &.in-view {
      opacity: 0.5;
    }

    &.active {
      opacity: 0.4;
    }
  }
}

.mips-marker {
  position: absolute;
  width: 50px;
  height: 50px;

  &__target {
    width: 10.5vw;
    height: 10.5vw;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
  }

  &__pin {
    position: absolute;
    transform: translate3d(-50%, -50%, 0) scale(0);
    z-index: 1;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    backdrop-filter: brightness(2.2);
    transition: transform 375ms cubic-bezier(0.19, 1, 0.22, 1);
    border: 1px solid #fff;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.6);

    &.active {
      transform: translate3d(-50%, -50%, 0) scale(1);
      animation: pin-animation 2.2s infinite cubic-bezier(0.165, 0.84, 0.44, 1);

      @keyframes pin-animation {
        0% {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
        }
        70% {
          box-shadow: 0 0 0 14px rgba(255, 255, 255, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        }
      }
    }

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background-image: url('~@img/svg/icon-cross--white.svg');
      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      left: 52%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      color: #fff;
      opacity: 0;
      transition: opacity 375ms cubic-bezier(0.165, 0.84, 0.44, 1),
        border 275ms cubic-bezier(0.165, 0.84, 0.44, 1);

      // opacity 1 on mobile always
      @include mq($until: tablet) {
        opacity: 1 !important;
      }
    }

    // Unless its a link
    &--link:after {
      opacity: 0;
    }

    &--link:hover:after {
      transition-delay: 350ms;
      opacity: 1;
    }

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.2);
      transform: translate3d(-50%, -50%, 0) scale(2);
      animation: none;
    }
  }

  &__header {
    @include fonts.helvetica-73-bold-extended;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 16px;

    @include mq($until: tablet) {
      font-size: 16px;
    }

    @include mq($until: mobile) {
      font-size: 14px;
    }

    &--link:after {
      content: '\f08e';
      font-family: 'FontAwesome';
      display: inline-block;
      color: #fff;
      margin-left: 6px;
      font-size: 14px;
    }
  }

  &__text {
    @include fonts.helvetica-53-extended;
    opacity: 0;
    position: absolute;
    left: #{'calc(100% + 10px)'};
    width: 300px;
    color: #fff;
    z-index: 2;
    border-radius: 3px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity 375ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
    pointer-events: none;

    a {
      color: #fff;
    }

    p {
      font-size: 12px;
      margin-bottom: 0px;

      @include mq($until: mobile) {
        font-size: 10px;
      }
    }

    img {
      display: block;
      margin-right: 8px;
      margin-bottom: 8px;
      float: left;
      max-width: 40px;
    }

    &.tease {
      opacity: 0.4;
    }

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
