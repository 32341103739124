@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-product-carousel {
  padding: 50px 40px;
  background-color: brand.$brand-listing-bg;

  @include mq($until: tablet) {
    padding: 50px 20px;
  }

  @include mq($until: mobile) {
    padding: 25px 0px;
  }

  &--red {
    background-color: brand.$brand-primary;
  }

  &__title {
    text-align: center;
    margin-bottom: 25px;
    text-transform: uppercase;
    @include fonts.helvetica-93-black-extended-oblique;
    font-size: 30px;
    text-transform: uppercase;
    color: brand.$brand-nearly-black;

    .ds-product-carousel--red & {
      color: brand.$brand-white;
    }

    @include mq($until: mobile) {
      font-size: 14px;
      padding: 0px 15px;
    }
  }

  &__products {
    position: relative;
    z-index: 3;
    padding: 35px 65px;
    margin: 0 auto;
    display: none;

    &.slick-initialized {
      display: block;
    }

    @include mq($until: desktop) {
      padding: 25px 0px;
    }

    @include mq($until: mobile) {
      padding: 0px;
    }

    .product-card {
      opacity: 1;
      animation: none;

      &__inner-container {
        padding: 0px 2px 0px 2px;

        @include mq($until: tablet) {
          padding: 0px 1px 0px 1px;
        }
      }
    }

    &--thin {
      padding: 10px 59px; // 59px being the width of the slick buttons

      @include mq($until: desktop) {
        padding: 10px 59px;
      }

      @include mq($until: tablet) {
        padding: 10px 120px 10px 10px;
      }

      @include mq($until: mobile) {
        padding: 10px 120px 10px 10px;
      }
    }

    /* Slick Styles Start */
    .slick-slide:focus {
      outline: none;
    }

    .slick-prev,
    .slick-next {
      background-color: brand.$brand-nearly-black;
      width: 59px;
      height: 59px;
      opacity: 0.1;
      transition: opacity 275ms ease;
      z-index: 10;

      @include mq($until: tablet) {
        display: none !important;
      }

      &:hover {
        opacity: 0.5;
      }

      &:active {
        opacity: 1;
      }

      &.slick-disabled {
        display: none !important;
      }

      &:before {
        content: '';
        color: #fff;
        opacity: 1;
        width: 14px;
        height: 24px;
        background-image: url('~@img/svg/icon-chevron-left--white.svg');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 1;
        display: block;
        margin: 0 auto;
      }
    }

    .slick-prev {
      transform: translate(50%, -50%);
      left: 7px;

      @include mq($until: desktop) {
        transform: translate(-50%, -50%);
        left: 0px;
      }
    }

    .slick-next {
      transform: translate(-50%, -50%);
      right: 7px;

      @include mq($until: desktop) {
        transform: translate(50%, -50%);
        right: 0px;
      }

      &:before {
        transform: rotate(180deg);
      }
    }

    .slick-track {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .slick-slide {
      display: block;
      float: none;
      height: auto;

      > div {
        height: 100%;
      }
    }

    /* Slick styles end */
  }

  &__footer {
    text-align: center;
    margin-top: 25px;

    @include mq($until: mobile) {
      margin-top: 50px;
    }
  }
}
