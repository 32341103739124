@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use '../base/base-fonts' as typography;
@use '../helpers/mixins' as mixins;
@use '../helpers/functions' as functions;

.ds-logo-banner {
  $animation-duration: 500ms;
  $animation-delay: 100ms;
  $animation-offset: 100ms;

  position: relative;
  background: brand.$brand-white;
  user-select: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: functions.flow(2) global.$padding;
  min-height: 540px;

  /* IE - */
  @media all and (-ms-high-contrast: none) {
    height: 540px;
  }

  picture {
    display: contents;
  }

  &__image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, black 5%, transparent);
  }

  &__media {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    font-family: 'object-fit: cover; object-position: center center;';
  }

  &__back {
    @include fonts.helvetica-53-extended;
    position: absolute;
    top: 20px;
    left: 20px;
    display: block;
    color: brand.$brand-white;
    fill: brand.$brand-white;
    font-size: 12px;
    line-height: 1em;
    text-transform: uppercase;
    transform: translateX(0);
    transition: transform 0.3s global.$easing;
    z-index: 5;

    &:focus,
    &:hover {
      color: brand.$brand-white;
      fill: brand.$brand-white;
      transform: translateX(-10px);
    }

    @include mq($from: mobile) {
      top: 40px;
      left: 40px;
    }

    .animated & {
      @include mixins.fade-in-up($animation-duration, $animation-offset + ($animation-delay * 2), 20px, global.$easing);
    }
  }

  &__back-icon {
    display: inline-block;
    width: 16px;
    height: 9px;
    margin-right: 10px;
  }

  &__title-wrapper {
    position: relative;
    display: block;
    width: 100%;

    @include mq($from: mobile) {
      max-width: 80%;
    }

    @include mq($from: tablet) {
      max-width: 60%;
    }
  }

  &__title {
    // double specificity to counter base styles
    & {
      @include typography.h1;
      display: block;
      color: brand.$brand-white;
      text-align: center;
      text-transform: uppercase;
      opacity: 0;
      font-size: 30px;
      margin-top: 0;
      margin-bottom: 0;
      line-height: normal;

      .animated & {
        @include mixins.fade-in-up($animation-duration, $animation-offset + ($animation-delay * 2), 20px, global.$easing);
      }

      @include mq($from: tablet) {
        font-size: 50px;
        line-height: 60px;
      }

      @include mq($until: xs) {
        font-size: 24px;
      }
    }

    &--with-description {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    @include fonts.helvetica-93-black-extended-oblique;
    text-transform: uppercase;
    color: brand.$brand-white;
    text-align: center;
    opacity: 0;
    font-size: 30px;

    .animated & {
      @include mixins.fade-in-up($animation-duration, $animation-offset + ($animation-delay * 3), 20px, global.$easing);
    }

    @include mq($from: tablet) {
      font-size: 50px;
    }

    @include mq($until: xs) {
      font-size: 24px;
    }
  }

  &__logo-container {
    text-align: center;

    .animated & {
      @include mixins.fade-in-up($animation-duration, $animation-offset + ($animation-delay * 3), 20px, global.$easing);
    }
  }

  &__logo {
    width: 400px;

    @include mq($from: tablet) {
      width: 640px;
    }
  }

  &__footer {
    position: absolute;
    bottom: 30px;

    .animated & {
      @include mixins.fade-in-up($animation-duration, $animation-offset + ($animation-delay * 3), 20px, global.$easing);
    }
  }

  &__footer-text {
    @include fonts.helvetica-93-black-extended-oblique;
    text-transform: uppercase;
    font-size: 20px;
    &--white {
      color: brand.$brand-white;
    }

    &--red {
      color: brand.$brand-primary;
    }
  }
}
