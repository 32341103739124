@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.toast-track {
  position: fixed;
  top: 8px;
  right: 8px;
  width: 360px;
  z-index: 5000;

  .toast {
    position: relative;
    border-radius: 4px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.175);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 8px;
    transform: translateX(200%);
    transition: transform 220ms cubic-bezier(0.2, 0, 0, 1), opacity 220ms;
    // transform: translate3d(0, 0, 0);

    &.animated-enter-done {
      transform: translateX(0%);
    }

    &__icon {
      width: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &__inner {
      padding: 10px;
      font-size: 14px;
    }

    &__dismiss {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }

    &--info {
      background-color: brand.$brand-white;

      .toast__icon {
        color: brand.$brand-white;
        background-color: #2684ff;
      }
    }

    &--success {
      background-color: #e3fcef;
      color: #006644;

      .toast__icon {
        background-color: #006644;
        color: #e3fcef;
      }
    }

    &--warning {
      background-color: #fffae6;
      color: #ff8b00;

      .toast__icon {
        background-color: #ff8b00;
        color: #fffae6;
      }
    }

    &--error {
      background-color: #ffebe6;
      color: #bf2600;

      .toast__icon {
        background-color: #bf2600;
        color: #ffebe6;
      }
    }
  }
}
