@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-fonts' as fonts;
@use '../settings/settings-global' as global;
@use '../helpers/mixins' as mixins;

.ds-centre-text {
  text-align: center;
  padding: 40px 0;

  @include mq(mobile) {
    padding: 60px 0;
  }

  &.ds-theme--red {
    background: brand.$brand-primary;
    color: brand.$brand-white;

    h3,
    .ds & h3 {
      color: brand.$brand-white;
    }
  }

  &.ds-theme--white {
    background: brand.$brand-white;
    color: brand.$brand-black;

    h3,
    .ds & h3 {
      color: brand.$brand-black;
    }
  }

  &.ds-theme--nearly-black {
    background: brand.$brand-nearly-black;
    color: brand.$brand-white;

    h3,
    .ds & h3 {
      color: brand.$brand-white;
    }
  }

  &__inner {
    max-width: 640px;
    margin: 0 auto;
  }

  &__title {
    display: flex;
    flex-direction: column-reverse;

    h2,
    .ds & h2 {
      font-size: 26px;
      line-height: 34px;
      text-transform: uppercase;
      margin: 0 0 30px;
      opacity: 0;

      @include mq(mobile) {
        font-size: 30px;
        line-height: 38px;
      }

      .animated & {
        @include mixins.fade-in-up(500ms, 100ms, 20px, global.$easing);
      }
    }

    h3,
    .ds & h3 {
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      margin: 0 0 10px;
      opacity: 0;

      .animated & {
        @include mixins.fade-in-up(500ms, 0ms, 20px, global.$easing);
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 24px;
    @include fonts.helvetica-53-extended;
    opacity: 0;

    @include mq(mobile) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .ds-button {
    margin: 20px 8px 0;
    opacity: 0;
  }

  &.animated {
    p {
      @include mixins.fade-in-up(500ms, 200ms, 20px, global.$easing);
    }

    .ds-button {
      &:nth-of-type(1) {
        @include mixins.fade-in-up(500ms, 300ms, 20px, global.$easing);
      }

      &:nth-of-type(2) {
        @include mixins.fade-in-up(500ms, 350ms, 20px, global.$easing);
      }
    }
  }
}
