@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

.laser-dual-product {
  background: brand.$brand-primary;
  padding: 60px 0;

  @include mq(tablet) {
    padding: 80px 0;
  }

  &__split {
    @include mq(tablet) {
      display: grid;
      grid-auto-flow: column;
    }

    &-half {
      display: flex;
      justify-content: center;

      &.animated:nth-child(1):before {
        transform: scaleY(1);
      }

      &:nth-child(1) {
        position: relative;
        padding-bottom: 40px;
        margin-bottom: 40px;

        &:before {
          content: '';
          left: 0;
          bottom: 0;
          position: absolute;
          display: block;
          width: 100%;
          height: 1px;
          border-bottom: 1px solid brand.$brand-white;
          transition: transform 600ms $anim-easing-cubic;
        }
      }

      @include mq(tablet) {
        padding: 0 10px;

        &:nth-child(1) {
          &:before {
            top: 0;
            right: 0;
            left: auto;
            bottom: auto;
            width: 1px;
            height: 100%;
            border-right: 1px solid brand.$brand-white;
            border-bottom: none;
            transform: scaleY(0);
            transform-origin: bottom;
          }
        }
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 444px;
  }

  &__icon {
    @include anim-fade-up(650ms, 20px, parent);
    margin-bottom: 12px;

    img {
      max-height: 48px;
    }
  }

  &__image {
    opacity: 0;
    transition: transform 500ms $anim-easing-cubic 400ms, opacity 500ms $anim-easing-cubic 400ms;

    .laser-dual-product__split-half:nth-child(1) & {
      transform: translateX(60px);
    }

    .laser-dual-product__split-half:nth-child(2) & {
      transform: translateX(-60px);
    }

    .laser-dual-product__split-half.animated:nth-child(1) & {
      opacity: 1;
      transform: translateX(0px);
    }

    .laser-dual-product__split-half.animated:nth-child(2) & {
      opacity: 1;
      transform: translateX(0px);
    }

    img {
      display: block;
    }
  }

  &__text {
    @include anim-fade-down(650ms, 20px, parent);

    small {
      display: block;
      font-size: 12px;
      color: brand.$brand-white;
      margin-bottom: 6px;
      @include fonts.helvetica-63-medium-extended;
      text-transform: uppercase;
    }

    strong {
      display: block;
      min-height: 78px;
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 24px;
      color: brand.$brand-white;
      @include fonts.helvetica-93-black-extended;
      text-transform: uppercase;
    }

    .ds-button {
      &.ds-button--black {
        background-color: brand.$brand-black;

        &:hover {
          background-color: brand.$brand-nearly-black;
        }
      }

      &.coming-soon {
        cursor: not-allowed;
        color: brand.$brand-primary;
      }
    }
  }
}
