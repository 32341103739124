@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

.laser-preview {
  border-bottom: 4px solid brand.$brand-black;

  &__text {
    background: brand.$brand-black;
    padding: 34px 24px;
    text-align: center;

    &.animated h2,
    &.animated p {
      animation-play-state: running;
    }

    h2 {
      @include anim-fade-up(0s, 20px, parent);

      color: brand.$brand-white;
      text-transform: uppercase;
      margin: 0;

      + p {
        margin-top: 24px;
      }
    }

    p {
      @include anim-fade-up(200ms, 20px, parent);

      font-size: 16px;
      line-height: 24px;
      @include fonts.helvetica-53-extended;
      color: brand.$brand-white;
      max-width: 850px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
    }
  }

  &__grid {
    display: flex;
    flex-direction: column;
    background: brand.$brand-black;
    overflow: hidden;

    @include mq(tablet) {
      display: grid;
      grid-template-columns: 346px 1fr;
      min-height: 560px;
    }

    @include mq(desktop) {
      grid-template-columns: 446px 1fr;
      min-height: 802px;
    }
  }

  &__aside {
    order: 2;
    background: brand.$brand-primary;
    transform: translateX(-100%);
    transition: transform 1s $anim-easing-cubic;

    @include mq(tablet) {
      order: 1;
    }

    .animated & {
      transform: translateX(0px);
    }

    &-title {
      padding: 24px;
      border-bottom: 1px solid brand.$brand-white;
    }

    h3 {
      font-size: 16px;
      @include fonts.helvetica-93-black-extended;
      color: brand.$brand-white;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
    }
  }

  &__tab {
    cursor: pointer;
    min-height: 120px;
    padding: 14px 24px;
    border-bottom: 1px solid brand.$brand-white;
    opacity: 0;
    transform: translateX(40px);
    transition: background-color 200ms $anim-easing-cubic;
    animation: tab-in 500ms $anim-easing-cubic forwards;
    animation-play-state: paused;

    @for $i from 1 to 6 {
      &:nth-child(#{$i}) {
        animation-delay: calc(600ms + (#{$i} * 100ms));
      }
    }

    @include mq(tablet) {
      min-height: 146px;
      padding: 30px 24px;
    }

    @keyframes tab-in {
      0% {
        opacity: 0;
        transform: translateX(40px);
      }
      100% {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    .animated & {
      animation-play-state: running;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover:not(.laser-preview__tab--active) {
      background-color: brand.$brand-primary-dark;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 0 20px 16px;
      border-color: transparent transparent transparent brand.$brand-primary;
      transform: translateY(-50%) translateX(-16px);
      transition: transform 200ms $anim-easing-cubic;
    }

    &--active {
      position: relative;
      background: brand.$brand-black;

      &:before {
        transform: translateY(-50%) translateX(0px);
      }
    }

    &-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 24px;
      transition: transform 300ms $anim-easing-cubic;

      .laser-preview__tab--active & {
        transform: translateX(26px);
      }
    }

    &-image {
      flex-shrink: 0;
      margin-right: 10px;
    }

    &-text {
      small {
        display: block;
        font-size: 12px;
        color: brand.$brand-white;
        margin-bottom: 6px;
        @include fonts.helvetica-63-medium-extended;
        text-transform: uppercase;
        max-width: 214px;
      }

      strong {
        display: block;
        font-size: 14px;
        line-height: 16px;
        color: brand.$brand-white;
        @include fonts.helvetica-93-black-extended;
        text-transform: uppercase;
        max-width: 214px;
      }
    }
  }

  &__content {
    position: relative;
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(1.75);
    filter: grayscale(50%);
    transition: transform 1s $anim-easing-cubic, filter 1s $anim-easing-cubic;

    @include mq(tablet) {
      order: 2;
    }

    .animated & {
      transform: scale(1);
      filter: grayscale(0%);
    }

    video {
      position: relative;
      z-index: 10;
      display: none;
      width: 100%;
      height: 100%;
      opacity: 0;
      border: 0;
      outline: 0;
      object-fit: cover;
      background: brand.$brand-black;

      &.is-active {
        display: block;
      }

      &.can-play {
        opacity: 1;
        transition: opacity 500ms $anim-easing-cubic;

        + .full-loading-container {
          opacity: 0;
        }
      }
    }

    &-image {
      display: none;

      &.is-active {
        display: block;
      }
    }

    .full-loading-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 250ms $anim-easing-cubic;
      display: none;

      &.is-active {
        display: flex;
      }
    }
  }
}
