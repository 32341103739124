/**
 * Add brand variables in this file.
 */

$brand-black: #000000;
$brand-nearly-black: #231f20;
$brand-white: #ffffff;

$brand-primary: #db011c;
$brand-primary-dark: #ab0116;

$brand-invalid: #db031d;

$brand-complete: #15c452;

$brand-grey: #ebebeb;
$brand-grey-light: #f8f8f8;
$brand-listing-bg: #f2f2f2;
$brand-grey-medium: #86878c;
$brand-grey-dark: #323232;

$brand-text: $brand-nearly-black;
