@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/mixins' as mixins;
@use '~@sass/helpers/functions' as *;

.ds-service-icons {
  background-color: brand.$brand-white;
  padding: flow(3) 0;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @include fonts.helvetica-93-black-extended;
    max-width: 640px;
    font-size: 30px;
    line-height: 30px;
    color: brand.$brand-primary;
    text-align: center;
    padding-bottom: flow(2);

    opacity: 0;

    &.animated {
      @include mixins.fade-in-up(700ms, 150ms, 20px, global.$easing);
    }
  }

  &__icon-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__icon {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px flow(2);
    opacity: 0;

    .animated & {
      @for $i from 1 to 9 {
        &:nth-child(#{$i}) {
          @include mixins.fade-in-up(300ms, $i * 300ms, 20px, global.$easing);
        }
      }
    }

    @include mq($until: mobile) {
      width: 50%;
    }

    @include mq($until: xs) {
      width: 100%;
    }

    &-image {
      height: 100px;
      padding-bottom: flow(1);

      svg {
        max-height: 70px;
      }
    }

    &-title {
      max-width: 214px;
      @include fonts.helvetica-83-heavy-extended-oblique;
      font-size: 16px;
      color: brand.$brand-black;
      text-align: center;
    }
  }
}

@keyframes example {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  25% {
    transform: rotate(-30deg);
  }
  50% {
  }
  100% {
  }
}
