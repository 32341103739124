@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/mixins' as mixins;

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.steps {
  background: brand.$brand-white;

  &--red {
    background: brand.$brand-primary;

    .steps__title,
    .steps__sub-title {
      .ds & {
        color: brand.$brand-white;
      }
    }

    .steps__step {
      &:before {
        background-color: brand.$brand-nearly-black;
      }
    }

    .steps__step-circle {
      background-color: brand.$brand-nearly-black;
    }

    .steps__step-title,
    .steps__step-content {
      color: brand.$brand-white;
    }
  }
}

.steps__inner {
  padding: 30px 24px;
  max-width: 1366px;
  width: 100%;
  opacity: 0;

  &.animated {
    @include mixins.fade-in-up(500ms, 0s, 20px, global.$easing);
  }
}

.steps__top {
  text-align: center;
  margin-bottom: 45px;
}

.steps__title {
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  color: brand.$brand-black;

  @include mq($until: mobile) {
    font-size: 21px;
    line-height: 21px;
  }

  .ds & {
    font-size: 30px;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: brand.$brand-black;

    @include mq($until: mobile) {
      font-size: 21px;
      line-height: 21px;
    }
  }
}

.steps__sub-title {
  @include fonts.helvetica-93-black-extended;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: brand.$brand-grey-medium;

  @include mq($until: mobile) {
    font-size: 16px;
    line-height: 16px;
  }

  .ds & {
    @include fonts.helvetica-93-black-extended;
    font-size: 18px;
    line-height: 18px;
    margin: 0;

    @include mq($until: mobile) {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.steps__holder {
  display: flex;
  justify-content: space-between;

  @include mq($until: tablet) {
    flex-direction: column;
  }
}

.steps__step {
  text-align: center;
  flex: 1 0 20%;
  position: relative;
  opacity: 0;

  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in;

  &:nth-child(2) {
    animation-delay: 0.3s;
    &:before {
      animation-delay: 0.5s;
    }
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
    &:before {
      animation-delay: 0.8s;
    }
  }
  &:nth-child(4) {
    animation-delay: 0.9s;
    &:before {
      animation-delay: 1.1s;
    }
  }
  &:nth-child(5) {
    animation-delay: 1.2s;
    &:before {
      animation-delay: 1.4s;
    }
  }

  @include mq($until: tablet) {
    text-align: left;
    padding-left: 90px;
    min-height: 250px;
  }

  @include mq($until: mobile) {
    padding-left: 50px;
    min-height: 150px;
  }

  &:before {
    content: '';
    height: 6px;
    width: calc(100% - 100px);
    background-color: brand.$brand-primary;
    border-radius: 3px;
    position: absolute;
    top: 40px;
    left: calc(50% + 50px);
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-delay: 0.2s;
    animation-timing-function: ease-in;

    @include mq($until: tablet) {
      width: 6px;
      height: calc(100% - 100px);
      top: 90px;
      left: 40px;
    }

    @include mq($until: mobile) {
      height: calc(100% - 60px);
      top: 50px;
      left: 20px;
    }
  }

  &:last-child {
    &:before {
      content: none;
    }
  }
}

.steps__step-circle {
  @include fonts.helvetica-93-black-extended;
  width: 84px;
  height: 84px;
  background-color: brand.$brand-primary;
  border-radius: 50%;
  color: brand.$brand-white;
  margin: 0 auto 30px;
  font-size: 50px;
  line-height: 79px;
  text-align: center;

  @include mq($until: tablet) {
    position: absolute;
    left: 0;
  }

  @include mq($until: mobile) {
    width: 43px;
    height: 43px;
    font-size: 25px;
    line-height: 42px;
  }
}

.steps__step-title {
  @include fonts.helvetica-93-black-extended-oblique;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  padding: 0 15px;
  letter-spacing: 2px;

  @include mq($until: tablet) {
    flex: 0 0 auto;
    padding: 30px 15px 0;
  }

  @include mq($until: mobile) {
    padding: 10px 15px 0;
    font-size: 20px;
    line-height: 20px;
  }

  .ds & {
    @include fonts.helvetica-93-black-extended-oblique;
    font-size: 22px;
    line-height: 22px;
    padding: 0 15px;
    letter-spacing: 2px;
    margin-top: 0;

    @include mq($until: tablet) {
      padding: 30px 15px 0;
      margin-bottom: 10px;
    }

    @include mq($until: mobile) {
      padding: 10px 15px 0;
      font-size: 20px;
      line-height: 20px;
    }
  }
}

.steps__step-content-holder {
  padding: 0 15px;
}

.steps__step-content {
  font-size: 12px;
  line-height: 18px;
  @include fonts.helvetica-53-extended;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  .ds & {
    font-size: 12px;
    line-height: 18px;
    margin-top: 0;
    @include fonts.helvetica-53-extended;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}
