@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

.laser-comparison {
  background: brand.$brand-black;
  padding: 60px 0;

  @include mq(tablet) {
    padding: 100px 0;
  }

  &__split {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq(tablet) {
      flex-direction: row;
    }

    &-half {
      &:nth-child(1) {
        @include anim-fade-up(0s, 60px, self);

        margin-bottom: 30px;
        max-width: 224px;
        text-align: center;

        @include mq(mobile) {
          max-width: 310px;
        }

        @include mq(tablet) {
          padding-right: 48px;
          margin-bottom: 0;
          max-width: 366px;
          text-align: left;
        }
      }

      &:nth-child(2) {
        @include anim-fade-up(100ms, 60px, self);

        flex: 1;
        width: 100%;
        text-align: center;

        @include mq(tablet) {
          padding-left: 48px;
        }
      }
    }
  }

  &__icon {
    margin-bottom: 20px;
  }

  &__text {
    font-size: 18px;
    line-height: 18px;
    color: brand.$brand-white;
    @include fonts.helvetica-93-black-extended;
    text-transform: uppercase;
    margin-bottom: 6px;

    @include mq(mobile) {
      font-size: 29px;
      line-height: 29px;
    }

    &--green {
      font-size: 102px;
      line-height: 72px;
      color: #12954c;
      margin-bottom: 12px;

      @include mq(mobile) {
        font-size: 159px;
        line-height: 100px;
        margin-bottom: 18px;
      }
    }

    &--red {
      font-size: 18px;
      line-height: 18px;
      color: brand.$brand-primary;

      @include mq(mobile) {
        font-size: 28px;
        line-height: 28px;
      }
    }

    strong {
      font-size: 36px;
      line-height: 25px;

      @include mq(mobile) {
        font-size: 57px;
        line-height: 40px;
      }

      small {
        font-size: 27px;
        line-height: 25px;

        @include mq(mobile) {
          font-size: 43px;
          line-height: 40px;
        }
      }
    }
  }
}
