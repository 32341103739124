@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

.laser-video-widget {
  position: relative;
  background: brand.$brand-white;
  padding: 60px 0;

  @include mq(tablet) {
    padding: 100px 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 40%;
    background: brand.$brand-primary;

    @include mq(tablet) {
      left: -5%;
      height: 100%;
      width: 45%;
      transform: skewX(12deg);
    }
  }

  &__split {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include mq(tablet) {
      flex-direction: row;
    }

    &-half {
      width: 100%;

      @include mq(tablet) {
        flex-basis: calc(50% - 48px);
      }

      &:nth-child(1) {
        @include anim-fade-up(0s, 60px, self);
        margin-bottom: 30px;

        @include mq(tablet) {
          padding-right: 48px;
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        @include anim-fade-up(100ms, 60px, self);
        text-align: center;

        @include mq(tablet) {
          padding-left: 48px;
        }
      }

      h2,
      .ds & h2 {
        font-size: 30px;
        color: brand.$brand-nearly-black;
        @include fonts.helvetica-93-black-extended;
        text-transform: uppercase;
      }

      p {
        font-size: 16px;
        line-height: 26px;
        @include fonts.helvetica-53-extended;
      }
    }
  }
}
