@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/mixins' as mixins;
@use '~@sass/helpers/functions' as *;

.ds-step-carousel {
  background-color: brand.$brand-primary;
  padding: flow(3) 0;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @include fonts.helvetica-93-black-extended;
    color: brand.$brand-white;
    font-size: 30px;
    line-height: 30px;

    @include mq($until: mobile) {
      text-align: center;
    }

    opacity: 0;
    transform: translateY(-30px);
    transition: opacity 0.3s ease-in 0.3s, transform 0.3s ease-in 0.3s;

    &.animated {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__subtitle {
    @include fonts.helvetica-53-extended;
    color: brand.$brand-white;
    font-size: 16px;
    max-width: 530px;
    text-align: center;

    opacity: 0;
    transform: translateY(-30px);
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;

    &.animated {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__steps {
    position: relative;
    padding: flow(1) 0;
  }

  &__step-container {
    width: 100%;
    max-width: 1084px;

    opacity: 0;
    transform: translateY(-30px);
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;

    &.animated {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__item {
    // overwrite inline-block on tinyslider
    display: inline-flex !important;

    align-items: center;
    justify-content: center;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    @include mq($until: mobile) {
      flex-direction: column-reverse;
    }

    &.tns-slide-active {
      opacity: 1;
      visibility: visible;
    }

    &-content-container {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      width: 50%;

      @include mq($until: mobile) {
        width: 100%;
        align-items: center;
      }
    }

    &-icon {
      padding-bottom: 20px;
    }

    &-step {
      @include fonts.helvetica-93-black-extended;
      color: brand.$brand-white;
      font-size: 18px;
      padding-bottom: 20px;

      @include mq($until: mobile) {
        text-align: center;
      }
    }

    &-title {
      @include fonts.helvetica-93-black-extended;
      color: brand.$brand-white;
      font-size: 24px;
      padding-bottom: 10px;

      @include mq($until: mobile) {
        text-align: center;
      }
    }

    &-content {
      p {
        @include fonts.helvetica-93-black-extended;
        color: brand.$brand-white;
        font-size: 14px;
        padding-bottom: 30px;

        @include mq($until: mobile) {
          text-align: center;
        }
      }
    }

    &-button {
      @include fonts.helvetica-83-heavy-extended;
      color: brand.$brand-primary;
      font-size: 14px;
      padding: 10px 20px;
      background-color: brand.$brand-white;
      cursor: pointer;
    }

    &-media {
      width: 50%;

      @include mq($until: mobile) {
        width: 100%;
        text-align: center;
      }
    }
  }

  &__footer {
    display: flex;
    position: relative;
    max-width: 325px;

    opacity: 0;
    transform: translateY(-30px);
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;

    &.animated {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__control-container {
    position: absolute;
    cursor: pointer;

    &--prev {
      right: 100%;
    }

    &--next {
      left: 100%;
    }
  }

  &__control-prev,
  &__control-next {
    width: 38px;
    height: 38px;
    position: relative;
    background-color: brand.$brand-black;
    &:before {
      content: '';
      position: absolute;
      width: 9px;
      height: 15px;
      background-image: url('~@img/svg/icon-chevron-left--white.svg');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__control-next {
    &:before {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  &__nav {
    display: flex;
    &-item {
      @include fonts.helvetica-93-black-extended;
      color: brand.$brand-white;
      font-size: 14px;
      padding: 10px;
      cursor: pointer;

      &.tns-nav-active {
        color: brand.$brand-black;
      }
    }
  }
}
