@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-nav-inner {
  display: flex;
  justify-content: center;

  &__carousel {
    max-width: 1340px;
    width: 100%;
    padding: 20px;

    @include mq($from: tablet) {
      padding: 80px;
    }

    @include mq($from: xlg) {
      padding: 80px 160px;
    }
  }

  &__image {
    max-width: 76px;
  }

  &__title {
    color: brand.$brand-black;
  }

  &__item {
    padding: 0 32px;
    text-align: center;

    &:hover {
      opacity: 0.87;
    }

    &.active {
      .primary {
        fill: #db031d;
      }
      .secondary {
        fill: #323232;
      }
    }
    &.not-active {
      .primary {
        fill: #abacad;
      }
      .secondary {
        fill: #cbcbcb;
      }
    }

    svg {
      max-height: 76px;
    }
  }
}
