@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-layers' as layers;
@use '../settings/settings-fonts' as fonts;
@use './helpers/functions' as *;

.ds-image-carousel {
  position: relative;
  padding: flow(3) 0;

  &__item {
    opacity: 0.2;
    transition: opacity 400ms global.$easing;
    text-align: center;

    &--active-centered {
      opacity: 1;
    }
  }

  &__caption {
    font-size: 14px;
    color: rgba(brand.$brand-black, 0.5);
    @include fonts.helvetica-53-extended;
    margin: flow(0.4) 0;
    text-align: center;
  }

  .tns-inner {
    @include mq($until: tablet) {
      margin-left: -102px !important;
      margin-right: -122px !important;
    }
  }

  .tns-controls {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    z-index: layers.$layer-default;
    top: 50%;
    left: 0;
    right: 0;
    margin: -20px auto 0;
    width: 100%;
    padding: 0 flow(0.78);
    max-width: 1382px;
    transform: translateY(-50%);
    pointer-events: none;
    outline: none;

    @include mq(tablet) {
      padding: 0 flow(1);
    }

    button {
      position: relative;
      display: inline-flex;
      justify-content: center;
      height: 38px;
      width: 38px;
      background: darken(brand.$brand-grey, 18%);
      border: none;
      box-shadow: none;
      outline: none;
      opacity: 1;
      font-size: 0;
      color: brand.$brand-grey;
      transition: transform 150ms global.$easing, opacity 400ms global.$easing;
      pointer-events: all;

      @include mq(mobile) {
        height: 60px;
        width: 60px;
      }

      &:disabled {
        opacity: 0;
        pointer-events: none;
      }

      &:hover {
        @include mq(tablet) {
          transform: scale(1.15);
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: layers.$layer-root;
        top: 50%;
        transform: translateY(-50%);
        background: darken(brand.$brand-grey, 18%);
        width: 1px;
        height: 90px;

        @include mq(tablet) {
          height: 140px;
        }
      }

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        z-index: layers.$layer-default;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: url('~@img/svg/icon-chevron-left--black.svg') no-repeat center;
        background-size: 50% 50%;

        @include mq(tablet) {
          background-size: 20px 30px;

          @supports (display: grid) {
            background-size: unset;
          }
        }
      }

      &:nth-child(2):after {
        transform: rotateZ(180deg);
      }
    }
  }
}
