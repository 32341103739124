@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use '../helpers/functions' as functions;

.ds-interest {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: global.$padding 0px;

  &__inner {
    opacity: 0;
    transition: opacity 500ms ease, transform 500ms ease;
    transform: translateY(5%);

    &.animated {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @include mq($from: mobile) {
    padding: functions.flow(2) 0px;
  }

  &__form {
    background-color: brand.$brand-white;
  }
}
