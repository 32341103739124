@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

.laser-tabbed {
  background: brand.$brand-primary;
  padding: 50px 0;

  &__intro {
    text-align: center;
    width: 100%;
    max-width: 746px;
    margin: 0 auto 36px;

    &.animated > * {
      animation-play-state: running;
    }

    img {
      @include anim-fade-up(0s, 20px, parent);

      display: inline-block;
      margin-bottom: 38px;
    }

    h2,
    .ds & h2 {
      @include anim-fade-up(100ms, 20px, parent);

      font-size: 30px;
      margin: 0 0 20px;
      color: brand.$brand-white;
    }

    p {
      @include anim-fade-up(200ms, 20px, parent);

      font-size: 16px;
      line-height: 26px;
      color: brand.$brand-white;
      @include fonts.helvetica-53-extended;
    }
  }

  &__battery {
    &.animated label {
      animation-play-state: running;
    }

    label {
      @include anim-fade-up(200ms, 20px, parent);

      cursor: text;
      display: block;
      text-align: center;
      font-size: 12px;
      margin-bottom: 14px;
      color: brand.$brand-white;
      @include fonts.helvetica-73-bold-extended;
    }

    &-selector {
      @include anim-fade-up(200ms, 20px, parent);

      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 418px;
      margin: 0 auto 40px;
      background: brand.$brand-white;
      padding: 6px;
      border-radius: 6px;
    }

    &-logo {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      flex-basis: calc(50% - 3px);
      padding: 10px;
      background: brand.$brand-primary;
      border-radius: 6px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: brand.$brand-white;
        transition: transform 500ms $anim-easing-cubic;
      }

      &:nth-child(1):before,
      &:nth-child(2):before {
        transform: translateX(0px);
      }

      &--active:nth-child(1):before {
        transform: translateX(calc(100% + 6px));
        transform-origin: right;
      }

      &--active:nth-child(2):before {
        transform: translateX(calc(-100% - 6px));
        transform-origin: left;
      }

      img {
        position: relative;
        z-index: 1;
        max-height: 34px;
      }
    }

    &-content {
      @include anim-fade-up(250ms, 60px, parent);

      &-inner {
        pointer-events: none;
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: opacity 500ms $anim-easing-cubic, transform 500ms $anim-easing-cubic;

        &:nth-child(1) {
          transform: translateX(60px);

          &.laser-tabbed__battery-content-inner--active {
            transform: translateX(0px);
          }
        }

        &:nth-child(2) {
          transform: translateX(-60px);

          &.laser-tabbed__battery-content-inner--active {
            transform: translateX(0px);
          }
        }

        &--active {
          pointer-events: all;
          opacity: 1;
          height: auto;
        }
      }

      &-logo {
        text-align: center;
        margin-bottom: 30px;
      }
    }

    &-row {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mq(tablet) {
        flex-direction: row;
      }
    }

    &-col {
      width: 100%;

      &:nth-child(1) {
        order: 2;
        margin-bottom: 30px;

        @include mq(tablet) {
          order: unset;
          flex-basis: 36%;
          margin-bottom: 0;
        }
      }

      &:nth-child(2) {
        order: 1;
        text-align: center;
        margin-bottom: 20px;

        @include mq(tablet) {
          order: unset;
          flex-basis: 28%;
          margin-bottom: 0;
        }
      }

      &:nth-child(3) {
        order: 2;
        text-align: center;

        @include mq(tablet) {
          order: unset;
          flex-basis: 36%;
        }
      }
    }

    &-text {
      font-size: 18px;
      color: brand.$brand-white;
      @include fonts.helvetica-63-medium-extended;
      margin-bottom: 22px;
      text-transform: uppercase;

      @include mq(tablet) {
        margin-bottom: 14px;
      }

      &--lead {
        font-size: 18px;
        line-height: 20px;
        @include fonts.helvetica-93-black-extended;

        @include mq(tablet) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      strong {
        display: block;
        font-size: 30px;
        line-height: 36px;
        @include fonts.helvetica-93-black-extended;

        @include mq(tablet) {
          font-size: 36px;
        }
      }

      small {
        display: block;
        font-size: 12px;
        @include fonts.helvetica-63-medium-extended;
        text-transform: none;
      }
    }
  }
}
