@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-viewport-wide {
  display: block;
  width: 100%;
  margin: 0;

  @include mq($from: mobile) {
    width: global.$viewport-wide;
  }
}

.ds-site-wide {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding-left: global.$padding;
  padding-right: global.$padding;

  @include mq($from: desktop) {
    width: global.$site-wide;
    padding-left: 0;
    padding-right: 0;
  }
}

.ds-banner__title--center-text {
  text-align: center;
}

.ds-content-wide {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding-left: global.$padding;
  padding-right: global.$padding;
  padding-bottom: global.$padding;

  @include mq($from: mobile) {
    width: global.$content-wide;
    padding-left: 0;
    padding-right: 0;

    &--small {
      width: global.$content-wide--small;
    }
  }
}

.ds-content-wide-wide {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding-left: global.$padding;
  padding-right: global.$padding;
  padding-bottom: global.$padding;

  @include mq($from: tablet) {
    width: global.$content-wide--wide;
    padding-left: 0;
    padding-right: 0;
  }
}

.ds-copy-container {
  max-width: global.$content-wide--small;
  margin-left: auto;
  margin-right: auto;
}

.ds-content-breakout {
  display: block;
  margin-left: 0;
  margin-right: 0;

  @include mq($from: mobile) {
    margin-left: global.$content-breakout;
    margin-right: global.$content-breakout;
  }
}

// @hack: there isn't a new wrapper class for the page background, therefore applying based on template currently :(
.ds {
  background: #f8f8f8;
  position: relative;
  z-index: 1;
}
