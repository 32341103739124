@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/functions' as *;
@use '~@sass/helpers/animations' as *;

.dc-tabbed-tools {
  background: brand.$brand-primary;
  padding: 60px 0 0;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 862px;
    text-align: center;

    > * {
      @include anim-fade-up(0s, 20px, parent);
      @include anim-loop-delay(3, 100ms);
    }

    h2 {
      color: brand.$brand-white;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      margin: 0 0 14px;
    }

    p {
      color: brand.$brand-white;
      @include fonts.helvetica-53-extended;
      line-height: 22px;
      max-width: 650px;
    }
  }

  &__filter {
    margin: 30px 0;

    &-list {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      padding: 0;

      li {
        cursor: pointer;
        position: relative;
        color: rgba(brand.$brand-white, 0.5);
        @include fonts.helvetica-83-heavy-extended;
        font-size: 14px;
        text-transform: uppercase;
        margin: 0 6px;

        @include mq(tablet) {
          font-size: 18px;
          margin: 0 12px;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -4px;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 80px;
          height: 2px;
          background: brand.$brand-white;
          transform: scaleX(0);
          opacity: 0;
          transform-origin: center;
          transition: opacity 300ms global.$easing, transform 300ms global.$easing;
        }

        &.is-active {
          color: brand.$brand-white;

          &:after {
            opacity: 1;
            transform: scaleX(1);
          }
        }
      }
    }
  }

  &__carousel {
    padding-bottom: 60px;

    &-wrapper {
      @include anim-image-fade-up(20px, parent);
      transition-delay: 400ms;
      position: relative;
      max-width: 1284px;
      width: 100%;
      padding-left: 70px;

      @include mq(1180px) {
        padding-left: 100px;
      }
    }

    .slick-slide {
      outline: 0;
      border: 0;
      padding-right: 50px;

      @include mq(1180px) {
        padding-right: 100px;
      }
    }

    &-item {
      position: relative;
      text-align: center;
      padding-bottom: 4px; // border applied dc-tabbed-tools__carousel-product
    }

    &-arrow {
      cursor: pointer;
      position: absolute;
      z-index: 10;
      top: 88px;
      left: calc(100% + 10px);
      display: block;
      width: 30px;
      height: 16px;
      background: url('~@icons/icon-slider-arrow.svg') no-repeat center;

      @include mq(1180px) {
        left: calc(100% + 37px);
      }

      .slick-slide:last-child & {
        display: none;
      }
    }

    &-prev-arrow {
      cursor: pointer;
      position: absolute;
      z-index: 10;
      top: 88px;
      left: 30px;
      display: block;
      width: 30px;
      height: 16px;
      background: url('~@icons/icon-slider-arrow.svg') no-repeat center;
      transform: rotate(-180deg) translateX(0px);
      opacity: 1;
      transition: opacity 300ms global.$easing, transform 300ms global.$easing;

      @include mq(1180px) {
        left: 35px;
      }

      &.arrow-disabled {
        opacity: 0;
        transform: rotate(-180deg) translateX(-10px);
      }
    }

    &-image {
      position: relative;
      max-width: 196px;
      border-radius: 50%;
      border: 4px solid brand.$brand-white;
      overflow: hidden;
      margin: 0 auto 20px;

      img {
        display: block;
        position: absolute;
        top: 0;
      }

      &:before {
        position: relative;
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }

    &-title {
      font-size: 16px;
      @include fonts.helvetica-93-black-extended;
      color: brand.$brand-white;
      text-transform: uppercase;
      margin-bottom: 14px;
    }

    &-text {
      font-size: 12px;
      line-height: 18px;
      @include fonts.helvetica-53-extended;
      color: brand.$brand-white;
      margin-bottom: 14px;
    }

    &-product {
      cursor: pointer;
      display: inline-block;
      position: relative;
      font-size: 14px;
      @include fonts.helvetica-73-bold-extended;
      color: brand.$brand-white;
      padding-right: 16px;

      &:before {
        content: ' ';
        display: block;
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        height: 2px;
        width: 100%;
        margin: 0 auto;
        background: brand.$brand-white;
        transform: scaleX(0);
        transition: transform 300ms $anim-easing-cubic;
      }

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 10px;
        height: 6px;
        background: url('~@icons/icon-angle-down.svg') no-repeat center;
      }

      &--active {
        &:before {
          transform: scaleX(1);
        }

        &:after {
          transform: rotate(180deg);
          margin-top: -2px;
        }
      }
    }
  }

  &__drain {
    background: brand.$brand-primary-dark;
    padding: 40px 24px;

    @include mq(tablet) {
      padding: 40px;
    }

    &-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;

      @include mq(tablet) {
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    &-close {
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      height: 18px;
      color: brand.$brand-white;
      @include fonts.helvetica-53-extended;
      padding-right: 26px;
      background: url('~@icons/icon-close.svg') no-repeat right top;
      margin-bottom: 12px;

      @include mq(tablet) {
        margin: 0;
      }
    }

    &-title {
      font-size: 20px;
      @include fonts.helvetica-93-black-extended;
      color: brand.$brand-white;
      text-transform: uppercase;
      margin-bottom: 32px;
      text-align: center;

      @include mq(tablet) {
        font-size: 24px;
        text-align: left;
      }
    }

    &-products {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 12px;

      @include mq(tablet) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 26px;
      }

      @include mq(desktop) {
        grid-template-columns: repeat(4, 1fr);
      }

      .product-card {
        width: 100%;

        &__inner-container {
          padding: 0;
        }
      }
    }
  }
}
