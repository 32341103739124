@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/helpers/animations' as *;

.laser-icons {
  background: brand.$brand-black;
  padding: 30px 0 34px;

  &--primary-dark {
    background: brand.$brand-primary-dark;
  }

  &__row {
    @include mq(tablet) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
    }
  }

  &__image {
    margin-right: 14px;
    flex-shrink: 0;
  }

  &__item {
    @include anim-fade-up(0s, 20px, self);
    @include anim-loop-delay(3);

    display: flex;
    align-items: center;
    height: 100%;
    padding: 30px 0;
    text-transform: uppercase;
    justify-content: center;
    border-bottom: 1px solid brand.$brand-white;

    @include mq(tablet) {
      border-right: 1px solid brand.$brand-white;
      border-bottom: none;
      padding: 0 40px;
    }

    &:first-child {
      @include mq(tablet) {
        justify-content: flex-start;
      }
    }

    &:nth-child(2) .laser-icons__text {
      max-width: 234px;
      min-width: 180px;
    }

    &:last-child {
      @include mq(tablet) {
        justify-content: flex-end;
      }
    }

    &:last-child {
      border-right: none;
      border-bottom: none;
    }
  }

  &__text {
    flex: 0;
    color: brand.$brand-white;
    font-size: 18px;
    max-width: 190px;
    @include fonts.helvetica-93-black-extended;

    @include mq(tablet) {
      flex: auto;
      font-size: 1.1vw;
      line-height: 1;
    }

    @include mq(desktop-lg) {
      font-size: 21px;
    }
  }
}
