@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;

.ds-icon-bar {
  &--black {
    background-color: brand.$brand-black;
  }

  &--white {
    background-color: brand.$brand-white;
  }

  &__items {
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    opacity: 0;
    transition: opacity 1000ms ease;

    &.slick-initialized {
      opacity: 1;
    }

    @include mq($from: tablet) {
      padding: 50px 60px;
    }
  }

  .slick-list {
    width: 100%;
  }

  &__item {
    // Overide slick styles..
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
  }

  &__img {
    width: 100%;
    max-width: 100px;
    align-self: flex-start;
    margin-right: 10px;
  }

  &__copy {
    h3 {
      @include fonts.helvetica-83-heavy-extended-oblique;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 1;
      margin: 0px;

      @include mq($until: mobile) {
        font-size: 12px;
      }

      .ds-icon-bar--black & {
        color: brand.$brand-white;
      }

      .ds-icon-bar--white & {
        color: brand.$brand-black;
      }
    }
  }
}
