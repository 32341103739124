@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;

.promo-card {
  @include mq($until: mobile) {
    // important needed to overwrite product card styling
    width: 100% !important;
    min-height: 500px;
  }

  @include mq($until: sm) {
    min-height: 350px;
  }

  &--red {
    .promo-card__title {
      color: brand.$brand-primary;
    }
    .promo-card__content {
      color: brand.$brand-primary;
    }
  }

  &--black {
    .promo-card__title {
      color: brand.$brand-grey-dark;
    }
    .promo-card__content {
      color: brand.$brand-grey-dark;
    }
  }

  &--white {
    .promo-card__title {
      color: brand.$brand-white;
    }
    .promo-card__content {
      color: brand.$brand-white;
    }
  }

  &__inner-container {
    height: 100%;
    padding: 0 4% 8% 4%;

    @include mq($until: desktop) {
      padding: 0 2% 4% 2%;
    }

    @include mq($until: mobile) {
      padding: 2px;
    }
  }

  &__inner {
    text-align: center;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    z-index: 1;
  }

  &__title-container {
    position: relative;
    z-index: 3;
  }

  &__new {
    @include fonts.helvetica-73-bold-extended();
    position: absolute;
    text-transform: uppercase;
    left: 50%;
    transform: translateX(-50%);
    background-color: brand.$brand-primary;
    color: brand.$brand-white;
    padding: 5px 10px;
  }

  &__title {
    padding: 40px 5px 0;
    text-align: center;
  }

  &__content-container {
    position: relative;
    z-index: 3;
    padding: 18px 5px;
  }

  &__content {
    padding-bottom: 30px;
    text-align: center;
  }
}
