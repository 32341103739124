@use '~sass-mq/mq' as *;
@use '../settings/settings-brand' as brand;
@use '../settings/settings-global' as global;
@use '../settings/settings-fonts' as fonts;
@use './helpers/functions' as *;

.ds-video-layout {
  display: flex;
  justify-content: center;
  padding: flow(2) flow(1);
  background: brand.$brand-black;
  background-position: center center;
  background-size: cover;

  &.ds-theme--red {
    background-color: brand.$brand-primary;
  }

  &.ds-theme--white {
    background-color: brand.$brand-white;
  }

  &.ds-theme--nearly-black {
    background-color: brand.$brand-nearly-black;
  }

  @include mq(mobile) {
    padding: flow(2) flow(2);
  }

  @include mq(desktop) {
    padding: flow(5) flow(2);
  }

  &--row {
    padding: flow(2.5) flow(1) flow(2);

    @include mq(mobile) {
      padding: flow(4) flow(2) flow(2);
    }

    @include mq(desktop) {
      padding: flow(5.1) flow(2);
    }
  }

  &--featured {
    padding: flow(2) 0 0;

    @include mq(desktop) {
      padding: flow(5) 0 0;
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .ds-video-layout--row & {
      flex-direction: column;
      max-width: 1568px;
    }

    .ds-video-layout--column & {
      flex-direction: column;
      max-width: 1330px;

      @include mq(tablet) {
        flex-direction: row;
      }
    }

    .ds-video-layout--featured & {
      flex-direction: row;
      justify-content: center;
      background: brand.$brand-nearly-black;
      padding-left: flow(2);
      padding-right: flow(2);
      padding-bottom: flow(3);
    }

    .ds-video-layout--transparent & {
      background: transparent;
    }

    h3 {
      font-size: 24px;
      @include fonts.helvetica-93-black-extended-oblique;
      color: brand.$brand-white;
      text-transform: uppercase;
      margin: 0 0 flow(1);
    }
  }

  &__main {
    position: relative;
    flex: 1;

    &.animated {
      .ds-video-layout__main-video:before {
        animation-play-state: running;
      }
    }

    .ds-video-layout--row & {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      max-width: 96vw;

      @include mq(tablet) {
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    .ds-video-layout--column & {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: flow(3.5);

      @include mq(tablet) {
        padding-right: 30px;
        margin-bottom: 0;
      }
    }

    .ds-video-layout--featured & {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: -#{flow(2)};
      margin-right: -#{flow(2)};

      > * {
        max-width: 1490px;
      }
    }

    &-video {
      &.hide-thumbnail {
        background: brand.$brand-black;
        border: 1px solid brand.$brand-primary;
        border-left: none;
      }

      .ds-video-layout--row & {
        position: relative;
        z-index: 10;
        top: -40px;
        left: 0;
        width: calc(100% - 30px);
        padding-bottom: 54%;

        @include mq(tablet) {
          position: absolute;
          width: 55.24%;
          padding-bottom: 31%;
        }

        &:before {
          content: '';
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 10px;
          height: calc(100% + 40px);
          background: brand.$brand-primary;
          transform: scaleY(0);
          transform-origin: bottom;
          animation: line 300ms linear forwards;
          animation-play-state: paused;

          @keyframes line {
            0% {
              transform: scaleY(0);
              transform-origin: bottom;
            }
            100% {
              transform: scaleY(1);
              transform-origin: bottom;
            }
          }
        }
      }

      .ds-video-layout--column & {
        position: relative;
        width: 100%;
        padding-bottom: 56%;

        &.hide-thumbnail {
          border: 1px solid brand.$brand-primary;

          + .ds-video-layout__main-content {
            transform: translateY(40px);
            transition: transform 500ms global.$easing;
          }
        }
      }

      .ds-video-layout--featured & {
        position: relative;
        width: 100%;
        padding-bottom: 56.3%;
        max-width: none;
        background: brand.$brand-black;

        @include mq(desktop-lg) {
          padding-bottom: 44.05%;
        }

        &.hide-thumbnail {
          border: none;
          margin-bottom: 130px;
          transition: margin-bottom 500ms global.$easing; // not the best but needed
          will-change: margin-bottom;

          .ds-video-layout__video {
            border: 1px solid brand.$brand-grey-dark;
            border-bottom: none;
          }

          + .ds-video-layout__main-content {
            transform: translateY(40px);
            transition: transform 500ms global.$easing;
          }
        }
      }

      .ds-video-layout--transparent & {
        background: transparent;
      }
    }

    &-content {
      background: brand.$brand-primary;
      color: brand.$brand-white;
      transform: translateX(60px);
      opacity: 0;
      transition: transform 500ms linear 1s, opacity 500ms linear 1s;

      .animated & {
        transform: translateX(0px);
        opacity: 1;
        max-width: 90vw;
      }

      .ds-video-layout--row & {
        width: 100%;
        padding: flow(1);

        @include mq(mobile) {
          padding: flow(1.5) flow(2);
        }

        @include mq(tablet) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 55%;
          min-height: 424px;
          padding: flow(1.5) flow(2) flow(1.5) calc(10% + #{flow(2)});
        }

        @include mq(desktop) {
          min-height: 490px;
          padding: flow(2) flow(3) flow(2) calc(10% + #{flow(3)});
        }
      }

      .ds-video-layout--column & {
        position: relative;
        z-index: 1;
        width: calc(100% - 80px);
        padding: flow(1.25) flow(1.5);
        margin-top: -40px;
      }

      .ds-video-layout--featured & {
        background: none;
        max-width: 1316px;
        padding: 0 flow(2);

        @include mq(tablet) {
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          .ds-video-layout__title {
            width: 400px;
            padding-right: flow(2);
          }

          .ds-video-layout__text {
            flex: 1;
          }
        }
      }

      h2 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: flow(1);
        color: brand.$brand-white;
        @include fonts.helvetica-93-black-extended;
        text-transform: uppercase;

        @include mq(mobile) {
          font-size: 26px;
          line-height: 34px;
        }

        @include mq(desktop) {
          font-size: 30px;
          line-height: 36px;
        }
      }

      p {
        font-size: 12px;
        line-height: 22px;
        color: brand.$brand-white;
        @include fonts.helvetica-53-extended;

        @include mq(mobile) {
          font-size: 14px;
          line-height: 24px;
        }

        @include mq(tablet) {
          font-size: 16px;
          line-height: 24px;
        }

        @include mq(desktop) {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  &__svg-loader {
    pointer-events: none;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    stroke-dasharray: 2694;
    opacity: 0;
    animation: draw-outline 1s linear forwards 300ms;
    animation-play-state: paused;

    .animated & {
      animation-play-state: running;
    }

    @keyframes draw-outline {
      0% {
        stroke-dashoffset: 2694;
        opacity: 0;
      }
      5% {
        opacity: 1;
      }
      75% {
        opacity: 1;
      }
      100% {
        stroke-dashoffset: 0;
        opacity: 0;
      }
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: video-in 0s linear forwards 1.2s;
    animation-play-state: paused;

    .animated & {
      animation-play-state: running;
    }

    .ds-video-layout--featured & {
      right: 0;
      margin: 0 auto;
      max-width: none;

      @include mq(desktop-lg) {
        max-width: 1490px;
      }
    }

    @keyframes video-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &__image {
    cursor: pointer;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: image-in 300ms linear forwards 750ms;
    animation-play-state: paused;

    .animated .ds-video-layout__main-video & {
      animation-play-state: running;

      &:before,
      &:after {
        animation-play-state: running;
      }
    }

    // removes thumbnail when iframe is playing
    .hide-thumbnail & {
      pointer-events: none;

      img {
        animation: img-out 300ms linear forwards 400ms;

        @keyframes img-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }

    @keyframes image-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    picture {
      display: contents;
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';
      transition: filter 300ms global.$easing;
      will-change: filter;

      .ds-video-layout__main-video:hover & {
        filter: grayscale(50%);
      }
    }

    .ds-video-layout--featured & {
      max-width: 1490px;
      right: 0;
      margin: auto;
    }
  }

  &__play-button {
    width: 152px;
    height: 152px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    .play-circle {
      display: block;
      width: 100%;
      height: 100%;

      &-01 {
        transform: rotateZ(0deg);
        transform-origin: center;
        transition: transform 500ms global.$easing;
        animation: spin-01 3s linear infinite 500ms;
        animation-play-state: paused;

        .ds-video-layout__image:hover & {
          transform: rotateZ(180deg);
          animation-play-state: running;
        }

        .ds-video-layout__main-video.hide-thumbnail & {
          transform: rotateZ(-360deg) scale(0);
          opacity: 0;
          transition: transform 500ms global.$easing, opacity 500ms global.$easing;
          animation-play-state: paused;
        }

        @keyframes spin-01 {
          0% {
            transform: rotateZ(180deg);
          }
          100% {
            transform: rotateZ(-180deg);
          }
        }
      }

      &-02 {
        transform: rotateZ(180deg);
        transform-origin: center;
        transition: transform 500ms global.$easing;
        animation: spin-02 3s linear infinite 500ms;
        animation-play-state: paused;

        .ds-video-layout__image:hover & {
          transform: rotateZ(0deg);
          animation-play-state: running;
        }

        .ds-video-layout__main-video.hide-thumbnail & {
          transform: rotateZ(180deg) scale(0);
          opacity: 0;
          transition: transform 500ms global.$easing, opacity 500ms global.$easing;
          animation-play-state: paused;
        }

        @keyframes spin-02 {
          0% {
            transform: rotateZ(0deg);
          }
          100% {
            transform: rotateZ(360deg);
          }
        }
      }
    }

    .play-triangle {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 0 20px 30px;
      border-color: transparent transparent transparent brand.$brand-white;
      transition: transform 500ms global.$easing;
      transform-origin: center;

      .ds-video-layout__image:hover & {
        transform: scale(1.3) perspective(2px) rotateY(0deg);
      }

      .ds-video-layout__main-video.hide-thumbnail & {
        transform: scale(50) translateX(-20%) perspective(2px) rotateY(-10deg);
        opacity: 0;
        transition: transform 1s global.$easing, opacity 250ms global.$easing 750ms;
      }
    }
  }

  &__thumb-carousel {
    position: relative;

    h3 {
      opacity: 0;
      transform: translateY(40px);
      transition: opacity 500ms global.$easing 1s, transform 500ms global.$easing 1s;
    }

    &.animated h3 {
      opacity: 1;
      transform: translateY(0px);
    }

    .ds-video-layout--column & {
      @include mq(mobile) {
        width: 240px;
      }
    }

    .ds-video-layout--featured & {
      position: relative;
      z-index: 2;
      max-width: 1454px;
      margin-top: -70px;
      margin-bottom: flow(3);
      padding: 0 flow(2);
    }

    @mixin defaultThumbCarousel {
      .ds-video-layout__thumb-carousel-inner {
        position: relative;
        padding: 0 flow(3);
        opacity: 0;
        transform: translateY(40px);
        transition: opacity 500ms global.$easing 1.25s, transform 500ms global.$easing 1.25s;
      }

      &.animated .ds-video-layout__thumb-carousel-inner {
        opacity: 1;
        transform: translateY(0px);
        max-width: 90vw;
      }

      .ds-video-layout__controls {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        pointer-events: none;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 70%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        &-button {
          pointer-events: all;
          display: block;
          width: 42px;
          height: 42px;
          background: brand.$brand-primary;
          border: none;
          outline: none;
          font-size: 0;

          &[disabled] {
            opacity: 0.25;
          }

          &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 20px;
            width: 1px;
            height: 100%;
            background: brand.$brand-primary;
          }

          &:after {
            content: '\f053';
            font-family: 'FontAwesome';
            display: inline-block;
            color: #fff;
            margin-left: 1px;
            font-size: 14px;
          }

          &:nth-child(2) {
            &:before {
              left: auto;
              right: 20px;
            }

            &:after {
              content: '\f054';
              margin-left: 4px;
            }
          }
        }
      }
    }

    &--horizontal {
      @include defaultThumbCarousel;
    }

    &--vertical {
      // use default horizontal styling for mobile
      @include mq($until: mobile) {
        @include defaultThumbCarousel;
      }

      // vertical styling for desktop
      @include mq(mobile) {
        .ds-video-layout__thumb-carousel-inner {
          position: relative;
          padding: flow(3) 0;
          opacity: 0;
          transform: translateY(40px);
          transition: opacity 500ms global.$easing 1.25s, transform 500ms global.$easing 1.25s;

          .tns-outer {
            max-width: 100vw;
          }
        }

        &.animated .ds-video-layout__thumb-carousel-inner {
          opacity: 1;
          transform: translateY(0px);
        }

        h3 {
          margin-top: flow(0.25);
        }

        .ds-video-layout__controls {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          pointer-events: none;
          position: absolute;
          z-index: 2;
          width: 100px;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;

          &-button {
            pointer-events: all;
            display: block;
            width: 42px;
            height: 42px;
            background: brand.$brand-primary;
            border: none;
            outline: none;
            font-size: 0;

            &[disabled] {
              opacity: 0.25;
            }

            &:before {
              content: '';
              display: block;
              position: absolute;
              z-index: -1;
              top: 20px;
              left: 0;
              width: 100%;
              height: 1px;
              background: brand.$brand-primary;
            }

            &:after {
              content: '\f077';
              font-family: 'FontAwesome';
              display: inline-block;
              color: #fff;
              font-size: 14px;
            }

            &:nth-child(2) {
              &:before {
                top: auto;
                bottom: 20px;
              }

              &:after {
                content: '\f078';
              }
            }
          }
        }
      }
    }
  }

  &__thumb {
    display: inline-block;

    &:hover {
      img {
        filter: grayscale(25%);
      }
      .ds-video-layout__thumb-inner:before {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }

    &-inner {
      position: relative;

      &:before {
        pointer-events: none;
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
        height: 60px;
        background: url('~@icons/icon-circle-play.svg') no-repeat center;
        background-size: contain;
        transition: transform 300ms global.$easing;
        transform-origin: center;
      }
    }

    img {
      cursor: pointer;
      transition: filter 300ms global.$easing;
      will-change: filter;
    }
  }
}
