@use '~sass-mq/mq' as *;
@use '~@sass/settings/settings-brand' as brand;
@use '~@sass/settings/settings-global' as global;
@use '~@sass/settings/settings-fonts' as fonts;
@use '~@sass/settings/settings-layers' as layers;
@use '~@sass/helpers/mixins' as mixins;

.stockists {
  width: 100%;
  background: brand.$brand-white;
}

.stockists__inner {
  text-align: center;
  max-width: 1566px;
  margin: 0 auto;
  padding: 30px 24px;
  opacity: 0;

  &.animated {
    @include mixins.fade-in-up(500ms, 0s, 20px, global.$easing);
  }
}

.stockists__title {
  margin-bottom: 40px;
  text-transform: uppercase;
  font-size: 24px;
  color: brand.$brand-black;

  @include mq(mobile) {
    font-size: 30px;
  }

  .ds & {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 24px;
    color: brand.$brand-black;

    @include mq(mobile) {
      font-size: 30px;
    }
  }
}

.stockists__card-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.stockists__card {
  flex: 1 1 100%;
  max-width: 400px;
  margin-bottom: 50px;

  @include mq(mobile) {
    flex: 1 1 50%;
  }

  @include mq(tablet) {
    flex: 1 1 25%;
  }
}

.stockists__card-inner {
  padding: 0 13px;
}

.stockists__media-holder {
  border: 1px solid lighten(brand.$brand-grey-medium, 10%);
  height: 204px;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    max-width: 222px;
    width: auto;
    height: auto;
    max-height: 110px;
  }
}

.stockists__card-title {
  @include fonts.helvetica-93-black-extended-oblique;
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: brand.$brand-nearly-black;

  .ds & {
    @include fonts.helvetica-93-black-extended-oblique;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 1px;
    margin: 20px 0 10px;
    color: brand.$brand-nearly-black;
  }
}

.stockists__card-blurb {
  margin-bottom: 20px;
  @include fonts.helvetica-53-extended;

  .ds & {
    margin-top: 0;
    margin-bottom: 20px;
    @include fonts.helvetica-53-extended;
  }
}

.stockists__link {
  @include fonts.helvetica-73-bold-extended;
  font-size: 14px;
  position: relative;
  padding-right: 12px;

  &:hover {
    &:after {
      background: url('~@img/svg/icon-chevron-right--black.svg') no-repeat right;
    }
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    z-index: layers.$layer-default;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url('~@img/svg/icon-chevron-right--red.svg') no-repeat right;
  }
}
