@use '~sass-mq/mq' as *;
@use './settings/settings-brand' as brand;
@use './settings/settings-global' as global;
@use './settings/settings-fonts' as fonts;
@use './helpers/functions' as *;

.ds-stacked-split-content {
  background-color: brand.$brand-white;

  &__inner {
    padding: flow(1);
    margin: 0 auto;
    max-width: global.$site-wide;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include mq($from: mobile) {
      padding: flow(2) flow(1);
      flex-wrap: nowrap;
    }

    @include mq($from: tablet) {
      padding: flow(3) flow(1);
    }
  }

  &__media,
  &__wysiwyg {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mq($from: mobile) {
      flex: 0 0 50%;
    }
  }

  &__media {
    flex: 0 0 85%;
    margin: 0 auto;

    @include mq($from: mobile) {
      flex: 0 0 50%;
    }
  }

  &__wysiwyg {
    h2,
    p {
      width: 100%;
    }

    h2 {
      @include fonts.helvetica-93-black-extended;
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: flow(0.5);

      @include mq($from: tablet) {
        font-size: 30px;
      }
    }

    p {
      @include fonts.helvetica-53-extended;
      font-size: 14px;
      margin-bottom: flow(1);
    }
  }
}
